import {Variant} from '@genomics-dev/denim-components';
import classNames from 'classnames';
import {
    BulletPoint,
    GridContainer,
} from '..';

export function ExplanatoryStep(props) {
    const {
        className,
        children,
        largeStepTitle = true,
        stepTitle,
        stepNumber,
    } = props;

    const containerClasses = classNames('explanatory-step', className);

    return (
        <GridContainer
            containerClassName={containerClasses}
            gridClassName={'two-thirds'}
            variant={Variant.DARK}
        >
            <div className={'sidebar with-bullet-point flex-column-to-row'}>
                <BulletPoint content={stepNumber} />
                {largeStepTitle
                    ? <h2 className={'no-margin'}>{stepTitle}</h2>
                    : <h4 className={'no-margin'}>{stepTitle}</h4>}
            </div>
            <div className={'content'}>{children}</div>
        </GridContainer>
    );
}
