import {
    Link,
    Variant,
} from '@genomics-dev/denim-components';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {
    BackLink,
    GridContainer,
} from '../../components';
import {
    useDefaultBackClickHandler,
    useTitle,
} from '../../hooks';

export function SteadyMDPolicyPage() {
    const intl = useIntl();
    const defaultBackClickHandler = useDefaultBackClickHandler();

    const rtfFormat = () => ({
        bold: (chunks) => <strong>{chunks}</strong>,
        italic: (chunks) => <em>{chunks}</em>,
    });

    useTitle(intl.formatMessage({id: 'steadyPolicy.documentTitle'}));

    return (
        <section className={'static-content'}>
            <GridContainer containerClassName={'content-section'}>
                <div className={'page-title'}>
                    <BackLink onClick={defaultBackClickHandler} />
                    <h1 data-test-id={'steady-policy-page-title'}>
                        <FormattedMessage id={'steadyPolicy.pageTitle.text'} />
                    </h1>
                </div>
            </GridContainer>
            <GridContainer
                className={'content-section'}
                variant={Variant.LIGHT}
            >
                <div className={'content-item'}>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.introduction.paragraph.one'}
                            values={rtfFormat()}
                        />
                    </p>
                </div>
                <div className={'content-item'}>
                    <h2>
                        <FormattedMessage id={'steadyPolicy.newPatientAgreement.title'} />
                    </h2>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.newPatientAgreement.introduction.paragraph.one'}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.newPatientAgreement.introduction.paragraph.two'}
                        />
                    </p>
                    <h3>
                        <FormattedMessage
                            id={'steadyPolicy.newPatientAgreement.termTerminationCancellation.title'}
                        />
                    </h3>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.newPatientAgreement.termTerminationCancellation.paragraph.one'}
                        />
                    </p>
                    <h3>
                        <FormattedMessage
                            id={'steadyPolicy.newPatientAgreement.otherProviders.title'}
                        />
                    </h3>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.newPatientAgreement.otherProviders.paragraph.one'}
                        />
                    </p>
                    <h3>
                        <FormattedMessage
                            id={'steadyPolicy.newPatientAgreement.insuranceMedicalCoverage.title'}
                        />
                    </h3>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.newPatientAgreement.insuranceMedicalCoverage.paragraph.one'}
                        />
                    </p>
                    <h3>
                        <FormattedMessage id={'steadyPolicy.newPatientAgreement.severability.title'} />
                    </h3>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.newPatientAgreement.severability.paragraph.one'}
                        />
                    </p>
                    <h3>
                        <FormattedMessage
                            id={'steadyPolicy.newPatientAgreement.modTermIntDis.title'}
                        />
                    </h3>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.newPatientAgreement.modTermIntDis.paragraph.one'}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.newPatientAgreement.modTermIntDis.paragraph.two'}
                        />
                    </p>
                    <h3>
                        <FormattedMessage id={'steadyPolicy.newPatientAgreement.amendment.title'} />
                    </h3>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.newPatientAgreement.amendment.paragraph.one'}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.newPatientAgreement.amendment.paragraph.two'}
                        />
                    </p>
                    <h3>
                        <FormattedMessage id={'steadyPolicy.newPatientAgreement.assignment.title'} />
                    </h3>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.newPatientAgreement.assignment.paragraph.one'}
                        />
                    </p>
                    <h3>
                        <FormattedMessage
                            id={'steadyPolicy.newPatientAgreement.relationshipParties.title'}
                        />
                    </h3>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.newPatientAgreement.relationshipParties.paragraph.one'}
                        />
                    </p>
                    <h3>
                        <FormattedMessage
                            id={'steadyPolicy.newPatientAgreement.legalSignificance.title'}
                        />
                    </h3>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.newPatientAgreement.legalSignificance.paragraph.one'}
                        />
                    </p>
                    <h3>
                        <FormattedMessage id={'steadyPolicy.newPatientAgreement.notice.title'} />
                    </h3>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.newPatientAgreement.notice.paragraph.one'}
                        />
                    </p>
                    <h3>
                        <FormattedMessage id={'steadyPolicy.newPatientAgreement.governingLaw.title'} />
                    </h3>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.newPatientAgreement.governingLaw.paragraph.one'}
                        />
                    </p>
                    <h3>
                        <FormattedMessage id={'steadyPolicy.newPatientAgreement.headings.title'} />
                    </h3>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.newPatientAgreement.headings.paragraph.one'}
                        />
                    </p>
                    <h3>
                        <FormattedMessage
                            id={'steadyPolicy.newPatientAgreement.entireAgreement.title'}
                        />
                    </h3>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.newPatientAgreement.entireAgreement.paragraph.one'}
                        />
                    </p>
                </div>
                <div className={'content-item'}>
                    <h2>
                        <FormattedMessage id={'steadyPolicy.patientWarning.title'} />
                    </h2>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.patientWarning.paragraph.one'}
                            values={rtfFormat()}
                        />
                    </p>
                </div>
                <div className={'content-item'}>
                    <h2>
                        <FormattedMessage id={'steadyPolicy.informedConsent.title'} />
                    </h2>
                    <h3>
                        <FormattedMessage id={'steadyPolicy.informedConsent.paragraph.one'} />
                    </h3>
                    <p>
                        <FormattedMessage id={'steadyPolicy.informedConsent.paragraph.two'} />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.informedConsent.paragraph.three'}
                            values={rtfFormat()}
                        />
                    </p>
                    <h3>
                        <FormattedMessage
                            id={'steadyPolicy.informedConsent.expectedBenefits.paragraph.one'}
                        />
                    </h3>
                    <ul>
                        <li>
                            <FormattedMessage
                                id={'steadyPolicy.informedConsent.expectedBenefits.list.one'}
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                id={'steadyPolicy.informedConsent.expectedBenefits.list.two'}
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                id={'steadyPolicy.informedConsent.expectedBenefits.list.three'}
                            />
                        </li>
                    </ul>
                    <h3>
                        <FormattedMessage
                            id={'steadyPolicy.informedConsent.possibleRisks.paragraph.one'}
                        />
                    </h3>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.informedConsent.possibleRisks.paragraph.two'}
                        />
                    </p>
                    <ul>
                        <li>
                            <FormattedMessage
                                id={'steadyPolicy.informedConsent.possibleRisks.list.one'}
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                id={'steadyPolicy.informedConsent.possibleRisks.list.two'}
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                id={'steadyPolicy.informedConsent.possibleRisks.list.three'}
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                id={'steadyPolicy.informedConsent.possibleRisks.list.four'}
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                id={'steadyPolicy.informedConsent.possibleRisks.list.five'}
                            />
                        </li>
                    </ul>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.informedConsent.acknowledgeAndAgree.paragraph.one'}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.informedConsent.acknowledgeAndAgree.paragraph.two'}
                        />
                    </p>
                    <h3>
                        <FormattedMessage
                            id={'steadyPolicy.informedConsent.disclosures.paragraph.one'}
                        />
                    </h3>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.informedConsent.disclosures.paragraph.two'}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.informedConsent.disclosures.paragraph.three'}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.informedConsent.disclosures.paragraph.four'}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.informedConsent.disclosures.paragraph.five'}
                            values={{
                                link: (chunks) => (
                                    <Link
                                        rel={'noreferrer'}
                                        target={'_blank'}
                                        url={'mailto:support@steadymd.com'}
                                    >
                                        {chunks}
                                    </Link>
                                ),
                            }}
                        />
                    </p>
                </div>
                <div className={'content-item'}>
                    <h2>
                        <FormattedMessage id={'steadyPolicy.patientBillRights.title'} />
                    </h2>
                    <p>
                        <FormattedMessage id={'steadyPolicy.patientBillRights.paragraph.one'} />
                    </p>
                </div>
                <div className={'content-item'}>
                    <h2>
                        <FormattedMessage id={'steadyPolicy.stateSpecificNotifications.title'} />
                    </h2>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.stateSpecificNotifications.paragraph.one'}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.stateSpecificNotifications.paragraph.two'}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.stateSpecificNotifications.paragraph.three'}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.stateSpecificNotifications.paragraph.four'}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.stateSpecificNotifications.paragraph.five'}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.stateSpecificNotifications.paragraph.six'}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.stateSpecificNotifications.paragraph.seven'}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.stateSpecificNotifications.paragraph.eight'}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.stateSpecificNotifications.paragraph.nine'}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.stateSpecificNotifications.paragraph.ten'}
                        />
                    </p>
                    <p style={{marginBottom: 'var(--denim-spacing-8)'}}>
                        <FormattedMessage
                            id={'steadyPolicy.stateSpecificNotifications.paragraph.eleven'}
                        />
                    </p>
                    <ul
                        className={'no-margin'}
                        style={{marginLeft: 'var(--denim-spacing-24)'}}
                    >
                        <li style={{margin: 'var(--denim-spacing-8) 0'}}>
                            <FormattedMessage
                                id={'steadyPolicy.stateSpecificNotifications.paragraph.eleven.pointOne'}
                            />
                        </li>
                        <li style={{margin: 'var(--denim-spacing-8) 0'}}>
                            <FormattedMessage
                                id={'steadyPolicy.stateSpecificNotifications.paragraph.eleven.pointTwo'}
                            />
                        </li>
                        <li style={{margin: 'var(--denim-spacing-8) 0'}}>
                            <FormattedMessage
                                id={'steadyPolicy.stateSpecificNotifications.paragraph.eleven.pointThree'}
                            />
                        </li>
                    </ul>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.stateSpecificNotifications.paragraph.twelve'}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.stateSpecificNotifications.paragraph.thirteen'}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.stateSpecificNotifications.paragraph.fourteen'}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.stateSpecificNotifications.lastUpdated'}
                            values={rtfFormat()}
                        />
                    </p>
                </div>
                <div className={'content-item'}>
                    <h2>
                        <FormattedMessage id={'steadyPolicy.hipaaNotice.title'} />
                    </h2>
                    <h3>
                        <FormattedMessage id={'steadyPolicy.hipaaNotice.privacyObligations.title'} />
                    </h3>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.hipaaNotice.privacyObligations.paragraph.one'}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.hipaaNotice.privacyObligations.paragraph.two'}
                        />
                    </p>
                    <h3>
                        <FormattedMessage id={'steadyPolicy.hipaaNotice.usePHI.title'} />
                    </h3>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.hipaaNotice.usePHI.paragraph.one'}
                            values={rtfFormat()}
                        />
                    </p>
                    <p>
                        <FormattedMessage id={'steadyPolicy.hipaaNotice.usePHI.paragraph.two'} />
                    </p>
                    <p>
                        <FormattedMessage id={'steadyPolicy.hipaaNotice.usePHI.paragraph.three'} />
                    </p>
                    <h3>
                        <FormattedMessage id={'steadyPolicy.hipaaNotice.usePHI.paragraph.four'} />
                    </h3>
                    <p>
                        <FormattedMessage id={'steadyPolicy.hipaaNotice.usePHI.paragraph.five'} />
                    </p>
                    <h3>
                        <FormattedMessage
                            id={'steadyPolicy.hipaaNotice.otherHealthCareProviders.paragraph.one'}
                        />
                    </h3>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.hipaaNotice.otherHealthCareProviders.paragraph.two'}
                        />
                    </p>
                    <h3>
                        <FormattedMessage id={'steadyPolicy.hipaaNotice.disclosures.paragraph.one'} />
                    </h3>
                    <p>
                        <FormattedMessage id={'steadyPolicy.hipaaNotice.disclosures.paragraph.two'} />
                    </p>
                    <h3>
                        <FormattedMessage
                            id={'steadyPolicy.hipaaNotice.publicHealthActivities.paragraph.one'}
                        />
                    </h3>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.hipaaNotice.publicHealthActivities.paragraph.two'}
                        />
                    </p>
                    <h3>
                        <FormattedMessage
                            id={'steadyPolicy.hipaaNotice.healthOversight.paragraph.one'}
                        />
                    </h3>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.hipaaNotice.healthOversight.paragraph.two'}
                        />
                    </p>
                    <h3>
                        <FormattedMessage
                            id={'steadyPolicy.hipaaNotice.judicialAdmin.paragraph.one'}
                        />
                    </h3>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.hipaaNotice.judicialAdmin.paragraph.two'}
                        />
                    </p>
                    <h3>
                        <FormattedMessage id={'steadyPolicy.hipaaNotice.enforcement.paragraph.one'} />
                    </h3>
                    <p>
                        <FormattedMessage id={'steadyPolicy.hipaaNotice.enforcement.paragraph.two'} />
                    </p>
                    <h3>
                        <FormattedMessage id={'steadyPolicy.hipaaNotice.decedents.paragraph.one'} />
                    </h3>
                    <p>
                        <FormattedMessage id={'steadyPolicy.hipaaNotice.decedents.paragraph.two'} />
                    </p>
                    <h3>
                        <FormattedMessage id={'steadyPolicy.hipaaNotice.organTissue.paragraph.one'} />
                    </h3>
                    <p>
                        <FormattedMessage id={'steadyPolicy.hipaaNotice.organTissue.paragraph.two'} />
                    </p>
                    <h3>
                        <FormattedMessage id={'steadyPolicy.hipaaNotice.research.paragraph.one'} />
                    </h3>
                    <p>
                        <FormattedMessage id={'steadyPolicy.hipaaNotice.research.paragraph.two'} />
                    </p>
                    <h3>
                        <FormattedMessage id={'steadyPolicy.hipaaNotice.workersComp.paragraph.one'} />
                    </h3>
                    <p>
                        <FormattedMessage id={'steadyPolicy.hipaaNotice.workersComp.paragraph.two'} />
                    </p>
                    <h3>
                        <FormattedMessage
                            id={'steadyPolicy.hipaaNotice.disasterRelief.paragraph.one'}
                        />
                    </h3>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.hipaaNotice.disasterRelief.paragraph.two'}
                        />
                    </p>
                    <h3>
                        <FormattedMessage
                            id={'steadyPolicy.hipaaNotice.schoolImmunizations.paragraph.one'}
                        />
                    </h3>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.hipaaNotice.schoolImmunizations.paragraph.two'}
                        />
                    </p>
                    <h3>
                        <FormattedMessage id={'steadyPolicy.hipaaNotice.fundraising.paragraph.one'} />
                    </h3>
                    <p>
                        <FormattedMessage id={'steadyPolicy.hipaaNotice.fundraising.paragraph.two'} />
                    </p>
                    <h3>
                        <FormattedMessage
                            id={'steadyPolicy.hipaaNotice.requiredByLaw.paragraph.one'}
                        />
                    </h3>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.hipaaNotice.requiredByLaw.paragraph.two'}
                        />
                    </p>
                    <h2>
                        <FormattedMessage
                            id={'steadyPolicy.hipaaNotice.writtenPermission.paragraph.one'}
                        />
                    </h2>
                    <h3>
                        <FormattedMessage
                            id={'steadyPolicy.hipaaNotice.writtenPermission.paragraph.two'}
                        />
                    </h3>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.hipaaNotice.writtenPermission.paragraph.three'}
                            values={rtfFormat()}
                        />
                    </p>
                    <h3>
                        <FormattedMessage id={'steadyPolicy.hipaaNotice.marketing.paragraph.one'} />
                    </h3>
                    <p>
                        <FormattedMessage id={'steadyPolicy.hipaaNotice.marketing.paragraph.two'} />
                    </p>
                    <h3>
                        <FormattedMessage
                            id={'steadyPolicy.hipaaNotice.usesConfidentialInformation.paragraph.one'}
                        />
                    </h3>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.hipaaNotice.usesConfidentialInformation.paragraph.two'}
                        />
                    </p>
                    <h2>
                        <FormattedMessage id={'steadyPolicy.hipaaNotice.yourRights.title'} />
                    </h2>
                    <h3>
                        <FormattedMessage id={'steadyPolicy.hipaaNotice.yourRights.paragraph.one'} />
                    </h3>
                    <p>
                        <FormattedMessage id={'steadyPolicy.hipaaNotice.yourRights.paragraph.two'} />
                    </p>
                    <h3>
                        <FormattedMessage id={'steadyPolicy.hipaaNotice.yourRights.paragraph.three'} />
                    </h3>
                    <p>
                        <FormattedMessage id={'steadyPolicy.hipaaNotice.yourRights.paragraph.four'} />
                    </p>
                    <h3>
                        <FormattedMessage id={'steadyPolicy.hipaaNotice.yourRights.paragraph.five'} />
                    </h3>
                    <p>
                        <FormattedMessage id={'steadyPolicy.hipaaNotice.yourRights.paragraph.six'} />
                    </p>
                    <h3>
                        <FormattedMessage id={'steadyPolicy.hipaaNotice.yourRights.paragraph.seven'} />
                    </h3>
                    <p>
                        <FormattedMessage id={'steadyPolicy.hipaaNotice.yourRights.paragraph.eight'} />
                    </p>
                    <h3>
                        <FormattedMessage id={'steadyPolicy.hipaaNotice.yourRights.paragraph.nine'} />
                    </h3>
                    <p>
                        <FormattedMessage id={'steadyPolicy.hipaaNotice.yourRights.paragraph.ten'} />
                    </p>
                    <h3>
                        <FormattedMessage
                            id={'steadyPolicy.hipaaNotice.yourRights.paragraph.eleven'}
                        />
                    </h3>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.hipaaNotice.yourRights.paragraph.twelve'}
                        />
                    </p>
                    <h3>
                        <FormattedMessage
                            id={'steadyPolicy.hipaaNotice.yourRights.paragraph.thirteen'}
                        />
                    </h3>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.hipaaNotice.yourRights.paragraph.fourteen'}
                        />
                    </p>
                    <h3>
                        <FormattedMessage
                            id={'steadyPolicy.hipaaNotice.yourRights.paragraph.fifteen'}
                        />
                    </h3>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.hipaaNotice.yourRights.paragraph.sixteen'}
                        />
                    </p>
                    <h3>
                        <FormattedMessage
                            id={'steadyPolicy.hipaaNotice.effectiveDate.paragraph.one'}
                        />
                    </h3>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.hipaaNotice.effectiveDate.paragraph.two'}
                        />
                    </p>
                    <h3>
                        <FormattedMessage id={'steadyPolicy.hipaaNotice.changeTerms.paragraph.one'} />
                    </h3>
                    <p>
                        <FormattedMessage id={'steadyPolicy.hipaaNotice.changeTerms.paragraph.two'} />
                    </p>
                    <h3>
                        <FormattedMessage id={'steadyPolicy.hipaaNotice.fedStateLaw.paragraph.one'} />
                    </h3>
                    <p>
                        <FormattedMessage id={'steadyPolicy.hipaaNotice.fedStateLaw.paragraph.two'} />
                    </p>
                    <h3>
                        <FormattedMessage
                            id={'steadyPolicy.hipaaNotice.questionsConcerns.paragraph.one'}
                        />
                    </h3>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.hipaaNotice.questionsConcerns.paragraph.two'}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.hipaaNotice.questionsConcerns.paragraph.three'}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'steadyPolicy.hipaaNotice.questionsConcerns.paragraph.four'}
                            values={{
                                link: (chunks) => (
                                    <Link
                                        rel={'noreferrer'}
                                        target={'_blank'}
                                        url={'mailto:legal@steadymd.com'}
                                    >
                                        {chunks}
                                    </Link>
                                ),
                            }}
                        />
                    </p>
                </div>
            </GridContainer>
        </section>
    );
}
