import {
    AuthenticationGuard,
    AuthenticationState,
    KitActivationGuard,
    PaymentGuard,
    PaymentState,
    ProfileState,
    ReorderTestGuard,
    ReportGuard,
    SignUpGuard,
} from './guards';

// As the current user flow through the application is essentially linear, we can infer the protect state of each step
// based on the need to have completed the previous step. Note that the `notAuthenticated` state is peculiar to the
// pre-account creation portion of the sign-up flow and nothing else.
//
// Thus, the user flow through the application is essentially:
//   - authenticated
//   - consented
//   - emailVerified
//   - signedUp
//   - paid
//   - registeredKit
//   - resultsAvailable
//
// Therefore is we assume all props to be unset if `false`, you only need to set the one prop to `true`, to ensure all
// previous protection steps are also checked. For example, to ensure the user has `paid`, we need to check the
// following states in order: authenticated -> consented -> emailVerified -> signedUp -> paid. If any of them fail, then
// we can assume the user has not paid and redirect as necessary.
export function ProtectedRoute(props) {
    const {
        authenticated,
        component: Component,
        expiredTestOrder,
        navigateTo,
        notAuthenticated,
        paymentRequired,
        paid,
        resultsAvailable,
        signUpCompleted,
        signUpRequired,
        unregisteredKit,
    } = props;

    // For pages that require just authentication, or explicitly requre the user to not be authenticated
    if (notAuthenticated || authenticated) {
        return (
            <AuthenticationGuard
                state={authenticated
                    ? AuthenticationState.AUTHENTICATED
                    : AuthenticationState.UNAUTHENTICATED}
            >
                <Component />
            </AuthenticationGuard>
        );
    }

    // Depending on the state, pages that can only be accessed if the user hasn't finished sign-up, or pages that the
    // user can only access after sign-up
    if (signUpRequired || signUpCompleted) {
        return (
            <AuthenticationGuard state={AuthenticationState.AUTHENTICATED}>
                <SignUpGuard state={signUpRequired ? ProfileState.INCOMPLETE : ProfileState.COMPLETE}>
                    <Component />
                </SignUpGuard>
            </AuthenticationGuard>
        );
    }

    // Depending on the state, pages that can only be accessed if the user hasn't paid for their test, or pages that the
    // user can only accessed after paying for their test
    if (paymentRequired || paid) {
        return (
            <AuthenticationGuard state={AuthenticationState.AUTHENTICATED}>
                <SignUpGuard state={ProfileState.COMPLETE}>
                    <PaymentGuard state={paymentRequired ? PaymentState.REQUIRED : PaymentState.SETTLED}>
                        <Component />
                    </PaymentGuard>
                </SignUpGuard>
            </AuthenticationGuard>
        );
    }

    // Depending on the state, pages that can only be accessed if the user has been invited to re-order a test, or pages
    // that can only be accessed after a test has been re-ordered
    if (expiredTestOrder) {
        return (
            <AuthenticationGuard state={AuthenticationState.AUTHENTICATED}>
                <SignUpGuard state={ProfileState.COMPLETE}>
                    <PaymentGuard state={PaymentState.SETTLED}>
                        <ReorderTestGuard>
                            <Component />
                        </ReorderTestGuard>
                    </PaymentGuard>
                </SignUpGuard>
            </AuthenticationGuard>
        );
    }

    // For the kit activation flow, you should only be able to access it, if you have an unregistered kit
    if (unregisteredKit) {
        return (
            <AuthenticationGuard state={AuthenticationState.AUTHENTICATED}>
                <SignUpGuard state={ProfileState.COMPLETE}>
                    <PaymentGuard state={PaymentState.SETTLED}>
                        <KitActivationGuard>
                            <Component />
                        </KitActivationGuard>
                    </PaymentGuard>
                </SignUpGuard>
            </AuthenticationGuard>
        );
    }

    // For the results pages, which you should only be able to access, if you have results available
    if (resultsAvailable) {
        return (
            <AuthenticationGuard state={AuthenticationState.AUTHENTICATED}>
                <SignUpGuard state={ProfileState.COMPLETE}>
                    <PaymentGuard state={PaymentState.SETTLED}>
                        <ReportGuard navigateTo={navigateTo}>
                            <Component />
                        </ReportGuard>
                    </PaymentGuard>
                </SignUpGuard>
            </AuthenticationGuard>
        );
    }

    // Nothing was set, so output an error to the console and render the component
    console.error('ProtectedRoute not configured to protected anything');
    return <Component />;
}
