import {Navigate} from 'react-router-dom';
import {
    FetchError,
    Loading,
} from '../../../components';
import {useQuery} from '../../../hooks';
import {Routes} from '../../../Routes';
import {
    Endpoint,
    extractErrorMessage,
} from '../../../util';
import {PhysicianReviewStatus} from './KitActivationGuard';

export function ReorderTestGuard(props) {
    const {children} = props;

    const {
        data: {
            physicianReviewStatus = PhysicianReviewStatus.NONE,
        } = {},
        error,
        loading,
    } = useQuery(Endpoint.SUMMARY);

    if (error) {
        return (
            <div
                className={'guard'}
                key={'error'}
            >
                <FetchError reason={extractErrorMessage(error)} />
            </div>
        );
    }

    if (loading) {
        return (
            <div
                className={'guard'}
                key={'loading'}
            >
                <Loading key={'loading'} />
            </div>
        );
    }

    // Only display the page if the user has an expired test order
    if (physicianReviewStatus === PhysicianReviewStatus.REORDER_INVITE_SENT) {
        return children;
    }

    // If they haven't been invited to reorder their expired test, send them to the /home route
    return (
        <Navigate
            replace={true}
            to={Routes.HOME}
        />
    );
}
