import {ErrorCard} from '@genomics-dev/denim-components';
import {
    createContext,
    useContext,
    useEffect,
    useState,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {
    ContactSupport,
    Loading,
} from '../components';
import {
    AmplitudeClient,
    ExperimentClient,
    IhrConfig,
} from '../config';
import {devConsole} from '../util';

const ConfigContext = createContext({});

export function useConfigContext() {
    const context = useContext(ConfigContext);

    if (typeof context === 'undefined') {
        throw new Error(`Config context must be used within a "ConfigProvider"`);
    }

    return context;
}

export function useConfig() {
    const {config} = useConfigContext();
    return config;
}

export function useAmplitude() {
    const {amplitudeClient} = useConfigContext();
    return amplitudeClient;
}

export function useFeatureFlags() {
    const {featureFlags} = useConfigContext();
    return featureFlags;
}

export function ConfigProvider({children}) {
    const intl = useIntl();
    const [loaded, setLoaded] = useState(false);
    const [config, setConfig] = useState({});
    const [amplitudeClient, setAmplitudeClient] = useState(null);
    const [featureFlags, setFeatureFlags] = useState({});
    const [error, setError] = useState(null);

    useEffect(() => {
        const configure = async () => {
            const appConfig = await IhrConfig.init();

            // Set the config
            setConfig(appConfig);

            // Configure Amplitude
            setAmplitudeClient(AmplitudeClient.load(appConfig?.amplitudeApiKey));

            // Start any experiments
            try {
                devConsole('Attempting to setup Amplitude A/B experiments');
                const experimentClient = ExperimentClient.load(appConfig?.amplitudeApiKey);
                await experimentClient.startExperiment();
                setFeatureFlags({headerCarousel: experimentClient.isVariant('2024-04-18-ab-header-carousel')});
            }
            catch (_err) {
                // If something went wrong trying to setup the Amplitude experiments, then just ignore it...
            }
        };

        configure()
            .catch((err) => setError(err))
            .finally(() => setLoaded(true));
    }, []);

    if (error) {
        // This error happens outside the router provider, so none of the links in things like the legal footer or
        // error notice work. Worse still, the use of useLocation in the mobile navbar also fails. Hence, we replicate
        // a bit of layout here with an error card in it. In normal use, no-one should ever see this.
        return (
            <div className={'minimal-layout error-layout'}>
                <main
                    className={'config-error'}
                    data-test-id={'config-error'}
                >
                    <ErrorCard title={intl.formatMessage({id: 'common.configError.title'})}>
                        <p>
                            <FormattedMessage
                                id={'common.configError.description'}
                                values={{cs: (chunk) => <ContactSupport text={chunk} />}}
                            />
                        </p>
                    </ErrorCard>
                </main>
            </div>
        );
    }

    if (loaded) {
        return (
            <ConfigContext.Provider
                value={{
                    amplitudeClient,
                    config,
                    featureFlags,
                }}
            >
                {children}
            </ConfigContext.Provider>
        );
    }

    return (
        <div className={'guard'}>
            <Loading key={'loading'} />
        </div>
    );
}
