/* eslint-disable no-magic-numbers */
import {Callout} from '@genomics-dev/denim-components';
import {FormattedMessage} from 'react-intl';
import {Trait} from '../../../../../util';
import {Alcohol} from './Alcohol';
import {Diet} from './diet';
import {Exercise} from './exercise';
import {Sleep} from './Sleep';
import {Smoke} from './Smoke';
import {Weight} from './weight';

export function LifestyleActions(props) {
    const {traitCode} = props;

    const showSmokeAdvice = traitCode !== Trait.BC.code;
    const showAlcoholAdvice = [
        Trait.AF.code,
        Trait.BC.code,
        Trait.CVD_US.code,
        Trait.HT.code,
        Trait.FNDXAT_N1P0.code,
    ].includes(traitCode);
    const showSleepAdvice = [Trait.CVD_US.code, Trait.HT.code].includes(traitCode);

    const totalSteps = 3 + showSmokeAdvice + showAlcoholAdvice + showSleepAdvice;

    return (
        <section className={'lifestyle-actions section-divider layout two-thirds'}>
            <div className={'sidebar'}>
                <h2>
                    <FormattedMessage id={'reports.trait.lifestyleActions.title'} />
                </h2>
                <div
                    className={'count is-caption is-heavy'}
                    data-test-id={'lifestyle-actions-step-count'}
                >
                    <FormattedMessage
                        id={'reports.trait.lifestyleActions.goals'}
                        values={{count: totalSteps}}
                    />
                </div>
            </div>
            <div className={'content'}>
                <Callout>
                    <FormattedMessage id={'reports.trait.lifestyleActions.infoBox'} />
                </Callout>
                <Weight
                    currentStep={1}
                    totalSteps={totalSteps}
                    traitCode={traitCode}
                />
                {showSmokeAdvice && (
                    <Smoke
                        currentStep={2}
                        totalSteps={totalSteps}
                        traitCode={traitCode}
                    />
                )}
                <Exercise
                    currentStep={2 + showSmokeAdvice}
                    totalSteps={totalSteps}
                    traitCode={traitCode}
                />
                {showAlcoholAdvice && (
                    <Alcohol
                        currentStep={3 + showSmokeAdvice}
                        totalSteps={totalSteps}
                        traitCode={traitCode}
                    />
                )}
                <Diet
                    currentStep={3 + showSmokeAdvice + showAlcoholAdvice}
                    totalSteps={totalSteps}
                    traitCode={traitCode}
                />
                {showSleepAdvice && (
                    <Sleep
                        currentStep={4 + showSmokeAdvice + showAlcoholAdvice}
                        totalSteps={totalSteps}
                        traitCode={traitCode}
                    />
                )}
            </div>
        </section>
    );
}
