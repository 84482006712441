import {Footer} from '@genomics-dev/denim-components';
import classNames from 'classnames';
import {useMemo} from 'react';
import {Logotype} from '../../components';
import {useUserContext} from '../../context';
import {FooterContent as AuthenticatedFooterContent} from './authenticated';
import {FooterContent as UnauthenticatedFooterContent} from './unauthenticated';

export function PrimaryFooter(props) {
    const {
        className,
        dataTestId,
        marketingRoutes,
    } = props;

    const {isAuthenticated} = useUserContext();

    const FooterContent = useMemo(() => {
        if (isAuthenticated) {
            return AuthenticatedFooterContent;
        }

        return UnauthenticatedFooterContent;
    }, [isAuthenticated]);

    const footerClasses = classNames('primary-footer', className);

    return (
        <Footer
            className={footerClasses}
            dataTestId={dataTestId ?? 'primary-footer'}
            role={'navigation'}
        >
            <div className={'grid'}>
                <div className={'footer-content flex-column-to-row space-between'}>
                    <div className={'logotype-container'}>
                        <Logotype
                            dataTestId={'health-insights-logotype-footer'}
                            poweredByLogotype={true}
                        />
                    </div>
                    <div className={'flex-column-to-row space-between links'}>
                        {FooterContent && <FooterContent marketingRoutes={marketingRoutes} />}
                    </div>
                </div>
            </div>
        </Footer>
    );
}
