import {
    Card,
    Variant,
} from '@genomics-dev/denim-components';
import classNames from 'classnames';
import {useCallback} from 'react';
import {FormattedMessage} from 'react-intl';
import {useNavigate} from 'react-router-dom';
import {Routes} from '../../../../Routes';
import {traitCodeToPathname} from '../../../../util';

export function ReportLink(props) {
    const {
        dataTestId,
        isCompact = false,
        riskLevel,
        trait,
    } = props;

    const navigate = useNavigate();

    const onClickHandler = useCallback(() => {
        navigate(`${Routes.RESULTS}/${traitCodeToPathname(trait)}`);
    }, []);

    const cardClassNames = classNames('report-link', riskLevel.toLocaleLowerCase(), {compact: isCompact});
    const riskLevelClasses = classNames('risk-gradient', 'text', 'is-heavy', {
        [riskLevel.toLocaleLowerCase()]: riskLevel,
    });

    return (
        <Card
            className={cardClassNames}
            dataTestId={`report-link-${dataTestId ?? trait}`}
            variant={Variant.LIGHT}
        >
            <div
                className={'shroud'}
                onClick={onClickHandler}
            >
                {isCompact
                    ? (
                        <>
                            <span>
                                <FormattedMessage id={`common.trait.${trait}.title`} />
                            </span>
                            <span className={'icon after chevron--right'} />
                        </>
                    )
                    : (
                        <>
                            <h4 className={'icon after arrow--right'}>
                                <FormattedMessage id={`common.trait.${trait}.title`} />
                            </h4>
                            <div>
                                <FormattedMessage
                                    id={`reports.overview.trait.risk.${riskLevel}`}
                                    values={{
                                        riskLevel: (chunks) => <span className={riskLevelClasses}>{chunks}</span>,
                                    }}
                                />
                            </div>
                        </>
                    )}
            </div>
        </Card>
    );
}
