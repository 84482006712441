import {
    Button,
    Variant,
} from '@genomics-dev/denim-components';
import {useCallback} from 'react';
import {FormattedMessage} from 'react-intl';
import {useNavigate} from 'react-router-dom';
import {
    FetchError,
    FetchValidationError,
    Loading,
    Test,
} from '../../../components';
import {useAmplitude} from '../../../context';
import {
    useMutation,
    useQuery,
    useTraitEligibility,
} from '../../../hooks';
import {Routes} from '../../../Routes';
import {
    Endpoint,
    extractErrorMessage,
} from '../../../util';

export function YourReports() {
    const amplitude = useAmplitude();
    const [confirmRegistration, {error: confirmError, loading: confirmLoading}] = useMutation(
        Endpoint.CONFIRM_REGISTRATION,
        {cacheKey: Endpoint.SUMMARY},
    );
    const navigate = useNavigate();
    const {data: summaryData} = useQuery(Endpoint.SUMMARY);
    const {
        eligibleTraits,
        error: traitError,
        ineligibleTraits,
        loading: traitLoading,
    } = useTraitEligibility();

    const onClickHandler = useCallback(async () => {
        await confirmRegistration();
        amplitude.logAnswersConfirmed();

        // If payment is required, we may as well go straight ot teh checkout and avoid the protection logic
        navigate(
            (summaryData?.payment === 'REQUIRED')
                ? Routes.CHECKOUT
                : Routes.HOME,
        );
    }, [amplitude, summaryData]);

    if (traitLoading) {
        return <Loading />;
    }

    if (traitError) {
        return <FetchError reason={extractErrorMessage(traitError)} />;
    }

    return (
        <>
            <h2 data-test-id={'sign-up-my-reports-title'}>
                <FormattedMessage id={'signUp.myReports.title'} />
            </h2>
            <FetchValidationError errorResponse={confirmError} />
            {Boolean(eligibleTraits?.length) && (
                <div className={'test-block'}>
                    <div className={'is-caption is-heavy'}>
                        <FormattedMessage id={'common.profile.tests.included'} />
                    </div>
                    {eligibleTraits.map(({trait}) => (
                        <Test
                            dataTestId={`included-trait-${trait}`}
                            included={true}
                            key={trait}
                            trait={trait}
                        />
                    ))}
                </div>
            )}
            {Boolean(ineligibleTraits?.length) && (
                <div className={'test-block'}>
                    <div className={'is-caption is-heavy'}>
                        <FormattedMessage id={'common.profile.tests.notIncluded'} />
                    </div>
                    {ineligibleTraits.map(({trait}) => (
                        <Test
                            dataTestId={`not-included-trait-${trait}`}
                            included={false}
                            key={trait}
                            trait={trait}
                        />
                    ))}
                </div>
            )}
            <Button
                dataTestId={'sign-up-confirm-answers'}
                isLoading={confirmLoading}
                onClick={onClickHandler}
                variant={Variant.PRIMARY}
            >
                {(summaryData?.payment === 'REQUIRED')
                    ? <FormattedMessage id={'signUp.myReports.confirmAnswers.toPay'} />
                    : <FormattedMessage id={'signUp.myReports.confirmAnswers.paid'} />}
            </Button>
        </>
    );
}
