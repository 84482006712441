import {StatusCodes} from 'http-status-codes';
import {
    Navigate,
    useLocation,
} from 'react-router-dom';
import {
    FetchError,
    Loading,
} from '../../../components';
import {useQuery} from '../../../hooks';
import {Routes} from '../../../Routes';
import {
    Endpoint,
    extractErrorMessage,
} from '../../../util';

export class ProfileState {
    static COMPLETE = 'COMPLETE';

    static INCOMPLETE = 'INCOMPLETE';

    static validate(value) {
        return [this.COMPLETE, this.INCOMPLETE].includes(value);
    }
}

export function SignUpGuard(props) {
    const {children, state} = props;

    const {pathname} = useLocation();
    const {
        data: {registration: {confirmed = false} = {}} = {},
        error,
        loading,
    } = useQuery(Endpoint.SUMMARY);

    if (!ProfileState.validate(state)) {
        throw new Error(`Unknown ProfileState - ${state}`);
    }

    if (error) {
        if (error.status === StatusCodes.FORBIDDEN) {
            // If the route that was requested was the consent, or email verification pages, then we should render
            // those, and not actually try and navigate the user to them
            if (pathname.includes(Routes.SIGN_UP_CONSENT) || pathname.includes(Routes.SIGN_UP_VERIFY_EMAIL)) {
                return children;
            }

            // User has either not consented, or not verified their email, either way, send them to the sign-up flow
            // and let the prerequisites handle it
            return <Navigate to={Routes.SIGN_UP_ABOUT_YOU} />;
        }

        return (
            <div
                className={'guard'}
                key={'error'}
            >
                <FetchError reason={extractErrorMessage(error)} />
            </div>
        );
    }

    if (loading) {
        return (
            <div
                className={'guard'}
                key={'loading'}
            >
                <Loading key={'loading'} />
            </div>
        );
    }

    // When the user profile needs to be complete
    if (state === ProfileState.COMPLETE) {
        if (confirmed) {
            // If the user has completed registration, we can't just display whatever it is, as they shouldn't be able
            // to get back into the sign-up flow, so ensure the page they've requested isn't within that flow
            return pathname.includes(Routes.SIGN_UP)
                ? (
                    <Navigate
                        replace={true}
                        to={Routes.HOME}
                    />
                )
                : children;
        }

        // They have not completed sign-up, so send them there and let it work out which page should be displayed
        return (
            <Navigate
                replace={true}
                to={Routes.SIGN_UP_SUMMARY}
            />
        );
    }

    // When the user profile needs to be incomplete.
    if (confirmed) {
        // They've completed sign-up, so send them to their account home
        return (
            <Navigate
                replace={true}
                to={Routes.HOME}
            />
        );
    }

    // If they're trying to access a sign-up flow page, then render it.
    // Otherwise, they're trying to access something outwith sign-up, so send them back to finish it. So send them there
    // and let it work out which page should be displayed
    return pathname.includes(Routes.SIGN_UP)
        ? children
        : (
            <Navigate
                replace={true}
                to={Routes.SIGN_UP_SUMMARY}
            />
        );
}
