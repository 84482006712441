import {MoreInfo} from '@genomics-dev/denim-components';
import {useIntl} from 'react-intl';
import {Link as InternalLink} from 'react-router-dom';
import {Routes} from '../../Routes';

export function TestMoreInfo() {
    const intl = useIntl();

    return (
        <>
            <MoreInfo
                content={intl.formatMessage({id: 'common.test.moreInfo.why.content'})}
                dataTestId={'more-info-why'}
                title={intl.formatMessage({id: 'common.test.moreInfo.why.title'})}
            />
            <MoreInfo
                content={intl.formatMessage(
                    {id: 'common.test.moreInfo.secure.content'},
                    {
                        pn: (chunk) => (
                            <InternalLink
                                className={'link alternate'}
                                to={Routes.PRIVACY_POLICY}
                            >
                                <span>{chunk}</span>
                            </InternalLink>
                        ),
                    },
                )}
                dataTestId={'more-info-secure'}
                title={intl.formatMessage({id: 'common.test.moreInfo.secure.title'})}
            />
        </>
    );
}
