import {
    Card,
    Variant,
} from '@genomics-dev/denim-components';
import classNames from 'classnames';
import {FormattedMessage} from 'react-intl';

export const ActionCardImage = {
    ALCOHOL: 'alcohol',
    DIET: 'diet',
    EXERCISE: 'exercise',
    NONE: 'none',
    SLEEP: 'sleep',
    SMOKE: 'smoke',
    WEIGHT: 'weight',
};

export function GraphicalActionCard(props) {
    const {
        children,
        className,
        currentStep,
        dataTestId,
        headerImage,
        title,
        totalSteps,
    } = props;

    let sanitisedHeaderImage = headerImage;
    if (
        ![
            ActionCardImage.ALCOHOL,
            ActionCardImage.DIET,
            ActionCardImage.EXERCISE,
            ActionCardImage.SLEEP,
            ActionCardImage.SMOKE,
            ActionCardImage.WEIGHT,
        ].includes(headerImage)
    ) {
        // This causes hte card to default ot looking like a normal ActionCard, which is maybe not what we want.
        sanitisedHeaderImage = ActionCardImage.NONE;
    }

    const cardClasses = classNames('action-card', className);
    const headerClasses = classNames('header', {
        'graphical-background': sanitisedHeaderImage !== ActionCardImage.NONE,
        [sanitisedHeaderImage]: sanitisedHeaderImage !== ActionCardImage.NONE,
    });

    return (
        <Card
            className={cardClasses}
            dataTestId={dataTestId}
            variant={Variant.LIGHT}
        >
            <div className={headerClasses}>
                {currentStep && (
                    <div className={'step is-caption is-heavy'}>
                        <FormattedMessage
                            id={'common.goalOfTotal.text'}
                            values={{
                                currentStep,
                                totalSteps,
                            }}
                        />
                    </div>
                )}
                <h4 className={'no-margin'}>{title}</h4>
            </div>
            <div className={'body'}>{children}</div>
        </Card>
    );
}
