import {
    EmbeddedCheckout,
    EmbeddedCheckoutProvider,
} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {
    useCallback,
    useEffect,
    useMemo,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {
    ErrorNotice,
    GridContainer,
} from '../../components';
import {
    useAmplitude,
    useConfig,
} from '../../context';
import {
    useMutation,
    useTitle,
} from '../../hooks';
import {Endpoint} from '../../util';

export function Checkout() {
    const amplitude = useAmplitude();
    const {stripePublicKey} = useConfig();
    const intl = useIntl();
    const [getClientSecret, {error}] = useMutation(Endpoint.CHECKOUT);

    useTitle(intl.formatMessage({id: 'checkout.documentTitle'}));

    // Call `loadStripe` in a useMemo to avoid recreating the `Stripe` object on every render.
    const stripePromise = useMemo(() => {
        return stripePublicKey ? loadStripe(stripePublicKey) : null;
    }, []);

    useEffect(() => {
        amplitude.logCheckoutVisit();
    }, []);

    // The Stripe checkout expects a promise, and doesn't like it if we await the call to our backend
    const fetchClientSecret = useCallback(() => {
        return getClientSecret()
            .then((data) => data?.clientSecret);
    }, []);

    if (error || !stripePublicKey) {
        return <ErrorNotice />;
    }

    return (
        <GridContainer gridClassName={'all-columns'}>
            <h2 data-test-id={'checkout-title'}>
                <FormattedMessage id={'checkout.title'} />
            </h2>
            <EmbeddedCheckoutProvider
                options={{fetchClientSecret}}
                stripe={stripePromise}
            >
                <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
        </GridContainer>
    );
}
