import {Link} from '@genomics-dev/denim-components';
import classNames from 'classnames';
import {useCallback} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {defaultEventHandler} from '../../util';

export function BackLink(props) {
    const {
        className,
        onClick = defaultEventHandler,
        textId = 'common.back.text',
        titleId = 'common.back.title',
    } = props;

    const intl = useIntl();

    const onClickHandler = useCallback(
        (event) => {
            if (onClick) {
                onClick(event);
            }
        },
        [onClick],
    );

    const classes = classNames(
        'back-link',
        'no-decoration',
        'icon',
        'before',
        'chevron--left',
        className,
    );

    return (
        <Link
            className={classes}
            dataTestId={'back-link'}
            onClick={onClickHandler}
            title={intl.formatMessage({id: titleId})}
        >
            <FormattedMessage id={textId} />
        </Link>
    );
}
