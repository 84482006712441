import {Navigate} from 'react-router-dom';
import {
    FetchError,
    Loading,
} from '../../../components';
import {useQuery} from '../../../hooks';
import {Routes} from '../../../Routes';
import {
    Endpoint,
    extractErrorMessage,
} from '../../../util';

export class ResultStatus {
    static NONE = 'NONE';

    static PROCESSING = 'PROCESSING';

    static READY = 'READY';
}

export function ReportGuard(props) {
    const {children, navigateTo} = props;

    const {
        data: {resultStatus = ResultStatus.NONE} = {},
        error,
        loading,
    } = useQuery(Endpoint.SUMMARY);

    if (error) {
        return (
            <div
                className={'guard'}
                key={'error'}
            >
                <FetchError reason={extractErrorMessage(error)} />
            </div>
        );
    }

    if (loading) {
        return (
            <div
                className={'guard'}
                key={'loading'}
            >
                <Loading key={'loading'} />
            </div>
        );
    }

    if (resultStatus === ResultStatus.READY) {
        return children;
    }

    return (
        <Navigate
            replace={true}
            to={navigateTo ?? Routes.HOME}
        />
    );
}
