import {Link} from '@genomics-dev/denim-components';
import {useEffect} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {useAmplitude} from '../../context';
import {Routes} from '../../Routes';

export function SendYourKitBack(props) {
    const {name} = props;

    const amplitude = useAmplitude();
    const intl = useIntl();

    useEffect(() => {
        amplitude.logSampleActivated();
    }, [amplitude]);

    return (
        <>
            <div className={'left'}>
                <header>
                    <div
                        className={'is-caption name'}
                        data-test-id={'users-shortened-name'}
                    >
                        {name}
                    </div>
                    <h2
                        className={'page-title'}
                        data-test-id={'home-kit-send-it-back-title'}
                    >
                        <FormattedMessage id={'home.kitSendItBack.title'} />
                    </h2>
                </header>
                <p className={'half-margin'}>
                    <FormattedMessage
                        id={'home.kitSendItBack.paragraphOne'}
                        values={{
                            link: (chunks) => (
                                <Link
                                    className={'alternate'}
                                    rel={'noreferrer'}
                                    target={'_blank'}
                                    url={'https://tools.usps.com/find-location.htm'}
                                >
                                    {chunks}
                                </Link>
                            ),
                        }}
                    />
                </p>
                <p className={'half-margin`'}>
                    <FormattedMessage
                        id={'home.kitSendItBack.paragraphTwo'}
                        values={{
                            link: (chunks) => (
                                <Link
                                    className={'alternate'}
                                    rel={'noreferrer'}
                                    target={'_blank'}
                                    url={'https://tools.usps.com/schedule-pickup-steps.htm'}
                                >
                                    {chunks}
                                </Link>
                            ),
                        }}
                    />
                </p>
                <p className={'half-margin'}>
                    <FormattedMessage id={'home.kitSendItBack.paragraphThree'} />
                </p>
                <p className={'half-margin no-margin-bottom'}>
                    <FormattedMessage
                        id={'home.kitSendItBack.paragraphFour'}
                        values={{
                            link: (chunks) => (
                                <Link
                                    className={'alternate'}
                                    rel={'noreferrer'}
                                    target={'_blank'}
                                    url={Routes.HELP_CENTER}
                                >
                                    {chunks}
                                </Link>
                            ),
                        }}
                    />
                </p>
            </div>
            <div className={'right'}>
                <img
                    alt={intl.formatMessage({id: 'common.testKitBox.alt'})}
                    src={'/images/health-insights-test-kit-box_520x600.jpg'}
                />
            </div>
        </>
    );
}
