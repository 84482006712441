import {
    Button,
    Variant,
} from '@genomics-dev/denim-components';
import {
    useCallback,
    useMemo,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {
    Outlet,
    useLocation,
} from 'react-router-dom';
import {BackLink} from '../../../components';
import {useLinearFlowContext} from '../../../layouts';

export function InformationPage(props) {
    const {noBackLink = [], noButton = []} = props;

    const intl = useIntl();
    const {pathname} = useLocation();
    const {nextStep, previousStep} = useLinearFlowContext();

    const onBackClickHandler = useCallback(() => {
        previousStep();
    }, [previousStep]);

    const onContinueClickHandler = useCallback(() => {
        nextStep();
    }, [nextStep]);

    const displayBackLink = useMemo(() => !noBackLink.includes(pathname), [pathname]);
    const displayButton = useMemo(() => !noButton.includes(pathname), [pathname]);

    return (
        <>
            {displayBackLink && (
                <BackLink
                    className={'less-margin'}
                    onClick={onBackClickHandler}
                />
            )}
            <Outlet />
            {displayButton && (
                <Button
                    className={'no-margin icon arrow--right after'}
                    dataTestId={'sign-up-page-continue'}
                    onClick={onContinueClickHandler}
                    title={intl.formatMessage({id: 'common.button.continue.title'})}
                    variant={Variant.PRIMARY}
                >
                    <FormattedMessage id={'common.button.continue'} />
                </Button>
            )}
        </>
    );
}
