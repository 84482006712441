import {Link} from '@genomics-dev/denim-components';
import {FormattedMessage} from 'react-intl';
import {Routes} from '../../../../Routes';

export function DateNotEligible() {
    return (
        <>
            <h2
                className={'title'}
                data-test-id={'sign-up-date-of-birth-not-eligible-title'}
            >
                <FormattedMessage id={'fetchValidationError.title.DOB_OUTSIDE_RANGE'} />
            </h2>
            <p>
                <FormattedMessage id={'fetchValidationError.paragraph.one.DOB_OUTSIDE_RANGE'} />
            </p>
            <p className={'variant-dark'}>
                <FormattedMessage
                    id={'fetchValidationError.paragraph.two.DOB_OUTSIDE_RANGE'}
                    values={{
                        link: (chunks) => (
                            <Link
                                className={'alternate'}
                                rel={'noreferrer'}
                                target={'_blank'}
                                url={Routes.HELP_CENTER}
                            >
                                {chunks}
                            </Link>
                        ),
                    }}
                />
            </p>
        </>
    );
}
