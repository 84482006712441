import {
    Card,
    Variant,
} from '@genomics-dev/denim-components';
import classNames from 'classnames';
import {FormattedMessage} from 'react-intl';

export function Test(props) {
    const {
        dataTestId,
        included,
        trait,
    } = props;

    const cardClasses = classNames('test', {
        included: included,
        'not-included': !included,
    });

    return (
        <Card
            className={cardClasses}
            dataTestId={dataTestId ?? `test-${trait}`}
            variant={Variant.DARK}
        >
            <img
                alt={''}
                className={'card-icon'}
                role={'presentation'}
                src={`/images/test-${included ? 'included' : 'not-included'}.svg`}
            />
            <div className={'card-content'}>
                <h4 className={'test-name is-medium'}>
                    <FormattedMessage id={`common.trait.${trait}.title`} />
                </h4>
                {!included && (
                    <p className={'is-caption no-margin'}>
                        <FormattedMessage id={`common.profile.tests.notIncluded.${trait}`} />
                    </p>
                )}
            </div>
        </Card>
    );
}
