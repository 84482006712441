import {
    Button,
    FormGroup,
    MoreInfo,
    TextInput,
} from '@genomics-dev/denim-components';
import {
    useCallback,
    useEffect,
    useRef,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {useOutletContext} from 'react-router-dom';
import {useHubSpot} from '../../../../hooks';
import {FetchValidationError} from '../../../fetch-validation-error';

// These are the same as those used in Minerva, we may want to look into alternatives...
const phoneNumberFormatRegexString = '^[\\(]?[0-9]{3}[\\)]?[\\-\\s\\.]?[0-9]{3}[\\-\\s\\.]?[0-9]{4}$';

const FIRST_DASH_INDEX = 3;
const SECOND_DASH_INDEX = 7;

export function PhoneNumber() {
    const hubSpot = useHubSpot();
    const intl = useIntl();
    const {
        currentUserProfile: {phoneNumber = ''} = {},
        errorResponse,
        setPersistFields,
    } = useOutletContext();
    const textInputRef = useRef();

    useEffect(() => {
        setPersistFields(() => () => {
            // Grab the 10 digits of the phone number and put them in format XXX-XXX-XXXX
            const enteredPhoneNumber = textInputRef.current?.value;
            const phoneDigits = enteredPhoneNumber.match(/\d/g);
            phoneDigits.splice(FIRST_DASH_INDEX, 0, '-');
            phoneDigits.splice(SECOND_DASH_INDEX, 0, '-');
            const formattedPhoneNumber = phoneDigits.join('');

            // Return the newly formatted number, rather than what the end user entered...
            return {phoneNumber: formattedPhoneNumber};
        });

        return () => {
            setPersistFields(null);
        };
    }, []);

    const onClickHandler = useCallback(() => {
        hubSpot.open();
    }, []);

    return (
        <>
            <h2
                className={'title'}
                data-test-id={'sign-up-phone-number-title'}
            >
                <FormattedMessage id={'signUp.phoneNumber.title'} />
            </h2>
            <MoreInfo
                content={
                    <>
                        <p className={'half-margin no-margin-top'}>
                            <FormattedMessage id={'signUp.phoneNumber.moreInfo.title'} />
                        </p>
                        <ul>
                            <li>
                                <FormattedMessage id={'signUp.phoneNumber.moreInfo.pointOne'} />
                            </li>
                            <li>
                                <FormattedMessage id={'signUp.phoneNumber.moreInfo.pointTwo'} />
                            </li>
                            <li>
                                <FormattedMessage id={'signUp.phoneNumber.moreInfo.pointThree'} />
                            </li>
                        </ul>
                        <Button onClick={onClickHandler}>
                            <FormattedMessage id={'common.openSupportChat'} />
                        </Button>
                    </>
                }
                title={intl.formatMessage({id: 'common.whyWeAskThisQuestion'})}
            />
            <FetchValidationError errorResponse={errorResponse} />
            <div className={'text-input-width-container'}>
                <FormGroup
                    dataTestId={'sign-up-phone-number-form-group'}
                    description={intl.formatMessage({id: 'signUp.phoneNumber.field.number.description'})}
                    label={intl.formatMessage({id: 'signUp.phoneNumber.field.number.label'})}
                    labelFor={'phone-number'}
                >
                    <TextInput
                        dataTestId={'sign-up-phone-number-input'}
                        errorMessages={{
                            patternMismatch: intl.formatMessage({id: 'signUp.phoneNumber.error.patternMismatch'}),
                            valueMissing: intl.formatMessage({id: 'signUp.phoneNumber.error.valueMissing'}),
                        }}
                        id={'phone-number'}
                        ignoreOnChangeInvalidity={true}
                        name={'phoneNumber'}
                        pattern={phoneNumberFormatRegexString}
                        ref={textInputRef}
                        required={true}
                        type={'tel'}
                        value={phoneNumber ?? ''}
                    />
                </FormGroup>
            </div>
        </>
    );
}
