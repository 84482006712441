import {
    Breakpoint,
    useBreakpoints,
} from '@genomics-dev/denim-components';
import classNames from 'classnames';
import {DesktopLoadingImage} from './DesktopLoadingImage';
import {MobileLoadingImage} from './MobileLoadingImage';

export function Loading(props) {
    const {className} = props;

    const breakpoint = useBreakpoints();

    const LoadingImage = [Breakpoint.DESKTOP, Breakpoint.TABLET_LANDSCAPE].includes(breakpoint)
        ? DesktopLoadingImage
        : MobileLoadingImage;

    const containerClasses = classNames('loading-indicator', className);

    return (
        <div className={containerClasses}>
            <LoadingImage />
        </div>
    );
}
