import {Navbar} from '@genomics-dev/denim-components';
import classNames from 'classnames';
import {Logotype} from '../../components';
import {LegalFooter} from '../legal-footer';

// This layout is for use by the various Providers that wrapper the App component, and shouldn't be used to render
// standard pages within the application. It differs from the <MinimalLayout /> only in the fact that it takes child
// components, rather than having them pumped in via a React Router <Outlet />.

export function ErrorLayout(props) {
    const {
        mainClassName,
        children,
        dataTestId,
    } = props;

    const mainClasses = classNames(mainClassName);

    return (
        <>
            <div className={'minimal-layout error-layout'}>
                <Navbar
                    desktopContent={<Logotype homeLink={true} />}
                    mobileContent={<Logotype homeLink={true} />}
                />
                <main
                    className={mainClasses}
                    data-test-id={dataTestId}
                >
                    {children}
                </main>
            </div>
            <LegalFooter />
        </>
    );
}
