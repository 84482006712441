import {Link} from '@genomics-dev/denim-components';
import {FormattedMessage} from 'react-intl';

export function ProjectsContent() {
    return (
        <>
            <p>
                <FormattedMessage
                    id={'aboutUs.projects.ourFutureHealth'}
                    values={{
                        ofh: (chunk) => (
                            <Link
                                className={'alternate'}
                                dataTestId={'our-future-health-link'}
                                url={'https://ourfuturehealth.org.uk/'}
                            >
                                {chunk}
                            </Link>
                        ),
                    }}
                />
            </p>
            <p>
                <FormattedMessage
                    id={'aboutUs.projects.necs'}
                    values={{
                        prs: (chunk) => (
                            <Link
                                className={'alternate'}
                                dataTestId={'polygenic-risk-study-link'}
                                url={'https://www.genomicseducation.hee.nhs.uk/blog/nhs-launches-new-polygenic-scores-trial-for-heart-disease/'}
                            >
                                {chunk}
                            </Link>
                        ),
                    }}
                />
            </p>
        </>
    );
}
