import {
    Button,
    Callout,
} from '@genomics-dev/denim-components';
import {
    useCallback,
    useReducer,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {
    ContactSupport,
    FetchError,
    Loading,
    QuestionAndAnswer,
} from '../../../components';
import {
    useConfig,
    useUserContext,
} from '../../../context';
import {useQuery} from '../../../hooks';
import {
    action,
    Endpoint,
    extractErrorMessage,
} from '../../../util';

const SET_PASSWORD_RESET_STATUS = 'SET_PASSWORD_RESET_STATUS';
const SET_PASSWORD_RESET_ERROR = 'SET_PASSWORD_RESET_ERROR';
const SET_PASSWORD_RESET_LOADING = 'SET_PASSWORD_RESET_LOADING';

const initialState = {
    passwordResetError: false,
    passwordResetLoading: false,
    passwordResetSent: false,
};

const reducer = (state, {type, payload}) => {
    switch (type) {
        case SET_PASSWORD_RESET_STATUS:
            return {
                ...state,
                passwordResetSent: payload,
            };

        case SET_PASSWORD_RESET_ERROR:
            return {
                ...state,
                passwordResetError: payload,
            };

        case SET_PASSWORD_RESET_LOADING:
            return {
                ...state,
                passwordResetError: payload ? false : state.passwordResetError,
                passwordResetLoading: payload,
            };

        default:
            return state;
    }
};

export function Security() {
    const {user} = useUserContext();
    const {auth0ClientId, auth0Domain} = useConfig();
    const intl = useIntl();
    const {
        data: currentUserProfile,
        error,
        loading,
    } = useQuery(Endpoint.PROFILE);
    const [{passwordResetLoading, passwordResetError, passwordResetSent}, dispatch] = useReducer(
        reducer,
        initialState,
    );

    const onPasswordClickHandler = useCallback(async () => {
        dispatch(action(SET_PASSWORD_RESET_LOADING, true));

        // Request password reset email using Auth0's authentication API:
        // https://auth0.com/docs/authenticate/database-connections/password-change#authentication-api
        const passwordResetUrl = `https://${auth0Domain}/dbconnections/change_password`;

        try {
            const response = await fetch(passwordResetUrl, {
                body: JSON.stringify({
                    // eslint-disable-next-line camelcase
                    client_id: auth0ClientId,
                    connection: 'Username-Password-Authentication',
                    email: user.emailAddress,
                }),
                headers: {'Content-Type': 'application/json'},
                method: 'POST',
            });

            if (response.ok) {
                dispatch(action(SET_PASSWORD_RESET_STATUS, true));
            }
            else {
                if (process.env.NODE_ENV === 'development') {
                    // eslint-disable-next-line no-console
                    console.error(
                        `Unable to send password reset email at this time; status - ${response.status}`,
                    );
                }
                dispatch(action(SET_PASSWORD_RESET_ERROR, true));
            }
        }
        catch (err) {
            if (process.env.NODE_ENV === 'development') {
                // eslint-disable-next-line no-console
                console.error(err);
            }

            dispatch(action(SET_PASSWORD_RESET_ERROR, true));
        }
        finally {
            dispatch(action(SET_PASSWORD_RESET_LOADING, false));

            const THREE_SECONDS = 3000;
            setTimeout(() => {
                dispatch(action(SET_PASSWORD_RESET_STATUS, false));
            }, THREE_SECONDS);
        }
    }, [user]);

    return (
        <section className={'account-content account-and-security'}>
            <header>
                <img
                    alt={''}
                    className={'icon'}
                    role={'presentation'}
                    src={'/images/icon-account.svg'}
                />
                <h2 data-test-id={'account-settings-title'}>
                    <FormattedMessage id={'account.security.title'} />
                </h2>
            </header>

            {error
                ? (
                    <FetchError
                        reason={passwordResetError
                            ? intl.formatMessage(
                                {id: 'account.security.password.error'},
                                {cs: (chunk) => <ContactSupport text={chunk} />},
                            )
                            : extractErrorMessage(error)}
                    />
                )
                : loading
                ? (
                    <div className={'center-within'}>
                        <Loading />
                    </div>
                )
                : (
                    <>
                        <div className={'profile-section'}>
                            <div className={'is-eyebrow'}>
                                <FormattedMessage id={'account.security.detail.title'} />
                            </div>

                            <QuestionAndAnswer
                                answer={user.emailAddress}
                                dataTestId={'account-email'}
                                question={intl.formatMessage({id: 'account.security.detail.email'})}
                            />

                            <QuestionAndAnswer
                                answer={intl.formatDate(currentUserProfile?.createdAt, {dateStyle: 'long'})}
                                dataTestId={'account-created'}
                                question={intl.formatMessage({id: 'account.security.detail.created'})}
                            />
                        </div>

                        <div className={'profile-section'}>
                            <div className={'is-eyebrow'}>
                                <FormattedMessage id={'account.security.security.title'} />
                            </div>

                            {passwordResetSent && (
                                <Callout>
                                    <FormattedMessage id={'account.security.password.success'} />
                                </Callout>
                            )}

                            <div
                                className={'question-and-answer password'}
                                data-test-id={'account-password'}
                            >
                                <div className={'question is-caption is-heavy'}>
                                    <FormattedMessage id={'account.security.password.title'} />
                                </div>
                                <div className={'answer is-caption'}>
                                    <span>
                                        <FormattedMessage id={'account.security.password.description'} />
                                    </span>
                                    <Button
                                        isLoading={passwordResetLoading}
                                        onClick={onPasswordClickHandler}
                                        title={intl.formatMessage({id: 'account.security.password.button.title'})}
                                    >
                                        <FormattedMessage id={'account.security.password.button.text'} />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </>
                )}
        </section>
    );
}
