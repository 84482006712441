import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    useLocation,
    useNavigate,
} from 'react-router-dom';
import {Loading} from '../../components';

const STEP_NOT_FOUND = -1;

const LinearFlowContext = createContext({});

const checkPrerequisites = async (prerequisites) => {
    // Flag to monitor state of prerequisites...
    let prerequisitesMet = true;

    for await (const satisfied of prerequisites()) {
        if (!satisfied) {
            prerequisitesMet = false;
            break;
        }
    }

    return prerequisitesMet;
};

export function useLinearFlowContext() {
    const context = useContext(LinearFlowContext);

    if (typeof context === 'undefined') {
        throw new Error(`LinearFlowContext must be used within a "LinearFlowProvider"`);
    }

    return context;
}

export function LinearFlowProvider(props) {
    const {
        children,
        containerClassNameSetter,
        flowConfig,
    } = props;

    const {pathname} = useLocation();
    const navigate = useNavigate();
    const [hasProcessedPrerequisites, setHasProcessedPrerequisites] = useState(false);

    const {currentStepIndex, isOptionalQuestion} = useMemo(() => {
        let stepIndex = STEP_NOT_FOUND;
        let isOptionalRoute = false;
        let config = null;

        for (let ii = 0; ii < flowConfig.steps.length; ii++) {
            config = flowConfig.steps[ii];
            if (config?.route === pathname) {
                stepIndex = ii;
                break;
            }
            else {
                config = (config?.optional ?? []).find(
                    ({route: optionalRoute}) => optionalRoute === pathname,
                );
                if (config) {
                    stepIndex = ii;
                    isOptionalRoute = true;
                    break;
                }
            }
        }

        return {
            currentStepIndex: stepIndex,
            isOptionalQuestion: isOptionalRoute,
            questionConfig: config,
        };
    }, [pathname]);

    useEffect(() => {
        setHasProcessedPrerequisites(false);

        const checkStepIsAccessible = async () => {
            if (currentStepIndex !== STEP_NOT_FOUND) {
                // Store the current step index so we can modify it
                let stepIndex = currentStepIndex;
                let optional = isOptionalQuestion;

                let ableToProceed = false;
                while (!ableToProceed) {
                    // Get any prerequisites for the step...
                    const {optional: optionalRoutes = [], prerequisites} = flowConfig.steps[stepIndex];

                    if (optional) {
                        // Are we on one of these optional question...?
                        const {prerequisites: optionalPrerequisites} = optionalRoutes.find(({route}) =>
                            route === pathname
                        ) ?? {};

                        // No match found, so
                        if (!optionalPrerequisites) {
                            break;
                        }

                        // eslint-disable-next-line no-await-in-loop
                        const prerequisitesMet = await checkPrerequisites(optionalPrerequisites);
                        if (prerequisitesMet) {
                            ableToProceed = true;
                        }
                        else {
                            // We're on an optional question, so we now need to check the parent before changing
                            // the step index.
                            optional = false;
                        }
                    }
                    else {
                        if (!prerequisites) {
                            break;
                        }

                        // eslint-disable-next-line no-await-in-loop
                        const prerequisitesMet = await checkPrerequisites(prerequisites);
                        if (prerequisitesMet) {
                            ableToProceed = true;
                        }
                        else {
                            // Go to the previous step...
                            stepIndex -= 1;

                            // Does this previous step have an optional question...?
                            if (flowConfig.steps?.[stepIndex]?.optional) {
                                optional = true;
                            }
                        }
                    }
                }

                // If the mutable step counter is different to the current step index, then prerequisites have not been
                // met, so we need to navigate to a previous step.
                if (stepIndex !== currentStepIndex || optional !== isOptionalQuestion) {
                    const {route} = flowConfig.steps[stepIndex];
                    if (route) {
                        navigate(route, {replace: true});
                    }
                    else {
                        throw new Error(
                            `No route for step ${stepIndex + 1} found after prerequisites checked`,
                        );
                    }
                }
            }
        };

        checkStepIsAccessible().finally(() => setHasProcessedPrerequisites(true));
    }, [currentStepIndex, isOptionalQuestion]);

    const {labelId, totalSteps} = useMemo(
        () => ({
            labelId: flowConfig.labelId,
            totalSteps: flowConfig.steps.length,
        }),
        [flowConfig],
    );

    const nextStep = useCallback(async () => {
        // Check to make sure we're not on the last step, or on a step that wasn't found...
        if (currentStepIndex !== STEP_NOT_FOUND && currentStepIndex !== flowConfig.steps.length - 1) {
            // Are we on an optional question...?
            if (isOptionalQuestion) {
                // Get the route for the next step...
                const {route} = flowConfig.steps[currentStepIndex + 1];
                navigate(route);
            }
            else {
                // Does the current step have an optional question...?
                const {optional} = flowConfig.steps[currentStepIndex];
                if (optional) {
                    // We need to check each optional question to see if any of them need to be displayed
                    const promises = optional.map(({prerequisites = []}) => checkPrerequisites(prerequisites));
                    const results = await Promise.all(promises);
                    const prerequisitesMetIndex = results.findIndex((result) => result);

                    // If any of the optional question prerequisites were met, then navigate to that page,
                    // otherwise go to the next step in the flow.
                    navigate(
                        prerequisitesMetIndex === -1
                            ? flowConfig.steps[currentStepIndex + 1]?.route
                            : optional[prerequisitesMetIndex]?.route,
                    );
                }
                else {
                    // Get the route for the next step...
                    const {route} = flowConfig.steps[currentStepIndex + 1];
                    navigate(route);
                }
            }
        }
    }, [currentStepIndex, isOptionalQuestion]);

    const previousStep = useCallback(async () => {
        // Check to make sure we're not on the first step, or on a step that wasn't found...
        if (currentStepIndex !== STEP_NOT_FOUND && currentStepIndex !== 0) {
            // Are we on an optional question...?
            if (isOptionalQuestion) {
                // Get the route for the parent...
                const {route} = flowConfig.steps[currentStepIndex];
                navigate(route);
            }
            else {
                // Does the previous step have an optional question(s)...?
                const {optional} = flowConfig.steps[currentStepIndex - 1];
                if (optional) {
                    // We need to check each optional question to see if any of them need to be displayed
                    const promises = optional.map(({prerequisites = []}) => checkPrerequisites(prerequisites));
                    const results = await Promise.all(promises);
                    const prerequisitesMetIndex = results.findIndex((result) => result);

                    // If any of the optional question prerequisites were met, then navigate to that page,
                    // otherwise go to the next step in the flow.
                    navigate(
                        prerequisitesMetIndex === -1
                            ? flowConfig.steps[currentStepIndex - 1]?.route
                            : optional[prerequisitesMetIndex]?.route,
                    );
                }
                else {
                    // Get the route for the previous step...
                    const {route} = flowConfig.steps[currentStepIndex - 1];
                    navigate(route);
                }
            }
        }
    }, [currentStepIndex, isOptionalQuestion]);

    const onBackLinkClickHandler = useCallback(() => {
        previousStep();
    }, [previousStep]);

    const context = {
        currentStep: currentStepIndex + 1,
        labelId: labelId,
        nextStep: nextStep,
        onBackLinkClickHandler: onBackLinkClickHandler,
        previousStep: previousStep,
        setContainerClassName: containerClassNameSetter,
        totalSteps: totalSteps,
    };

    if (hasProcessedPrerequisites) {
        return <LinearFlowContext.Provider value={context}>{children}</LinearFlowContext.Provider>;
    }

    return (
        <div className={'flex-column full-height'}>
            <Loading key={'loading'} />
        </div>
    );
}
