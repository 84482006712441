import {
    Card,
    Variant,
} from '@genomics-dev/denim-components';
import {BellCurveVisualisation} from './BellCurveVisualisation';
import {LikelihoodHeadline} from './LikelihoodHeadline';
import {PeopleVisualisation} from './PeopleVisualisation';
import {UnderstandYourResult} from './UnderstandYourResult';

export function RiskLikelihood(props) {
    const {
        firstName,
        highThreshold,
        integratedRiskScore,
        prsPercentile,
        riskLevel,
        riskWindow,
        traitCode,
    } = props;

    return (
        <Card
            className={'risk-likelihood'}
            variant={Variant.LIGHT}
        >
            <section className={'headline'}>
                <LikelihoodHeadline
                    firstName={firstName}
                    highThreshold={highThreshold}
                    integratedRiskScore={integratedRiskScore}
                    riskLevel={riskLevel}
                    riskWindow={riskWindow}
                    traitCode={traitCode}
                />
                <BellCurveVisualisation
                    highThreshold={highThreshold}
                    integratedRiskScore={integratedRiskScore}
                    prsPercentile={prsPercentile}
                    riskLevel={riskLevel}
                />
            </section>
            <section className={'understanding section-divider'}>
                <UnderstandYourResult
                    integratedRiskScore={integratedRiskScore}
                    riskLevel={riskLevel}
                    riskWindow={riskWindow}
                />
                <PeopleVisualisation
                    integratedRiskScore={integratedRiskScore}
                    riskLevel={riskLevel}
                />
            </section>
        </Card>
    );
}
