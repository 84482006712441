import {
    Card,
    Variant,
} from '@genomics-dev/denim-components';
import classNames from 'classnames';

export function GridContainer(props) {
    const {
        children,
        containerClassName,
        containerDataTestId,
        gridClassName,
        gridDataTestId,
        role,
        variant = Variant.DARK,
    } = props;

    const containerClassNames = classNames('grid-container', containerClassName);
    const gridClassNames = classNames('grid', gridClassName);

    return (
        <Card
            className={containerClassNames}
            dataTestId={containerDataTestId}
            role={role}
            variant={variant}
        >
            <div
                className={gridClassNames}
                data-test-id={gridDataTestId}
            >
                {children}
            </div>
        </Card>
    );
}
