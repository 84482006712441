import {useEffect} from 'react';
import {Navigate} from 'react-router-dom';
import {
    FetchError,
    Loading,
} from '../../components';
import {useAmplitude} from '../../context';
import {useQuery} from '../../hooks';
import {Routes} from '../../Routes';
import {
    Endpoint,
    extractErrorMessage,
    NetworkPolicy,
} from '../../util';

// This component exists solely to refresh the summary response that's in the cache. Normally this would be done
// by the response from an existing API endpoint, but having the checkout status endpoint return the summary response
// is not somewhere we want to go. So we do it here, then the navigation to the home route below should actually work,
// as it'll get through all the protection logic; which generally use the summary response to determine what to do.
export function PaymentComplete() {
    const amplitude = useAmplitude();
    const {data, error} = useQuery(Endpoint.SUMMARY, {networkPolicy: NetworkPolicy.NETWORK_ONLY});

    useEffect(() => {
        // Log that payment is complete…
        amplitude.logPaymentConfirmed();
    }, []);

    if (data) {
        return <Navigate to={Routes.HOME} />;
    }

    if (error) {
        return (
            <div
                className={'api-error'}
                key={'error'}
            >
                <FetchError reason={extractErrorMessage(error)} />
            </div>
        );
    }

    return (
        <div
            className={'loading-container'}
            data-test-id={'loading-indicator'}
            key={'loading'}
        >
            <Loading />
        </div>
    );
}
