import {useEffect} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {ContactSupport} from '../../../components';
import {useAmplitude} from '../../../context';
import {
    useEligibility,
    useTitle,
} from '../../../hooks';

export function CheckEligibility() {
    const amplitude = useAmplitude();
    const {getEligibility} = useEligibility();
    const intl = useIntl();
    useTitle(intl.formatMessage({id: `signUp.eligibility.documentTitle`}));

    useEffect(() => {
        amplitude.logEligibilityVisit();
    }, [amplitude]);

    const listOfStates = getEligibility()?.eligibleRegions ?? [];
    const statesCriteriaText = listOfStates.length === 1
        ? (
            <p data-test-id={'state-singular'}>
                <FormattedMessage
                    id="signUp.eligibility.criteria.paragraph.two.singular"
                    values={{state: intl.formatMessage({id: `common.state.${listOfStates[0]}`})}}
                />
            </p>
        )
        : (
            <>
                <p data-test-id={'state-plural'}>
                    <FormattedMessage id={'signUp.eligibility.criteria.paragraph.two'} />
                </p>
                <ul
                    className={'no-item-margin list-of-states'}
                    data-test-id={'list-of-states'}
                >
                    {getEligibility()
                        ?.eligibleRegions.map((stateCode) => ({
                            stateCode: stateCode,
                            stateName: intl.formatMessage({id: `common.state.${stateCode}`}),
                        }))
                        .sort((
                            {stateName: leftStateName},
                            {stateName: rightStateName},
                        ) => (leftStateName < rightStateName ? -1 : leftStateName > rightStateName ? 1 : 0))
                        .map(({stateCode, stateName}) => (
                            <li
                                data-test-id={'state'}
                                key={stateCode}
                            >
                                {stateName}
                            </li>
                        ))}
                </ul>
            </>
        );

    return (
        <>
            <h2 data-test-id={'sign-up-eligibility-title'}>
                <FormattedMessage id={'signUp.eligibility.title'} />
            </h2>
            <p>
                <FormattedMessage id={'signUp.eligibility.criteria.paragraph.one'} />
            </p>
            {statesCriteriaText}
            <p>
                <FormattedMessage
                    id={'signUp.eligibility.criteria.paragraph.three'}
                    values={{cs: (chunk) => <ContactSupport text={chunk} />}}
                />
            </p>
        </>
    );
}
