import {
    Link,
    MoreInfo,
} from '@genomics-dev/denim-components';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {Link as InternalLink} from 'react-router-dom';
import {
    DoctorsMessage,
    ReportDownloadButton,
} from '../../../../components';
import {Routes} from '../../../../Routes';
import {TalkToDoctorOption} from './TalkToDoctorOption';

export function TalkToDoctor() {
    const intl = useIntl();

    return (
        <section className={'talk-to-doctor section-divider layout two-thirds'}>
            <div className={'sidebar'}>
                <h2>
                    <FormattedMessage id={'reports.trait.talkToDoctor.title'} />
                </h2>
                <div
                    className={'count is-caption is-heavy'}
                    data-test-id={'talk-to-doctor-step-count'}
                >
                    <FormattedMessage id={'reports.trait.talkToDoctor.options'} />
                </div>
            </div>
            <div className={'content talk-to-doctor-content'}>
                <TalkToDoctorOption
                    currentStep={1}
                    title={intl.formatMessage({id: 'reports.trait.talkToDoctor.shareResults.title'})}
                    totalSteps={2}
                >
                    <>
                        <p className={'no-margin'}>
                            <FormattedMessage
                                id={'reports.trait.talkToDoctor.shareResults.description'}
                            />
                        </p>
                        <DoctorsMessage />
                        <ReportDownloadButton dataTestId={`download-button`}>
                            <FormattedMessage id={'common.button.downloadReport'} />
                        </ReportDownloadButton>
                    </>
                </TalkToDoctorOption>
                <TalkToDoctorOption
                    currentStep={2}
                    title={intl.formatMessage({id: 'reports.trait.talkToDoctor.bookConsultation.title'})}
                    totalSteps={2}
                >
                    <>
                        <p className={'no-margin'}>
                            <FormattedMessage
                                id={'reports.trait.talkToDoctor.bookConsultation.description'}
                            />
                        </p>
                        <ul className={'no-margin'}>
                            <li>
                                <FormattedMessage
                                    id={'reports.trait.talkToDoctor.bookConsultation.item.one'}
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id={'reports.trait.talkToDoctor.bookConsultation.item.two'}
                                />
                            </li>
                            <li className={'no-bottom-margin'}>
                                <FormattedMessage
                                    id={'reports.trait.talkToDoctor.bookConsultation.item.three'}
                                />
                            </li>
                        </ul>
                        <MoreInfo
                            content={
                                <p>
                                    <FormattedMessage
                                        id={'reports.trait.talkToDoctor.bookConsultation.moreInfo.one.description'}
                                        values={{
                                            link: (chunks) => (
                                                <Link
                                                    className={'link  alternate'}
                                                    dataTestId={'about-steady-md-link'}
                                                    rel={'noreferrer'}
                                                    target={'_blank'}
                                                    url={'https://www.steadymd.com/'}
                                                >
                                                    {chunks}
                                                </Link>
                                            ),
                                        }}
                                    />
                                </p>
                            }
                            dataTestId={'more-info-steady-md'}
                            title={intl.formatMessage({
                                id: 'reports.trait.talkToDoctor.bookConsultation.moreInfo.one.title',
                            })}
                        />
                        <MoreInfo
                            content={
                                <p>
                                    <FormattedMessage
                                        id={'reports.trait.talkToDoctor.bookConsultation.moreInfo.two.description'}
                                        values={{
                                            link: (chunks) => (
                                                <InternalLink
                                                    className={'link alternate'}
                                                    data-test-id={'steady-md-privacy-button'}
                                                    to={Routes.PRIVACY_POLICY}
                                                >
                                                    {chunks}
                                                </InternalLink>
                                            ),
                                        }}
                                    />
                                </p>
                            }
                            dataTestId={'more-info-consultation'}
                            title={intl.formatMessage({
                                id: 'reports.trait.talkToDoctor.bookConsultation.moreInfo.two.title',
                            })}
                        />
                        <Link
                            className={'button icon after launch'}
                            dataTestId={'consultation-button'}
                            rel={'noreferrer'}
                            target={'_blank'}
                            url={Routes.HELP_CENTER}
                        >
                            <FormattedMessage
                                id={'reports.trait.talkToDoctor.bookConsultation.learnConsultation.button.text'}
                            />
                        </Link>
                    </>
                </TalkToDoctorOption>
                <div className={'is-caption disclaimer'}>
                    <FormattedMessage id={'reports.trait.lifestyleActions.disclaimer'} />
                </div>
            </div>
        </section>
    );
}
