import {
    Card,
    Variant,
} from '@genomics-dev/denim-components';
import {FormattedMessage} from 'react-intl';
import {Link as InternalLink} from 'react-router-dom';
import {Checkmark} from '../../components';
import {Routes} from '../../Routes';

export function DeidentifiedDataContent() {
    return (
        <>
            <ul className={'no-decoration'}>
                <li className={'with-checkmark is-heavy no-top-margin'}>
                    <Checkmark />
                    <FormattedMessage id={'trust-and-privacy.de-identified-data.one'} />
                </li>
                <li className={'with-checkmark is-heavy'}>
                    <Checkmark />
                    <FormattedMessage id={'trust-and-privacy.de-identified-data.two'} />
                </li>
            </ul>
            <Card
                className={'supplemental'}
                variant={Variant.DARK}
            >
                <p className={'no-margin'}>
                    <FormattedMessage
                        id={'trust-and-privacy.de-identified-data.supplemental'}
                        values={{
                            link: (chunks) => (
                                <InternalLink
                                    className={'link alternate'}
                                    to={Routes.PRIVACY_POLICY}
                                >
                                    <span>{chunks}</span>
                                </InternalLink>
                            ),
                        }}
                    />
                </p>
            </Card>
        </>
    );
}
