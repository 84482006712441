export class IhrConfig {
    constructor(config) {
        this.config = config ?? {};
    }

    static async init() {
        try {
            const applicationConfig = await (await fetch('/config.json')).json();

            return new IhrConfig(applicationConfig);
        }
        catch (error) {
            // eslint-disable-next-line no-console
            console.error(`Unable to load application config - ${error}`);
        }

        throw new Error('Unable to load the application configuration.');
    }

    get auth0() {
        const {auth0} = this.config;
        return auth0;
    }

    get auth0ClientId() {
        const {auth0: {clientId} = {}} = this.config;
        return clientId;
    }

    get auth0Domain() {
        const {auth0: {domain} = {}} = this.config;
        return domain;
    }

    get backend() {
        const {services: {backend} = {}} = this.config;
        return backend;
    }

    get datadog() {
        const {services: {datadog} = {}} = this.config;
        return datadog;
    }

    get datadogApplicationId() {
        const {services: {datadog: {applicationId} = {}} = {}} = this.config;
        return applicationId;
    }

    get datadogClientToken() {
        const {services: {datadog: {clientToken} = {}} = {}} = this.config;
        return clientToken;
    }

    get datadogEnv() {
        const {services: {datadog: {env} = {}} = {}} = this.config;
        return env;
    }

    get datadogService() {
        const {services: {datadog: {service} = {}} = {}} = this.config;
        return service;
    }

    get datadogSite() {
        const {services: {datadog: {site} = {}} = {}} = this.config;
        return site;
    }

    get amplitude() {
        const {amplitude} = this.config;
        return amplitude;
    }

    get amplitudeApiKey() {
        const {amplitude: {apiKey} = {}} = this.config;
        return apiKey;
    }

    get stripe() {
        const {services: {stripe} = {}} = this.config;
        return stripe;
    }

    get stripePublicKey() {
        const {services: {stripe: {publicKey} = {}} = {}} = this.config;
        return publicKey;
    }
}
