import {
    Button,
    Variant,
} from '@genomics-dev/denim-components';
import {
    useCallback,
    useEffect,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {useNavigate} from 'react-router-dom';
import {
    BackLink,
    FetchError,
    FetchValidationError,
    Loading,
    Test,
} from '../../../components';
import {useAmplitude} from '../../../context';
import {
    useMutation,
    useTraitEligibility,
} from '../../../hooks';
import {useLinearFlowContext} from '../../../layouts';
import {Routes} from '../../../Routes';
import {
    Endpoint,
    extractErrorMessage,
} from '../../../util';

export function YourReports() {
    const amplitude = useAmplitude();
    const intl = useIntl();
    const {previousStep} = useLinearFlowContext();
    const [confirmTestReorder, {error: confirmError, loading: confirmLoading}] = useMutation(
        Endpoint.REORDER_TEST,
        {cacheKey: Endpoint.SUMMARY},
    );
    const navigate = useNavigate();
    const {
        eligibleTraits,
        error: traitError,
        ineligibleTraits,
        loading: traitLoading,
    } = useTraitEligibility();

    useEffect(() => {
        amplitude.logRequestNewTestYourResultsVisit();
    }, [amplitude]);

    const onBackClickHandler = useCallback(() => {
        previousStep();
    }, [previousStep]);

    const onClickHandler = useCallback(async () => {
        await confirmTestReorder();
        amplitude.logRequestNewTestPlaced();
        navigate(Routes.HOME);
    }, [amplitude, navigate]);

    if (traitLoading) {
        return <Loading />;
    }

    if (traitError) {
        return <FetchError reason={extractErrorMessage(traitError)} />;
    }

    return (
        <>
            <BackLink
                className={'less-margin'}
                onClick={onBackClickHandler}
            />
            <h2 data-test-id={'test-reorder-your-reports-title'}>
                <FormattedMessage id={'testReorder.yourReports.title'} />
            </h2>
            <FetchValidationError errorResponse={confirmError} />
            {Boolean(eligibleTraits?.length) && (
                <div className={'test-block'}>
                    <div className={'is-caption is-heavy'}>
                        <FormattedMessage id={'common.profile.tests.included'} />
                    </div>
                    {eligibleTraits.map(({trait}) => (
                        <Test
                            dataTestId={`included-trait-${trait}`}
                            included={true}
                            key={trait}
                            trait={trait}
                        />
                    ))}
                </div>
            )}
            {Boolean(ineligibleTraits?.length) && (
                <div className={'test-block'}>
                    <div className={'is-caption is-heavy'}>
                        <FormattedMessage id={'common.profile.tests.notIncluded'} />
                    </div>
                    {ineligibleTraits.map(({trait}) => (
                        <Test
                            dataTestId={`not-included-trait-${trait}`}
                            included={false}
                            key={trait}
                            trait={trait}
                        />
                    ))}
                </div>
            )}
            <Button
                dataTestId={'test-reorder-order-new-test'}
                isLoading={confirmLoading}
                onClick={onClickHandler}
                title={intl.formatMessage({id: 'testReorder.yourReports.orderNewTest.title'})}
                variant={Variant.PRIMARY}
            >
                <FormattedMessage id={'testReorder.yourReports.orderNewTest.text'} />
            </Button>
        </>
    );
}
