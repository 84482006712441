import {MoreInfo} from '@genomics-dev/denim-components';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';

export function SignUpContent() {
    const intl = useIntl();

    return (
        <>
            <p>
                <FormattedMessage id={'how-it-works.sign-up.description'} />
            </p>
            <MoreInfo
                content={
                    <>
                        <p>
                            <FormattedMessage id={'how-it-works.sign-up.moreInfo.answer.one'} />
                        </p>
                        <p>
                            <FormattedMessage id={'how-it-works.sign-up.moreInfo.answer.two'} />
                        </p>
                        <p>
                            <FormattedMessage id={'how-it-works.sign-up.moreInfo.answer.three'} />
                        </p>
                    </>
                }
                title={intl.formatMessage({id: 'how-it-works.sign-up.moreInfo.question'})}
            />
        </>
    );
}
