import {Button} from '@genomics-dev/denim-components';
import classNames from 'classnames';
import {useCallback} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {LogotypeIcon} from '../../../components';
import {useUserContext} from '../../../context';
import {Routes} from '../../../Routes';
import {
    ActivateKitLink,
    HelpCenterLink,
    NavbarMarketingLinks,
} from '../common';
import {SignUpButton} from '../common/mobile-nav-content/SignUpButton';

export function MobileNavbarMenuContent(props) {
    const {onClose} = props;

    const {isAuthenticated, loginToAuth0} = useUserContext();
    const intl = useIntl();

    const onCloseClickHandler = useCallback(() => {
        if (onClose) {
            onClose();
        }
    }, [onClose]);

    const onLoginClickHandler = useCallback(() => {
        loginToAuth0(Routes.HOME);
    }, [loginToAuth0]);

    const linkClasses = classNames('no-decoration', 'is-heavy');

    return (
        <>
            <div className={'header'}>
                <LogotypeIcon />
                <Button
                    dataTestId={'mobile-nav-close'}
                    onClick={onCloseClickHandler}
                    title={intl.formatMessage({id: 'navbar.closeButton.title'})}
                >
                    <FormattedMessage id={'navbar.closeButton.text'} />
                </Button>
            </div>
            <div className={'content'}>
                {!isAuthenticated && (
                    <>
                        <SignUpButton location={'mobile-navbar-drawer'} />
                        <Button
                            dataTestId={'navigation-login'}
                            onClick={onLoginClickHandler}
                            title={intl.formatMessage({id: 'navbar.loginButton.title'})}
                        >
                            <FormattedMessage id={'navbar.loginButton.text'} />
                        </Button>
                    </>
                )}
                <ul className={'main-menu no-decoration no-margin'}>
                    <NavbarMarketingLinks compact={false} />
                    <li>
                        <ActivateKitLink
                            className={linkClasses}
                            location={'mobile-menu-drawer'}
                        />
                    </li>
                    <li>
                        <HelpCenterLink className={linkClasses} />
                    </li>
                </ul>
            </div>
        </>
    );
}
