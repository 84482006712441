import {Navigate} from 'react-router-dom';
import {
    FetchError,
    Loading,
} from '../../../components';
import {useQuery} from '../../../hooks';
import {Routes} from '../../../Routes';
import {
    Endpoint,
    extractErrorMessage,
} from '../../../util';

export class PaymentState {
    static REQUIRED = 'REQUIRED';

    static SETTLED = 'SETTLED';

    static validate(value) {
        return [this.REQUIRED, this.SETTLED].includes(value);
    }
}

export class Payment {
    static REQUIRED = 'REQUIRED';

    static NOT_REQUIRED = 'NOT_REQUIRED';

    static PAID = 'PAID';
}

export function PaymentGuard(props) {
    const {children, state} = props;

    const {
        data: {payment} = {},
        error,
        loading,
    } = useQuery(Endpoint.SUMMARY);

    if (!PaymentState.validate(state)) {
        throw new Error(`Unknown PaymentState - ${state}`);
    }

    if (error) {
        return (
            <div
                className={'guard'}
                key={'error'}
            >
                <FetchError reason={extractErrorMessage(error)} />
            </div>
        );
    }

    if (loading) {
        return (
            <div
                className={'guard'}
                key={'loading'}
            >
                <Loading key={'loading'} />
            </div>
        );
    }

    // You should only be able to access the requested page if payment is required
    if (state === PaymentState.REQUIRED) {
        if (payment === Payment.REQUIRED) {
            return children;
        }

        // No idea where they might be trying to access from, so just send them home
        return (
            <Navigate
                replace={true}
                to={Routes.HOME}
            />
        );
    }

    // You should only be able to access the requested page if payment wasn't required, or it's already been taken
    if (payment === Payment.REQUIRED) {
        return (
            <Navigate
                replace={true}
                to={Routes.CHECKOUT}
            />
        );
    }

    return children;
}
