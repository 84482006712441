import {
    Button,
    Callout,
    Link,
    Variant,
} from '@genomics-dev/denim-components';
import {useCallback} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {BackLink} from '../../../components';
import {useKitActivationContext} from '../components';

export function KitCodeValidationError(props) {
    const {onNavigate} = props;

    const intl = useIntl();
    const {clearKitCode} = useKitActivationContext();

    const onClickHandler = useCallback(() => {
        // Clear the kit code, then tell the parent we want to navigate back to the code input view.
        clearKitCode();
        onNavigate();
    }, [clearKitCode, onNavigate]);

    return (
        <>
            <BackLink onClick={onClickHandler} />
            <h2 data-test-id={'kit-activation-code-invalid-title'}>
                <FormattedMessage id={'kit.activation.stepTwo.error.title'} />
            </h2>
            <p className={'no-margin'}>
                <FormattedMessage id={'kit.activation.stepTwo.error.description'} />
            </p>
            <Callout>
                <FormattedMessage id={'kit.activation.stepTwo.error.callout'} />
            </Callout>
            <Button
                className={'try-again'}
                dataTestId={'try-again-button'}
                onClick={onClickHandler}
                title={intl.formatMessage({id: 'kit.activation.stepTwo.error.button.title'})}
                variant={Variant.PRIMARY}
            >
                <FormattedMessage id={'kit.activation.stepTwo.error.button.text'} />
            </Button>
            <p className={'no-margin support-link variant-dark'}>
                <FormattedMessage
                    id={'kit.activation.stepTwo.error.support'}
                    values={{
                        support: (chunks) => (
                            <Link
                                className={'alternate'}
                                dataTestId={'contact-support-link'}
                                href={`mailto:${chunks}`}
                            >
                                {chunks}
                            </Link>
                        ),
                    }}
                />
            </p>
        </>
    );
}
