import {useAuth0} from '@auth0/auth0-react';
import {
    ErrorCard,
    FormGroup,
    Variant,
} from '@genomics-dev/denim-components';
import {
    useCallback,
    useReducer,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {useNavigate} from 'react-router-dom';
import {
    register,
    RegisterResult,
} from '../../auth/apiAuthActions';
import {
    AccessCodeInput,
    ContactSupport,
    Divider,
} from '../../components';
import {
    useAmplitude,
    useConfig,
} from '../../context';
import {getEligibility} from '../../hooks';
import {Routes} from '../../Routes';
import {
    action,
    devConsole,
} from '../../util';

const SET_ACCESS_CODE = 'SET_ACCESS_CODE';
const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';

const reducer = (state, {type, payload}) => {
    switch (type) {
        case SET_ACCESS_CODE:
            return {
                ...state,
                accessCode: payload,
            };

        case SET_ERROR_MESSAGE:
            return {
                ...state,
                errorMessage: payload,
            };

        default:
            return state;
    }
};

const init = () => {
    const storedAccessCode = getEligibility()?.inviteCode;

    return {accessCode: storedAccessCode ?? ''};
};

export function AccessCodeReEntry() {
    const intl = useIntl();
    const navigate = useNavigate();
    const [{accessCode, errorMessage}, dispatch] = useReducer(reducer, {}, init);
    const {getIdTokenClaims} = useAuth0();
    const {backend} = useConfig();
    const amplitude = useAmplitude();

    const onAccessCodeErrorHandler = useCallback(() => {
        dispatch(
            action(
                SET_ERROR_MESSAGE,
                intl.formatMessage({id: 'landing.accessCodeReEntry.incorrectAccessCode'}),
            ),
        );
    }, []);

    const onValidAccessCodeHandler = useCallback(
        async (validAccessCode) => {
            const {__raw: idToken} = await getIdTokenClaims();

            devConsole(`AccessCodeReEntry: Starting backend registration`);
            const result = await register(validAccessCode, idToken, backend);
            devConsole(`AccessCodeReEntry: Registration result:`, result);

            if (result !== RegisterResult.Success) {
                devConsole(`AccessCodeReEntry: Registration failed with invalid access code`);
                return;
            }

            devConsole(`AccessCodeReEntry: Registration success`);
            // We should've updated both user and customer IDs at this point, so track it's a new registration
            amplitude.logAccountCreated();
            navigate(Routes.HOME);
        },
        [navigate, getIdTokenClaims, amplitude],
    );

    const onInvalidAccessCodeHandler = useCallback((_invalidAccessCode) => {
        dispatch(
            action(
                SET_ERROR_MESSAGE,
                intl.formatMessage({id: 'landing.accessCodeReEntry.incorrectAccessCode'}),
            ),
        );
    }, []);

    return (
        <div className={'grid fifty-fifty center-content access-code-issue'}>
            <div
                className={'left'}
                data-test-id={'access-code-re-entry'}
            >
                <h1 data-test-id={'access-code-re-entry-title'}>
                    <FormattedMessage id={'landing.accessCodeReEntry.title'} />
                </h1>
                <p>
                    <FormattedMessage id={'landing.accessCodeReEntry.reEnterAccessCode'} />
                </p>
                {errorMessage && (
                    <ErrorCard
                        title={intl.formatMessage({id: 'landing.accessCodeReEntry.error.title'})}
                    >
                        <p>{errorMessage}</p>
                    </ErrorCard>
                )}
                <AccessCodeInput
                    buttonText={intl.formatMessage({id: 'landing.accessCodeReEntry.accessCodeInput.button'})}
                    id={'access-code-input'}
                    label={intl.formatMessage({id: 'landing.accessCodeReEntry.accessCodeInput.label'})}
                    onError={onAccessCodeErrorHandler}
                    onInvalidAccessCode={onInvalidAccessCodeHandler}
                    onValidAccessCode={onValidAccessCodeHandler}
                    value={accessCode}
                    variant={Variant.DARK}
                />
                <Divider />
                <FormGroup label={intl.formatMessage({id: 'landing.accessCodeReEntry.notSureOfCode'})}>
                    <span>
                        <FormattedMessage
                            id={'landing.accessCodeReEntry.help'}
                            values={{cs: (chunk) => <ContactSupport text={chunk} />}}
                        />
                    </span>
                </FormGroup>
            </div>
            <img
                alt={intl.formatMessage({id: 'landing.accessCodeReEntry.image.altText'})}
                className={'right'}
                src={'/images/square-and-circle.svg'}
            />
        </div>
    );
}
