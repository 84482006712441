import {
    Navigate,
    useLocation,
} from 'react-router-dom';
import {useUserContext} from '../../../context';
import {Routes} from '../../../Routes';

export class AuthenticationState {
    static AUTHENTICATED = 'AUTHENTICATED';

    static UNAUTHENTICATED = 'UNAUTHENTICATED';

    static validate(value) {
        return [this.AUTHENTICATED, this.UNAUTHENTICATED].includes(value);
    }
}

export function AuthenticationGuard(props) {
    const {children, state} = props;

    const {pathname} = useLocation();
    const {isAuthenticated, loginToAuth0} = useUserContext();

    if (!AuthenticationState.validate(state)) {
        throw new Error(`Unknown AuthenticationState - ${state}`);
    }

    // When the state needs to be authenticated
    if (state === AuthenticationState.AUTHENTICATED) {
        // If the user is authenticated, then fabulous, display whatever is being protected
        if (isAuthenticated) {
            return children;
        }

        // Sad face, send them to Auth0 to prove their identity
        loginToAuth0(pathname);
        return null;
    }

    // When the state needs to be unauthenticated
    // NOTE: this is a special case for the pre Auth0 pages of the sign-up flow and isn't needed anywhere else
    if (isAuthenticated) {
        return (
            <Navigate
                replace={true}
                to={Routes.LANDING}
            />
        );
    }

    return children;
}
