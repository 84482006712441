import {
    Breakpoint,
    ButtonActuator,
    Navbar,
    useWindowDimensions,
} from '@genomics-dev/denim-components';
import {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {useLocation} from 'react-router-dom';
import {useUserContext} from '../../context';
import {
    DesktopNavbarContent as AuthenticatedDesktopNavbarContent,
    MobileNavbarMenuContent as AuthenticatedMobileMenuContent,
} from './authenticated';
import {MobileNavbarContent} from './common';
import {
    DesktopNavbarContent as UnauthenticatedDesktopNavbarContent,
    MobileNavbarMenuContent as UnauthenticatedMobileMenuContent,
} from './unauthenticated';

const MARKETING_LINKS_BREAKPOINT = 1000;

export function PrimaryNavbar(props) {
    const {
        className,
        homeLink = false,
        marketingRoutes,
        requiresSignUp,
    } = props;

    const {isAuthenticated} = useUserContext();
    const intl = useIntl();
    const {pathname} = useLocation();
    const {width} = useWindowDimensions();

    const {DesktopContent, MobileMenuContent} = useMemo(() => {
        if (isAuthenticated) {
            return {
                DesktopContent: AuthenticatedDesktopNavbarContent,
                MobileMenuContent: AuthenticatedMobileMenuContent,
            };
        }

        // If we're not authenticated then display the marketing links
        return {
            DesktopContent: UnauthenticatedDesktopNavbarContent,
            MobileMenuContent: UnauthenticatedMobileMenuContent,
        };
    }, [isAuthenticated]);

    /* Should return true if the we are to display the desktop navbar, rather than the mobile one. */
    const marketingNavComparator = (breakpoint) => {
        if ([Breakpoint.PHONE, Breakpoint.TABLET_PORTRAIT].includes(breakpoint)) {
            return false;
        }
        else if (Breakpoint.TABLET_LANDSCAPE === breakpoint) {
            // If the width of the window is less than 1000px, then display hte mobil navbar, due to the number of links
            // in it which would cause it to otherwise overflow...
            return width >= MARKETING_LINKS_BREAKPOINT;
        }

        return true;
    };

    return (
        <Navbar
            className={className}
            customComparator={marketingRoutes.includes(pathname) ? marketingNavComparator : null}
            dataTestId={'primary-navbar'}
            desktopContent={
                <DesktopContent
                    displaySignUp={requiresSignUp}
                    homeLink={homeLink}
                    marketingRoutes={marketingRoutes}
                />
            }
            mobileContent={
                <MobileNavbarContent
                    displaySignUp={requiresSignUp}
                    homeLink={homeLink}
                    marketingRoutes={marketingRoutes}
                />
            }
            mobileMenuActuator={
                <ButtonActuator
                    buttonText={intl.formatMessage({id: 'navbar.mobileActuator.text'})}
                    buttonTitle={intl.formatMessage({id: 'navbar.mobileActuator.title'})}
                />
            }
            mobileMenuContent={<MobileMenuContent marketingRoutes={marketingRoutes} />}
            sticky={true}
        />
    );
}
