import {
    Link,
    Variant,
} from '@genomics-dev/denim-components';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {Link as InternalLink} from 'react-router-dom';
import {
    BackLink,
    GridContainer,
} from '../../components';
import {useDefaultBackClickHandler} from '../../hooks';
import {Routes} from '../../Routes';

export function PrivacyPolicy() {
    const intl = useIntl();
    const defaultBackClickHandler = useDefaultBackClickHandler();

    return (
        <section className={'static-content'}>
            <GridContainer containerClassName={'content-section'}>
                <div className={'page-title'}>
                    <BackLink onClick={defaultBackClickHandler} />
                    <h1 data-test-id={'privacy-notice-page-title'}>
                        <FormattedMessage id={'privacyNotice.pageTitle.text'} />
                    </h1>
                </div>
            </GridContainer>
            <GridContainer
                containerClassName={'content-section'}
                variant={Variant.LIGHT}
            >
                <div className={'content-item'}>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.lastUpdated.paragraph.one.text'}
                            values={{strong: (chunks) => <strong>{chunks}</strong>}}
                        />
                    </p>
                    <h1 id={'overview'}>
                        <FormattedMessage id={'privacyNotice.overview.title.text'} />
                    </h1>
                    <p>
                        <FormattedMessage id={'privacyNotice.overview.paragraph.one.text'} />
                    </p>
                    <ul>
                        <li>
                            <FormattedMessage
                                id={'privacyNotice.overview.item.one.text'}
                                values={{strong: (chunks) => <strong>{chunks}</strong>}}
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                id={'privacyNotice.overview.item.two.text'}
                                values={{strong: (chunks) => <strong>{chunks}</strong>}}
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                id={'privacyNotice.overview.item.three.text'}
                                values={{strong: (chunks) => <strong>{chunks}</strong>}}
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                id={'privacyNotice.overview.item.four.text'}
                                values={{strong: (chunks) => <strong>{chunks}</strong>}}
                            />
                        </li>
                    </ul>
                    <p>
                        <FormattedMessage id={'privacyNotice.overview.paragraph.two.text'} />
                    </p>
                </div>
                <div className={'content-item'}>
                    <h1 id={'introduction'}>
                        <FormattedMessage id={'privacyNotice.introduction.title.text'} />
                    </h1>
                    <p>
                        <FormattedMessage id={'privacyNotice.introduction.paragraph.one.text'} />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.introduction.paragraph.two.text'}
                            values={{strong: (chunks) => <strong>{chunks}</strong>}}
                        />
                    </p>
                </div>
                <div className={'content-item'}>
                    <h1 id={'genomics'}>
                        <FormattedMessage id={'privacyNotice.genomics.title.text'} />
                    </h1>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.genomics.paragraph.one.text'}
                            values={{
                                em: (chunks) => <em>{chunks}</em>,
                                strong: (chunks) => <strong>{chunks}</strong>,
                            }}
                        />
                    </p>
                    <p>
                        <FormattedMessage id={'privacyNotice.genomics.paragraph.two.text'} />
                    </p>
                </div>
                <div className={'content-item'}>
                    <h1 id={'health-insights'}>
                        <FormattedMessage id={'privacyNotice.healthInsights.title.text'} />
                    </h1>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.healthInsights.paragraph.one.text'}
                            values={{strong: (chunks) => <strong>{chunks}</strong>}}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.healthInsights.paragraph.two.text'}
                            values={{strong: (chunks) => <strong>{chunks}</strong>}}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.healthInsights.paragraph.three.text'}
                            values={{
                                em: (chunks) => <em>{chunks}</em>,
                                strong: (chunks) => <strong>{chunks}</strong>,
                            }}
                        />
                    </p>
                </div>
                <div className={'content-item'}>
                    <h1 id={'what-data'}>
                        <FormattedMessage id={'privacyNotice.whatData.title.text'} />
                    </h1>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.whatData.paragraph.one.text'}
                            values={{strong: (chunks) => <strong>{chunks}</strong>}}
                        />
                    </p>
                    <h2>
                        <FormattedMessage
                            id={'privacyNotice.whatData.subsection.dataYouProvide.title'}
                            values={{strong: (chunks) => <strong>{chunks}</strong>}}
                        />
                    </h2>
                    <ul>
                        <li>
                            <FormattedMessage
                                id={'privacyNotice.whatData.subsection.dataYouProvide.item.one.text'}
                                values={{strong: (chunks) => <strong>{chunks}</strong>}}
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                id={'privacyNotice.whatData.subsection.dataYouProvide.item.two.text'}
                                values={{strong: (chunks) => <strong>{chunks}</strong>}}
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                id={'privacyNotice.whatData.subsection.dataYouProvide.item.three.text'}
                                values={{strong: (chunks) => <strong>{chunks}</strong>}}
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                id={'privacyNotice.whatData.subsection.dataYouProvide.item.four.text'}
                                values={{strong: (chunks) => <strong>{chunks}</strong>}}
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                id={'privacyNotice.whatData.subsection.dataYouProvide.item.five.text'}
                                values={{strong: (chunks) => <strong>{chunks}</strong>}}
                            />
                        </li>
                    </ul>
                    <h2>
                        <FormattedMessage
                            id={'privacyNotice.whatData.subsection.dataWeProvide.title'}
                        />
                    </h2>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.whatData.subsection.dataWeProvide.paragraph.one.text'}
                        />
                    </p>
                    <ul>
                        <li>
                            <FormattedMessage
                                id={'privacyNotice.whatData.subsection.dataWeProvide.item.one.text'}
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                id={'privacyNotice.whatData.subsection.dataWeProvide.item.two.text'}
                            />
                        </li>
                    </ul>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.whatData.subsection.dataWeProvide.paragraph.two.text'}
                        />
                    </p>
                    <h2>
                        <FormattedMessage id={'privacyNotice.whatData.yourSample.subtitle.text'} />
                    </h2>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.whatData.yourSample.paragraph.one.text'}
                            values={{strong: (chunks) => <strong>{chunks}</strong>}}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.whatData.yourSample.paragraph.two.text'}
                            values={{strong: (chunks) => <strong>{chunks}</strong>}}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.whatData.yourSample.paragraph.three.text'}
                            values={{
                                link: (chunk) => (
                                    <InternalLink
                                        className={'link'}
                                        to={Routes.LANDING}
                                    >
                                        <span>{chunk}</span>
                                    </InternalLink>
                                ),
                                strong: (chunks) => <strong>{chunks}</strong>,
                            }}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.whatData.yourSample.paragraph.four.text'}
                            values={{
                                em: (chunks) => <em>{chunks}</em>,
                                link: (chunks) => (
                                    <Link
                                        rel={'noreferrer'}
                                        target={'_blank'}
                                        url={'https://clinicalenterprise.com/'}
                                    >
                                        {chunks}
                                    </Link>
                                ),
                                strong: (chunks) => <strong>{chunks}</strong>,
                            }}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.whatData.yourSample.paragraph.five.text'}
                            values={{
                                em: (chunks) => <em>{chunks}</em>,
                                link: (chunks) => (
                                    <Link
                                        rel={'noreferrer'}
                                        target={'_blank'}
                                        url={'https://clinicalenterprise.com/'}
                                    >
                                        {chunks}
                                    </Link>
                                ),
                                strong: (chunks) => <strong>{chunks}</strong>,
                            }}
                        />
                    </p>
                    <h2>
                        <FormattedMessage
                            id={'privacyNotice.whatData.yourGeneticData.subtitle.text'}
                        />
                    </h2>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.whatData.yourGeneticData.paragraph.one.text'}
                            values={{strong: (chunks) => <strong>{chunks}</strong>}}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.whatData.yourGeneticData.paragraph.two.text'}
                            values={{
                                link: (chunk) => (
                                    <InternalLink
                                        className={'link'}
                                        to={Routes.LANDING}
                                    >
                                        <span>{chunk}</span>
                                    </InternalLink>
                                ),
                                strong: (chunks) => <strong>{chunks}</strong>,
                            }}
                        />
                    </p>
                    <h2>
                        <FormattedMessage id={'privacyNotice.whatData.yourResults.subtitle.text'} />
                    </h2>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.whatData.yourResults.paragraph.one.text'}
                            values={{strong: (chunks) => <strong>{chunks}</strong>}}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.whatData.yourResults.paragraph.two.text'}
                            values={{strong: (chunks) => <strong>{chunks}</strong>}}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.whatData.yourResults.paragraph.three.text'}
                            values={{
                                em: (chunks) => <em>{chunks}</em>,
                                link: (chunks) => (
                                    <Link
                                        rel={'noreferrer'}
                                        target={'_blank'}
                                        url={'https://www.steadymd.com/'}
                                    >
                                        {chunks}
                                    </Link>
                                ),
                                strong: (chunks) => <strong>{chunks}</strong>,
                            }}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.whatData.yourResults.paragraph.four.text'}
                            values={{
                                em: (chunks) => <em>{chunks}</em>,
                                link: (chunks) => (
                                    <Link
                                        rel={'noreferrer'}
                                        target={'_blank'}
                                        url={'https://www.steadymd.com/'}
                                    >
                                        {chunks}
                                    </Link>
                                ),
                                strong: (chunks) => <strong>{chunks}</strong>,
                            }}
                        />
                    </p>
                    <h2>
                        <FormattedMessage id={'privacyNotice.whatData.otherData.subtitle.text'} />
                    </h2>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.whatData.otherData.paragraph.one.text'}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.whatData.otherData.paragraph.two.text'}
                        />
                    </p>{' '}
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.whatData.otherData.paragraph.three.text'}
                        />
                    </p>{' '}
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.whatData.otherData.paragraph.four.text'}
                        />
                    </p>{' '}
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.whatData.otherData.paragraph.five.text'}
                        />
                    </p>
                    <h2>
                        <FormattedMessage id={'privacyNotice.whatData.cookies.subtitle.text'} />
                    </h2>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.whatData.cookies.paragraph.one.text'}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.whatData.cookies.paragraph.two.text'}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.whatData.cookies.paragraph.three.text'}
                        />
                    </p>
                    <ul>
                        <li>
                            <FormattedMessage
                                id={'privacyNotice.whatData.cookies.item.one.text'}
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                id={'privacyNotice.whatData.cookies.item.two.text'}
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                id={'privacyNotice.whatData.cookies.item.three.text'}
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                id={'privacyNotice.whatData.cookies.item.four.text'}
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                id={'privacyNotice.whatData.cookies.item.five.text'}
                            />
                        </li>
                    </ul>
                    <h4>
                        <FormattedMessage
                            id={'privacyNotice.whatData.cookies.subsection.thirdParty.title'}
                        />
                    </h4>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.whatData.cookies.subsection.thirdParty.paragraph.one.text'}
                        />
                    </p>
                    <h4>
                        <FormattedMessage
                            id={'privacyNotice.whatData.cookies.subsection.turnOff.title'}
                        />
                    </h4>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.whatData.cookies.subsection.turnOff.paragraph.one.text'}
                        />
                    </p>
                    <h2>
                        <FormattedMessage id={'privacyNotice.whatData.otherUses.subtitle.text'} />
                    </h2>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.whatData.otherUses.paragraph.one.text'}
                        />
                    </p>
                    <ul>
                        <li>
                            <FormattedMessage
                                id={'privacyNotice.whatData.otherUses.item.one.text'}
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                id={'privacyNotice.whatData.otherUses.item.two.text'}
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                id={'privacyNotice.whatData.otherUses.item.three.text'}
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                id={'privacyNotice.whatData.otherUses.item.four.text'}
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                id={'privacyNotice.whatData.otherUses.item.five.text'}
                            />
                        </li>
                    </ul>
                </div>
                <div className={'content-item'}>
                    <h1 id={'who-work'}>
                        <FormattedMessage id={'privacyNotice.whoWork.title.text'} />
                    </h1>

                    <h2 id={'our-partners'}>
                        <FormattedMessage id={'privacyNotice.whoWork.ourPartners.subtitle.text'} />
                    </h2>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.whoWork.ourPartners.paragraph.one.text'}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.whoWork.ourPartners.paragraph.two.text'}
                        />
                    </p>
                    <h4 id={'external-links'}>
                        <FormattedMessage id={'privacyNotice.whoWork.ourPartners.externalLinks.text'} />
                    </h4>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.whoWork.ourPartners.externalLinks.paragraph.one.text'}
                        />
                    </p>
                    <h4 id={'approved-study'}>
                        <FormattedMessage id={'privacyNotice.whoWork.ourPartners.section.one.text'} />
                    </h4>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.whoWork.ourPartners.paragraph.six.text'}
                            values={{
                                em: (chunks) => <em>{chunks}</em>,
                                steadyMdLink: (chunks) => (
                                    <Link
                                        rel={'noreferrer'}
                                        target={'_blank'}
                                        url={'https://www.steadymd.com/'}
                                    >
                                        {chunks}
                                    </Link>
                                ),
                                strong: (chunks) => <strong>{chunks}</strong>,
                            }}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.whoWork.ourPartners.paragraph.seven.text'}
                            values={{
                                em: (chunks) => <em>{chunks}</em>,
                                link: (chunk) => (
                                    <InternalLink
                                        className={'link'}
                                        to={Routes.STEADYMD_DATA_POLICY}
                                    >
                                        {chunk}
                                    </InternalLink>
                                ),
                                steadyMdLink: (chunks) => (
                                    <Link
                                        rel={'noreferrer'}
                                        target={'_blank'}
                                        url={'https://www.steadymd.com/'}
                                    >
                                        {chunks}
                                    </Link>
                                ),
                                steadyMdPPLink: (chunk) => (
                                    <Link
                                        rel={'noreferrer'}
                                        target={'_blank'}
                                        url={'https://www.steadymd.com/privacy-policy/'}
                                    >
                                        {chunk}
                                    </Link>
                                ),
                                strong: (chunks) => <strong>{chunks}</strong>,
                            }}
                        />
                    </p>
                    <div className={'card'}>
                        <h4 id={'mass-mutual'}>
                            <FormattedMessage id={'privacyNotice.whoWork.massMutual.subtitle.text'} />
                        </h4>
                        <p>
                            <FormattedMessage
                                id={'privacyNotice.whoWork.massMutual.leading.one'}
                                values={{strong: (chunks) => <strong>{chunks}</strong>}}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id={'privacyNotice.whoWork.massMutual.paragraph.one.text'}
                                values={{strong: (chunks) => <strong>{chunks}</strong>}}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id={'privacyNotice.whoWork.massMutual.paragraph.two.text'}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id={'privacyNotice.whoWork.massMutual.paragraph.three.text'}
                                values={{strong: (chunks) => <strong>{chunks}</strong>}}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id={'privacyNotice.whoWork.massMutual.paragraph.four.text'}
                                values={{strong: (chunks) => <strong>{chunks}</strong>}}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id={'privacyNotice.whoWork.massMutual.paragraph.five.text'}
                                values={{strong: (chunks) => <strong>{chunks}</strong>}}
                            />
                        </p>
                    </div>
                </div>
                <div className={'content-item'}>
                    <h1 id={'other-uses-data'}>
                        <FormattedMessage id={'privacyNotice.otherUsesData.title.text'} />
                    </h1>
                    <h2 id={'other-uses-data'}>
                        <FormattedMessage
                            id={'privacyNotice.otherUsesData.researchAndDevelopment.subtitle.text'}
                        />
                    </h2>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.otherUsesData.researchAndDevelopment.paragraph.one.text'}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.otherUsesData.researchAndDevelopment.paragraph.two.text'}
                            values={{strong: (chunks) => <strong>{chunks}</strong>}}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.otherUsesData.researchAndDevelopment.paragraph.three.text'}
                            values={{strong: (chunks) => <strong>{chunks}</strong>}}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.otherUsesData.researchAndDevelopment.paragraph.four.text'}
                            values={{strong: (chunks) => <strong>{chunks}</strong>}}
                        />
                    </p>
                    <h2 id={'other-uses-data'}>
                        <FormattedMessage
                            id={'privacyNotice.otherUsesData.updatingYou.subtitle.text'}
                        />
                    </h2>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.otherUsesData.updatingYou.paragraph.one.text'}
                            values={{strong: (chunks) => <strong>{chunks}</strong>}}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.otherUsesData.updatingYou.paragraph.two.text'}
                            values={{
                                link: (chunks) => (
                                    <Link
                                        rel={'noreferrer'}
                                        target={'_blank'}
                                        url={intl.formatMessage({id: 'common.support.email'})}
                                    >
                                        {chunks}
                                    </Link>
                                ),
                            }}
                        />
                    </p>
                    <h2 id={'other-uses-data'}>
                        <FormattedMessage
                            id={'privacyNotice.otherUsesData.futureResearch.subtitle.text'}
                        />
                    </h2>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.otherUsesData.futureResearch.paragraph.one.text'}
                            values={{strong: (chunks) => <strong>{chunks}</strong>}}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.otherUsesData.futureResearch.paragraph.two.text'}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.otherUsesData.futureResearch.paragraph.three.text'}
                            values={{
                                link: (chunks) => (
                                    <Link
                                        rel={'noreferrer'}
                                        target={'_blank'}
                                        url={intl.formatMessage({id: 'common.support.email'})}
                                    >
                                        {chunks}
                                    </Link>
                                ),
                            }}
                        />
                    </p>
                </div>
                <div className={'content-item'}>
                    <h1 id={'your-rights'}>
                        <FormattedMessage id={'privacyNotice.yourRights.title.text'} />
                    </h1>
                    <p>
                        <FormattedMessage id={'privacyNotice.yourRights.paragraph.one.text'} />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.yourRights.paragraph.two.text'}
                            values={{
                                link: (chunks) => (
                                    <Link
                                        rel={'noreferrer'}
                                        target={'_blank'}
                                        url={'https://ico.org.uk/for-the-public/'}
                                    >
                                        {chunks}
                                    </Link>
                                ),
                            }}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.yourRights.paragraph.three.text'}
                            values={{
                                link: (chunks) => (
                                    <Link
                                        rel={'noreferrer'}
                                        target={'_blank'}
                                        url={intl.formatMessage({id: 'common.dataRequests.email'})}
                                    >
                                        {chunks}
                                    </Link>
                                ),
                            }}
                        />
                    </p>
                    <h2>
                        <FormattedMessage
                            id={'privacyNotice.yourRights.rightToRectify.subtitle.text'}
                        />
                    </h2>
                    <p>
                        <FormattedMessage id={'privacyNotice.yourRights.rightToRectify.paragraph.one.text'} />
                    </p>
                    <h2>
                        <FormattedMessage
                            id={'privacyNotice.yourRights.rightToRestrict.subtitle.text'}
                        />
                    </h2>
                    <p>
                        <FormattedMessage id={'privacyNotice.yourRights.rightToRestrict.paragraph.one.text'} />
                    </p>
                    <h2>
                        <FormattedMessage
                            id={'privacyNotice.yourRights.rightToObject.subtitle.text'}
                        />
                    </h2>
                    <p>
                        <FormattedMessage id={'privacyNotice.yourRights.rightToObject.paragraph.one.text'} />
                    </p>
                    <h2>
                        <FormattedMessage
                            id={'privacyNotice.yourRights.rightToPortability.subtitle.text'}
                        />
                    </h2>
                    <p>
                        <FormattedMessage id={'privacyNotice.yourRights.rightToPortability.paragraph.one.text'} />
                    </p>
                    <h2>
                        <FormattedMessage
                            id={'privacyNotice.yourRights.rightToWithdraw.subtitle.text'}
                        />
                    </h2>
                    <p>
                        <FormattedMessage id={'privacyNotice.yourRights.rightToWithdraw.paragraph.one.text'} />
                    </p>
                    <h2>
                        <FormattedMessage
                            id={'privacyNotice.yourRights.rightToCommunicate.subtitle.text'}
                        />
                    </h2>
                    <p>
                        <FormattedMessage id={'privacyNotice.yourRights.rightToCommunicate.paragraph.one.text'} />
                    </p>
                    <h2>
                        <FormattedMessage
                            id={'privacyNotice.yourRights.rightToStopResearch.subtitle.text'}
                        />
                    </h2>
                    <p>
                        <FormattedMessage id={'privacyNotice.yourRights.rightToStopResearch.paragraph.one.text'} />
                    </p>
                    <h2>
                        <FormattedMessage
                            id={'privacyNotice.yourRights.stopData.subtitle.text'}
                        />
                    </h2>
                    <p>
                        <FormattedMessage id={'privacyNotice.yourRights.stopData.paragraph.one.text'} />
                    </p>
                    <h2>
                        <FormattedMessage
                            id={'privacyNotice.yourRights.rightToErasure.subtitle.text'}
                        />
                    </h2>
                    <p>
                        <FormattedMessage id={'privacyNotice.yourRights.rightToErasure.paragraph.one.text'} />
                    </p>
                    <p>
                        <FormattedMessage id={'privacyNotice.yourRights.rightToErasure.paragraph.two.text'} />
                    </p>
                    <p>
                        <FormattedMessage id={'privacyNotice.yourRights.rightToErasure.paragraph.three.text'} />
                    </p>
                    <p>
                        <FormattedMessage id={'privacyNotice.yourRights.rightToErasure.paragraph.four.text'} />
                    </p>
                    <h2>
                        <FormattedMessage
                            id={'privacyNotice.yourRights.noContact.subtitle.text'}
                        />
                    </h2>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.yourRights.noContact.paragraph.one.text'}
                            values={{
                                link: (chunks) => (
                                    <Link
                                        rel={'noreferrer'}
                                        target={'_blank'}
                                        url={intl.formatMessage({id: 'common.dataRequests.email'})}
                                    >
                                        {chunks}
                                    </Link>
                                ),
                            }}
                        />
                    </p>
                    <h2>
                        <FormattedMessage
                            id={'privacyNotice.yourRights.shareData.subtitle.text'}
                        />
                    </h2>
                    <p>
                        <FormattedMessage id={'privacyNotice.yourRights.shareData.paragraph.one.text'} />
                    </p>
                    <h2>
                        <FormattedMessage
                            id={'privacyNotice.yourRights.listOfSharedData.subtitle.text'}
                        />
                    </h2>
                    <p>
                        <FormattedMessage id={'privacyNotice.yourRights.listOfSharedData.paragraph.one.text'} />
                    </p>
                    <p>
                        <FormattedMessage id={'privacyNotice.yourRights.listOfSharedData.paragraph.two.text'} />
                    </p>
                    <h2>
                        <FormattedMessage
                            id={'privacyNotice.yourRights.chooseSomeone.subtitle.text'}
                        />
                    </h2>
                    <p>
                        <FormattedMessage id={'privacyNotice.yourRights.chooseSomeone.paragraph.one.text'} />
                    </p>
                    <h2>
                        <FormattedMessage
                            id={'privacyNotice.yourRights.fileComplaint.subtitle.text'}
                        />
                    </h2>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.yourRights.fileComplaint.paragraph.one.text'}
                            values={{
                                link: (chunks) => (
                                    <Link
                                        rel={'noreferrer'}
                                        target={'_blank'}
                                        url={intl.formatMessage({id: 'dataProtection.email'})}
                                    >
                                        {chunks}
                                    </Link>
                                ),
                            }}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.yourRights.fileComplaint.paragraph.two.text'}
                            values={{
                                link: (chunks) => (
                                    <Link
                                        rel={'noreferrer'}
                                        target={'_blank'}
                                        url={'https://ico.org.uk/'}
                                    >
                                        {chunks}
                                    </Link>
                                ),
                            }}
                        />
                    </p>
                    <p>
                        <FormattedMessage id={'privacyNotice.yourRights.fileComplaint.paragraph.three.text'} />
                    </p>
                    <p>
                        <FormattedMessage id={'privacyNotice.yourRights.fileComplaint.paragraph.four.text'} />
                    </p>
                </div>
                <div className={'content-item'}>
                    <h1 id={'request-shara-data'}>
                        <FormattedMessage id={'privacyNotice.requestShareData.title.text'} />
                    </h1>
                    <p>
                        <FormattedMessage id={'privacyNotice.requestShareData.paragraph.one.text'} />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.requestShareData.paragraph.two.text'}
                            values={{
                                em: (chunks) => <em>{chunks}</em>,
                                strong: (chunks) => <strong>{chunks}</strong>,
                            }}
                        />
                    </p>
                    <p>
                        <FormattedMessage id={'privacyNotice.requestShareData.paragraph.three.text'} />
                    </p>
                    <div className={'table-wrapper'}>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <p>
                                            <FormattedMessage
                                                id={'privacyNotice.requestShareData.table.row.one.col.one.text'}
                                                values={{strong: (chunks) => <strong>{chunks}</strong>}}
                                            />
                                        </p>
                                    </td>
                                    <td>
                                        <p>
                                            <FormattedMessage
                                                id={'privacyNotice.requestShareData.table.row.one.col.two.text'}
                                            />
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>
                                            <FormattedMessage
                                                id={'privacyNotice.requestShareData.table.row.two.col.one.text'}
                                                values={{strong: (chunks) => <strong>{chunks}</strong>}}
                                            />
                                        </p>
                                    </td>
                                    <td>
                                        <p>
                                            <FormattedMessage
                                                id={'privacyNotice.requestShareData.table.row.two.col.two.text'}
                                            />
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>
                                            <FormattedMessage
                                                id={'privacyNotice.requestShareData.table.row.three.col.one.text'}
                                                values={{strong: (chunks) => <strong>{chunks}</strong>}}
                                            />
                                        </p>
                                    </td>
                                    <td>
                                        <p>
                                            <FormattedMessage
                                                id={'privacyNotice.requestShareData.table.row.three.col.two.text'}
                                            />
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>
                                            <FormattedMessage
                                                id={'privacyNotice.requestShareData.table.row.four.col.one.text'}
                                                values={{strong: (chunks) => <strong>{chunks}</strong>}}
                                            />
                                        </p>
                                    </td>
                                    <td>
                                        <p>
                                            <FormattedMessage
                                                id={'privacyNotice.requestShareData.table.row.four.col.two.text'}
                                            />
                                        </p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <p>
                        <FormattedMessage id={'privacyNotice.requestShareData.paragraph.four.text'} />
                    </p>
                    <ul>
                        <li>
                            <FormattedMessage id={'privacyNotice.requestShareData.item.one.text'} />
                        </li>
                        <li>
                            <FormattedMessage id={'privacyNotice.requestShareData.item.two.text'} />
                        </li>
                        <li>
                            <FormattedMessage id={'privacyNotice.requestShareData.item.three.text'} />
                        </li>
                    </ul>
                    <p>
                        <FormattedMessage id={'privacyNotice.requestShareData.paragraph.five.text'} />
                    </p>
                    <p>
                        <FormattedMessage id={'privacyNotice.requestShareData.paragraph.six.text'} />
                    </p>
                    <p>
                        <FormattedMessage id={'privacyNotice.requestShareData.paragraph.seven.text'} />
                    </p>
                    <h1>
                        <FormattedMessage
                            id={'privacyNotice.privacyLaws.title.text'}
                        />
                    </h1>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.privacyLaws.paragraph.one.text'}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.privacyLaws.paragraph.two.text'}
                            values={{
                                em: (chunks) => <em>{chunks}</em>,
                                strong: (chunks) => <strong>{chunks}</strong>,
                            }}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.privacyLaws.paragraph.three.text'}
                            values={{em: (chunks) => <em>{chunks}</em>}}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.privacyLaws.paragraph.four.text'}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.privacyLaws.paragraph.five.text'}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.privacyLaws.paragraph.six.text'}
                        />
                    </p>
                    <h2>
                        <FormattedMessage
                            id={'privacyNotice.privacyLaws.consent.subtitle.text'}
                        />
                    </h2>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.privacyLaws.consent.paragraph.one.text'}
                            values={{
                                em: (chunks) => <em>{chunks}</em>,
                                strong: (chunks) => <strong>{chunks}</strong>,
                            }}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.privacyLaws.consent.paragraph.two.text'}
                            values={{
                                em: (chunks) => <em>{chunks}</em>,
                                strong: (chunks) => <strong>{chunks}</strong>,
                            }}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.privacyLaws.consent.paragraph.three.text'}
                            values={{
                                link: (chunks) => (
                                    <Link
                                        rel={'noreferrer'}
                                        target={'_blank'}
                                        url={intl.formatMessage({id: 'common.dataRequests.email'})}
                                    >
                                        {chunks}
                                    </Link>
                                ),
                            }}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.privacyLaws.consent.paragraph.four.text'}
                            values={{em: (chunks) => <em>{chunks}</em>}}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.privacyLaws.consent.paragraph.five.text'}
                            values={{strong: (chunks) => <strong>{chunks}</strong>}}
                        />
                    </p>
                    <h2>
                        <FormattedMessage
                            id={'privacyNotice.privacyLaws.legalRequirements.subtitle.text'}
                        />
                    </h2>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.privacyLaws.legalRequirements.paragraph.one.text'}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.privacyLaws.legalRequirements.paragraph.two.text'}
                        />
                    </p>
                    <h2>
                        <FormattedMessage
                            id={'privacyNotice.privacyLaws.legitimateInterests.subtitle.text'}
                        />
                    </h2>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.privacyLaws.legitimateInterests.paragraph.one.text'}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.privacyLaws.legitimateInterests.paragraph.two.text'}
                        />
                    </p>
                    <h2>
                        <FormattedMessage
                            id={'privacyNotice.privacyLaws.forMassMutual.subtitle.text'}
                        />
                    </h2>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.privacyLaws.forMassMutual.paragraph.one.text'}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.privacyLaws.forMassMutual.paragraph.two.text'}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.privacyLaws.forMassMutual.paragraph.three.text'}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.privacyLaws.forMassMutual.paragraph.four.text'}
                        />
                    </p>
                    <h1>
                        <FormattedMessage
                            id={'privacyNotice.oregon.title.text'}
                        />
                    </h1>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.oregon.paragraph.one.text'}
                            values={{
                                link: (chunks) => (
                                    <Link
                                        rel={'noreferrer'}
                                        target={'_blank'}
                                        url={'https://oregon.public.law/statutes/ors_192.537'}
                                    >
                                        {chunks}
                                    </Link>
                                ),
                            }}
                        />
                    </p>
                    <h1>
                        <FormattedMessage
                            id={'privacyNotice.ourResponsibilities.title.text'}
                        />
                    </h1>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.ourResponsibilities.paragraph.one.text'}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.ourResponsibilities.section.one.title.text'}
                            values={{strong: (chunks) => <strong>{chunks}</strong>}}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.ourResponsibilities.section.one.paragraph.one.text'}
                            values={{strong: (chunks) => <strong>{chunks}</strong>}}
                        />
                    </p>
                    <ul>
                        <li>
                            <FormattedMessage
                                id={'privacyNotice.ourResponsibilities.section.one.item.one.text'}
                                values={{strong: (chunks) => <strong>{chunks}</strong>}}
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                id={'privacyNotice.ourResponsibilities.section.one.item.two.text'}
                                values={{strong: (chunks) => <strong>{chunks}</strong>}}
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                id={'privacyNotice.ourResponsibilities.section.one.item.three.text'}
                                values={{strong: (chunks) => <strong>{chunks}</strong>}}
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                id={'privacyNotice.ourResponsibilities.section.one.item.four.text'}
                                values={{strong: (chunks) => <strong>{chunks}</strong>}}
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                id={'privacyNotice.ourResponsibilities.section.one.item.five.text'}
                                values={{strong: (chunks) => <strong>{chunks}</strong>}}
                            />
                            <ul>
                                <li>
                                    <FormattedMessage
                                        id={'privacyNotice.ourResponsibilities.section.one.item.five.subItem.one.text'}
                                        values={{strong: (chunks) => <strong>{chunks}</strong>}}
                                    />
                                </li>
                                <li>
                                    <FormattedMessage
                                        id={'privacyNotice.ourResponsibilities.section.one.item.five.subItem.two.text'}
                                        values={{strong: (chunks) => <strong>{chunks}</strong>}}
                                    />
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.ourResponsibilities.section.two.title.text'}
                            values={{strong: (chunks) => <strong>{chunks}</strong>}}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.ourResponsibilities.section.two.paragraph.one.text'}
                            values={{strong: (chunks) => <strong>{chunks}</strong>}}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.ourResponsibilities.section.three.title.text'}
                            values={{strong: (chunks) => <strong>{chunks}</strong>}}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.ourResponsibilities.section.three.paragraph.one.text'}
                            values={{
                                link: (chunks) => (
                                    <Link
                                        rel={'noreferrer'}
                                        target={'_blank'}
                                        url={intl.formatMessage({id: 'dataProtection.email'})}
                                    >
                                        {chunks}
                                    </Link>
                                ),
                            }}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.ourResponsibilities.section.four.title.text'}
                            values={{strong: (chunks) => <strong>{chunks}</strong>}}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'privacyNotice.ourResponsibilities.section.four.paragraph.one.text'}
                        />
                    </p>
                </div>
            </GridContainer>
        </section>
    );
}
