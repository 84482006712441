import {useAuth0} from '@auth0/auth0-react';
import React, {
    useCallback,
    useEffect,
} from 'react';
import {useNavigate} from 'react-router-dom';
import {
    ErrorNotice,
    Loading,
} from '../components';
import {
    Auth0CallbackActions,
    useAmplitude,
    useConfig,
} from '../context';
import {useEligibility} from '../hooks';
import {ErrorLayout} from '../layouts';
import {Routes} from '../Routes';
import {devConsole} from '../util';
import {
    login,
    LoginResult,
    register,
    RegisterResult,
} from './apiAuthActions';

// Child component that processes the Auth0 callback and redirects to the appropriate page.
export const Auth0CallbackProcessor = ({appState}) => {
    const {error, getIdTokenClaims, isLoading, logout} = useAuth0();
    const navigate = useNavigate();
    const {getEligibility, removeEligibility} = useEligibility();
    const amplitude = useAmplitude();
    const {backend} = useConfig();

    const logoutAuth0WithError = useCallback(
        async () => {
            return logout({
                logoutParams: {
                    returnTo: `${window.location.origin}${Routes.CONTACT_SUPPORT}`,
                },
            });
        },
        [logout],
    );

    const registerCallback = useCallback(
        async () => {
            const {__raw: idToken} = await getIdTokenClaims();

            devConsole(`Auth0CallbackProcessor: Starting backend registration`);
            try {
                const result = await register(getEligibility()?.inviteCode, idToken, backend);
                devConsole(`Auth0CallbackProcessor: Registration result:`, result);

                // Clean up our access code, whatever the outcome
                removeEligibility();

                if (result === RegisterResult.Success) {
                    devConsole(`Auth0CallbackProcessor: Registration success`);
                    // We should've updated both user and customer IDs at this point, so track it's a new registration
                    amplitude.logAccountCreated();
                    navigate(Routes.HOME);
                    return;
                }
                else if (result === RegisterResult.InvalidAccessCode) {
                    devConsole(`Auth0CallbackProcessor: Registration failed with invalid access code`);
                    // We need to register user again, but stay logged-in to allow signup
                    navigate(Routes.RE_ENTER_ACCESS_CODE);
                    return;
                }

                // Some error we can't handle. User still technically logged into Auth0 at this point, but this is ok, because
                // they are not authenticated in the backend and the app will treat them as logged out until they try another
                // Auth0 login.
                devConsole(
                    `Auth0CallbackProcessor: Registration returned unknown error. Logging out and redirecting to support page.`,
                );
                await logoutAuth0WithError();
            }
            catch (e) {
                devConsole(
                    `Auth0CallbackProcessor: Registration threw unknown error. Logging out and redirecting to support page.`,
                );
                await logoutAuth0WithError();
            }
        },
        [getEligibility, navigate, getIdTokenClaims, removeEligibility, amplitude, logoutAuth0WithError],
    );

    const loginCallback = useCallback(
        async (appState) => {
            const {__raw: idToken} = await getIdTokenClaims();

            devConsole(`Auth0CallbackProcessor: Starting backend login`);
            try {
                const result = await login(idToken, backend);
                devConsole(`Auth0CallbackProcessor: Login result:`, result);

                if (result === LoginResult.Success) {
                    // If there's a redirectTo in the appState, the user was trying to access a protected page, so send them
                    // there, otherwise, send them to their home
                    navigate(appState?.returnTo ? appState.returnTo : Routes.HOME);
                    return;
                }
                else if (result === LoginResult.AccountDoesNotExist) {
                    // User did an auth0 login (maybe social auth?), but does not have an account in the backend. We need to
                    // log them out of Auth0 and send them to the access code page to ask them to register again.
                    navigate(Routes.RE_ENTER_ACCESS_CODE);
                    return;
                }

                // Some error we can't handle. User still technically logged into Auth0 at this point, but this is ok, because
                // they are not authenticated in the backend and the app will treat them as logged out until they try another
                // Auth0 login.
                await logoutAuth0WithError();
            }
            catch (e) {
                await logoutAuth0WithError();
            }
        },
        [navigate, getIdTokenClaims],
    );

    useEffect(
        () => {
            if (!appState || isLoading) {
                // We are not ready to process the callback yet
                return;
            }

            devConsole(`Auth0CallbackProcessor: Got an appState:`, appState);
            if (appState.action === Auth0CallbackActions.Register) {
                registerCallback();
            }
            else {
                // Assume anything else has to be a regular login?
                loginCallback(appState);
            }
        },
        [registerCallback, loginCallback, appState, isLoading],
    );

    // Show an error if it is returned by Auth0
    if (error) {
        return (
            <ErrorLayout mainClassName={'error-container'}>
                <ErrorNotice
                    errorCode={error}
                />
            </ErrorLayout>
        );
    }

    // Always show a loading screen, as the navigate calls will break us out of this render
    return (
        <div className={'flex-column full-height'}>
            <Loading key={'loading'} />
        </div>
    );
};
