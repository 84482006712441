import {Navigate} from 'react-router-dom';
import {
    FetchError,
    Loading,
} from '../../../components';
import {useQuery} from '../../../hooks';
import {Routes} from '../../../Routes';
import {
    Endpoint,
    extractErrorMessage,
} from '../../../util';

export class PhysicianReviewStatus {
    static NONE = 'NONE';

    static PENDING = 'PENDING';

    static APPROVED = 'APPROVED';

    static REJECTED = 'REJECTED';

    static REORDER_INVITE_SENT = 'REORDER_INVITE_SENT';

    static REORDER_PLACED = 'REORDER_PLACED';
}

export class KitOrderStatus {
    static NONE = 'NONE';

    static ORDERED = 'ORDERED';

    static REGISTERED = 'REGISTERED';
}

export function KitActivationGuard(props) {
    const {children} = props;

    const {
        data: {
            kitOrderStatus = KitOrderStatus.NONE,
            physicianReviewStatus = PhysicianReviewStatus.NONE,
        } = {},
        error,
        loading,
    } = useQuery(Endpoint.SUMMARY);

    if (error) {
        return (
            <div
                className={'guard'}
                key={'error'}
            >
                <FetchError reason={extractErrorMessage(error)} />
            </div>
        );
    }

    if (loading) {
        return (
            <div
                className={'guard'}
                key={'loading'}
            >
                <Loading key={'loading'} />
            </div>
        );
    }

    // You should only be able to access the requested page if a kit has been ordered
    if (
        [PhysicianReviewStatus.APPROVED, PhysicianReviewStatus.REORDER_PLACED].includes(physicianReviewStatus)
        && kitOrderStatus === KitOrderStatus.ORDERED
    ) {
        return children;
    }

    // Otherwise just send them back to their home area
    return (
        <Navigate
            replace={true}
            to={Routes.HOME}
        />
    );
}
