import {
    Button,
    FormGroup,
    Variant,
} from '@genomics-dev/denim-components';
import {useCallback} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {useNavigate} from 'react-router-dom';
import {useName} from '../../hooks';
import {Routes} from '../../Routes';

export function GotoAccountButton(props) {
    const {dataTestId} = props;
    const intl = useIntl();
    const {name} = useName();
    const navigate = useNavigate();
    const onClickHandler = useCallback(() => {
        navigate(Routes.HOME);
    }, []);

    const formGroupLabel = name
        ? intl.formatMessage({id: 'landing.hero.account.label'}, {name})
        : null;

    return (
        <FormGroup
            className={'goto-account-form alternate'}
            label={formGroupLabel}
        >
            <Button
                after={true}
                className={'goto-account-button'}
                dataTestId={dataTestId}
                icon={'arrow--right'}
                onClick={onClickHandler}
                title={intl.formatMessage({id: `landing.hero.account.button.title`})}
                variant={Variant.PRIMARY}
            >
                <FormattedMessage id={'landing.hero.account.button.text'} />
            </Button>
        </FormGroup>
    );
}
