import {Button} from '@genomics-dev/denim-components';
import classNames from 'classnames';
import {
    useCallback,
    useEffect,
    useState,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import Modal from 'react-modal';
import {useLocation} from 'react-router-dom';
import {AccountMenu} from '../account-menu';

// This takes into account the outline offset and thickness of the button
const BUTTON_OFFSET = 4;

export function AccountButton() {
    const intl = useIntl();
    const {pathname} = useLocation();
    const [isAccountDropdownMenuOpen, setIsAccountDropdownMenuOpen] = useState(false);
    const [pathnameOnOpen, setPathnameOnOpen] = useState(null);

    // The modal overlay has to cover the entire viewport to enable it to capture clicks. As the menu is contained
    // within the overlay for some reason, we have to manually position it under the account button.
    const repositionModal = useCallback(() => {
        // Get the details of the account button
        const buttonElement = document.querySelector('.dropdown');
        const buttonLeft = buttonElement.offsetLeft;
        const buttonRight = buttonElement.offsetLeft + buttonElement.offsetWidth;
        const buttonBottom = buttonElement.offsetTop + buttonElement.offsetHeight;

        // Get the account menu which needs positioning relative to it's overly.
        const accountMenu = document.querySelector('.dropdown-menu');
        accountMenu.style.top = `${buttonBottom + BUTTON_OFFSET}px`;

        // We need to make sure that it doesn't go off the right of the viewport. If we're switching to the right, we
        // also need to take into account scrollbar width.
        const accountMenuWidth = accountMenu.offsetWidth;
        if (buttonLeft + accountMenuWidth > window.innerWidth) {
            accountMenu.style.left = '';
            accountMenu.style.right = `${
                window.innerWidth
                - buttonRight
                - (window.innerWidth - window.visualViewport.width)
                - BUTTON_OFFSET
            }px`;
        }
        else {
            accountMenu.style.right = '';
            accountMenu.style.left = `${buttonLeft - BUTTON_OFFSET}px`;
        }
    }, []);

    const windowResized = useCallback(() => {
        if (isAccountDropdownMenuOpen) {
            repositionModal();
        }
    }, [isAccountDropdownMenuOpen]);

    useEffect(() => {
        window.addEventListener('resize', windowResized);

        return () => {
            window.removeEventListener('resize', windowResized);
        };
    }, [windowResized]);

    useEffect(() => {
        // If the pathname changes while the menu is open, then close it...
        if (isAccountDropdownMenuOpen && pathname !== pathnameOnOpen) {
            setIsAccountDropdownMenuOpen(false);
        }
    }, [isAccountDropdownMenuOpen, pathname, pathnameOnOpen]);

    const onClickHandler = useCallback(
        (event) => {
            event.preventDefault();
            setIsAccountDropdownMenuOpen(true);
            setPathnameOnOpen(pathname);
        },
        [pathname],
    );

    const closeAccountDropdownMenu = useCallback(() => {
        setIsAccountDropdownMenuOpen(false);
    }, []);

    const accountMenuClasses = classNames('dropdown-menu', {open: isAccountDropdownMenuOpen});

    return (
        <>
            <Button
                className={'icon after chevron--down dropdown'}
                dataTestId={'navigation-account'}
                onClick={onClickHandler}
                title={intl.formatMessage({id: 'accountButton.title'})}
            >
                <FormattedMessage id={'accountButton.text'} />
            </Button>
            <Modal
                className={'dropdown-menu-modal'}
                isOpen={isAccountDropdownMenuOpen}
                onAfterOpen={repositionModal}
                onRequestClose={closeAccountDropdownMenu}
                overlayClassName={'dropdown-overlay'}
                shouldCloseOnEsc={true}
                shouldFocusAfterRender={true}
            >
                <AccountMenu
                    className={accountMenuClasses}
                    compact={true}
                />
            </Modal>
        </>
    );
}
