import {
    Navigate,
    Outlet,
    Route,
    Routes,
} from 'react-router-dom';
import {
    DateOfBirth,
    Ethnicity,
    EthnicityOptionalQuestionInterceptor,
    HomeAddress,
    IdentifiedEthnicity,
    Name,
    PhoneNumber,
    PreferNotToSayEthnicity,
    PreferNotToSaySex,
    ReportedSex,
    ReportedSexOptionalQuestionInterceptor,
    ShippingAddress,
} from '../../../components';
import {
    LinearFlowLayout,
    MinimalLayout,
} from '../../../layouts';
import {Routes as InternalRoutes} from '../../../Routes';
import {ProtectedRoute} from '../../auth';
import {NotFound} from '../../not-found';
import {AboutYou} from '../about-you';
import {AccountSetup} from '../account-setup';
import {CheckEligibility} from '../check-eligibility';
import {
    EditProfilePage,
    InformationPage,
    ProfilePage,
    useSignUpFlow,
} from '../common';
import {Consent} from '../consent';
import {InviteCode} from '../invite-code';
import {ShippingDetails} from '../shipping-details';
import {Summary} from '../summary';
import {ThingsYouShouldKnow} from '../things-you-should-know';
import {VerifyEmail} from '../verify-email';
import {YourReports} from '../your-reports';

export function SignUpController() {
    const flowConfig = useSignUpFlow();

    return (
        <Routes>
            <Route
                element={
                    <LinearFlowLayout
                        className={'sign-up'}
                        flowConfig={flowConfig}
                        logoutLink={true}
                    />
                }
            >
                <Route
                    element={
                        <Navigate
                            replace={true}
                            to={'invite-code'}
                        />
                    }
                    index={true}
                />
                <Route
                    element={
                        <ProtectedRoute
                            component={Outlet}
                            notAuthenticated={true}
                        />
                    }
                >
                    <Route
                        element={
                            <InformationPage
                                noBackLink={[
                                    InternalRoutes.SIGN_UP_INVITE_CODE,
                                    InternalRoutes.SIGN_UP_ACCOUNT_SETUP,
                                ]}
                                noButton={[
                                    InternalRoutes.SIGN_UP_INVITE_CODE,
                                    InternalRoutes.SIGN_UP_ACCOUNT_SETUP,
                                ]}
                            />
                        }
                    >
                        <Route
                            element={<InviteCode />}
                            path={'invite-code'}
                        />
                        <Route
                            element={<CheckEligibility />}
                            path={'check-eligibility'}
                        />
                        <Route
                            element={<ThingsYouShouldKnow />}
                            path={'things-you-should-know'}
                        />
                        <Route
                            element={<AccountSetup />}
                            path={'account-setup'}
                        />
                    </Route>
                </Route>
                <Route
                    element={
                        <ProtectedRoute
                            component={Outlet}
                            signUpRequired={true}
                        />
                    }
                >
                    <Route
                        element={
                            <InformationPage
                                noBackLink={[
                                    InternalRoutes.SIGN_UP_CONSENT,
                                    InternalRoutes.SIGN_UP_VERIFY_EMAIL,
                                    InternalRoutes.SIGN_UP_ABOUT_YOU,
                                ]}
                                noButton={[
                                    InternalRoutes.SIGN_UP_CONSENT,
                                    InternalRoutes.SIGN_UP_VERIFY_EMAIL,
                                    InternalRoutes.SIGN_UP_YOUR_REPORTS,
                                ]}
                            />
                        }
                    >
                        <Route
                            element={<Consent />}
                            path={'consent'}
                        />
                        <Route
                            element={<VerifyEmail />}
                            path={'verify-email'}
                        />
                        <Route
                            element={<AboutYou />}
                            path={'about-you'}
                        />
                        <Route
                            element={<YourReports />}
                            path={'your-reports'}
                        />
                    </Route>
                    <Route element={<ProfilePage />}>
                        <Route
                            element={<Name />}
                            path={'name'}
                        />
                        <Route
                            element={<DateOfBirth />}
                            path={'date-of-birth'}
                        />
                        <Route
                            element={<ReportedSex />}
                            path={'reported-sex'}
                        />
                        <Route
                            element={<PreferNotToSaySex />}
                            path={'prefer-not-to-say-sex'}
                        />
                        <Route
                            element={<Ethnicity />}
                            path={'ethnicity'}
                        />
                        <Route
                            element={<IdentifiedEthnicity />}
                            path={'identified-ethnicity'}
                        />
                        <Route
                            element={<PreferNotToSayEthnicity />}
                            path={'prefer-not-to-say-ethnicity'}
                        />
                        <Route
                            element={<HomeAddress />}
                            path={'home-address'}
                        />
                        <Route
                            element={<PhoneNumber />}
                            path={'phone-number'}
                        />
                        <Route
                            element={<ShippingDetails />}
                            path={'shipping-details'}
                        />
                        <Route
                            element={<ShippingAddress />}
                            path={'shipping-address'}
                        />
                        <Route
                            element={<Summary />}
                            path={'summary'}
                        />
                    </Route>
                    <Route
                        element={<EditProfilePage />}
                        path={'edit'}
                    >
                        <Route
                            element={<Name />}
                            path={'name'}
                        />
                        <Route
                            element={<DateOfBirth />}
                            path={'date-of-birth'}
                        />
                        <Route
                            element={
                                <ReportedSexOptionalQuestionInterceptor
                                    routes={{
                                        reportedSex: InternalRoutes.SIGN_UP_EDIT_REPORTED_SEX,
                                        pntsSex: InternalRoutes.SIGN_UP_EDIT_PNTS_SEX,
                                    }}
                                />
                            }
                        >
                            <Route
                                element={<ReportedSex />}
                                path={'reported-sex'}
                            />
                            <Route
                                element={<PreferNotToSaySex />}
                                path={'prefer-not-to-say-sex'}
                            />
                        </Route>
                        <Route
                            element={
                                <EthnicityOptionalQuestionInterceptor
                                    routes={{
                                        ethnicity: InternalRoutes.SIGN_UP_EDIT_ETHNICITY,
                                        pntsEthnicity: InternalRoutes.SIGN_UP_EDIT_PNTS_ETHNICITY,
                                        identifiedEthnicity: InternalRoutes.SIGN_UP_EDIT_IDENTIFIED_ETHNICITY,
                                    }}
                                />
                            }
                        >
                            <Route
                                element={<Ethnicity />}
                                path={'ethnicity'}
                            />
                            <Route
                                element={<IdentifiedEthnicity />}
                                path={'identified-ethnicity'}
                            />
                            <Route
                                element={<PreferNotToSayEthnicity />}
                                path={'prefer-not-to-say-ethnicity'}
                            />
                        </Route>
                        <Route
                            element={<HomeAddress />}
                            path={'home-address'}
                        />
                        <Route
                            element={<PhoneNumber />}
                            path={'phone-number'}
                        />
                        <Route
                            element={<ShippingAddress />}
                            path={'shipping-address'}
                        />
                    </Route>
                </Route>
            </Route>
            <Route element={<MinimalLayout />}>
                <Route
                    element={<NotFound />}
                    path={'*'}
                />
            </Route>
        </Routes>
    );
}
