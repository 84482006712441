import {Callout} from '@genomics-dev/denim-components';
import {useEffect} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {useAmplitude} from '../../../context';
import {useTitle} from '../../../hooks';

export function ThingsYouShouldKnow() {
    const amplitude = useAmplitude();
    const intl = useIntl();
    useTitle(intl.formatMessage({id: `signUp.thingsYouShouldKnow.documentTitle`}));

    useEffect(() => {
        amplitude.logThingsYouShouldKnowVisit();
    }, [amplitude]);

    return (
        <>
            <h2 data-test-id={'sign-up-things-you-should-know-title'}>
                <FormattedMessage id={'signUp.thingsYouShouldKnow.title'} />
            </h2>
            <ul>
                <li>
                    <FormattedMessage id={'signUp.thingsYouShouldKnow.one'} />
                </li>
                <li>
                    <FormattedMessage id={'signUp.thingsYouShouldKnow.two'} />
                </li>
                <li>
                    <FormattedMessage id={'signUp.thingsYouShouldKnow.three'} />
                </li>
            </ul>
            <Callout>
                <FormattedMessage id={'signUp.thingsYouShouldKnow.callout'} />
            </Callout>
        </>
    );
}
