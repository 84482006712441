import {
    Callout,
    Link,
    MoreInfo,
} from '@genomics-dev/denim-components';
import {
    useCallback,
    useEffect,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {
    useAmplitude,
    useUserContext,
} from '../../../context';
import {
    ContinueButton,
    Instruction,
} from '../components';

export function UnpackTheContents() {
    const amplitude = useAmplitude();
    const intl = useIntl();
    const {logout} = useUserContext();

    useEffect(() => {
        amplitude.logActivationUnpackVisit();
    }, [amplitude]);

    const onClickHandler = useCallback(() => {
        logout();
    }, [logout]);

    return (
        <>
            <h2 data-test-id={'kit-activation-step-one-title'}>
                <FormattedMessage id={'kit.activation.stepOne.title'} />
            </h2>
            <Instruction
                image={'/images/kit-activation-one-one.svg'}
                text={intl.formatMessage({id: 'kit.activation.stepOne.instruction.one'})}
            />
            <Instruction
                className={'more-margin'}
                image={'/images/kit-activation-one-two.svg'}
                text={intl.formatMessage({id: 'kit.activation.stepOne.instruction.two'})}
            />
            <MoreInfo
                content={intl.formatMessage({id: 'kit.activation.stepOne.oragene.content'})}
                title={intl.formatMessage({id: 'kit.activation.stepOne.oragene.title'})}
            />
            <Callout>
                <FormattedMessage id={'kit.activation.stepOne.callout'} />
            </Callout>
            <ContinueButton />
            <p>
                <FormattedMessage
                    id={'kit.activation.footer'}
                    values={{
                        logout: (chunks) => (
                            <Link
                                className={'logout-link'}
                                dataTestId={'logout-link'}
                                onClick={onClickHandler}
                            >
                                {chunks}
                            </Link>
                        ),
                    }}
                />
            </p>
        </>
    );
}
