import {
    Link,
    MoreInfo,
} from '@genomics-dev/denim-components';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {Link as InternalLink} from 'react-router-dom';
import {Routes} from '../../Routes';

export function FaqContent() {
    const intl = useIntl();

    return (
        <div className={'faq'}>
            <h4 id={'faq'}>
                <FormattedMessage id={'landing.faq.title'} />
            </h4>

            <MoreInfo
                content={intl.formatMessage(
                    {id: 'landing.faq.geneticData.content'},
                    {
                        pn: (chunk) => (
                            <InternalLink
                                className={'link alternate'}
                                to={Routes.PRIVACY_POLICY}
                            >
                                <span>{chunk}</span>
                            </InternalLink>
                        ),
                    },
                )}
                dataTestId={'question-genetic-data'}
                title={intl.formatMessage({id: 'landing.faq.geneticData.title'})}
            />
            <MoreInfo
                content={intl.formatMessage({id: 'landing.faq.howLong.content'})}
                dataTestId={'question-how-long'}
                title={intl.formatMessage({id: 'landing.faq.howLong.title'})}
            />
            <MoreInfo
                content={intl.formatMessage(
                    {id: 'landing.faq.oversight.content'},
                    {
                        pn: (chunk) => (
                            <InternalLink
                                className={'link alternate'}
                                to={Routes.STEADYMD_DATA_POLICY}
                            >
                                <span>{chunk}</span>
                            </InternalLink>
                        ),
                    },
                )}
                dataTestId={'question-oversight'}
                title={intl.formatMessage({id: 'landing.faq.oversight.title'})}
            />
            <MoreInfo
                content={
                    <>
                        <p>
                            <FormattedMessage id={'landing.faq.difference.contentOne'} />
                        </p>
                        <p>
                            <FormattedMessage id={'landing.faq.difference.contentTwo'} />
                        </p>
                        <p>
                            <FormattedMessage id={'landing.faq.difference.contentThree'} />
                        </p>
                    </>
                }
                dataTestId={'question-difference'}
                title={intl.formatMessage({id: 'landing.faq.difference.title'})}
            />
            <Link
                className={'button'}
                dataTestId={'questions-button'}
                rel={'noreferrer'}
                target={'_blank'}
                title={intl.formatMessage({id: 'landing.faq.button.title'})}
                url={Routes.HELP_CENTER}
            >
                <FormattedMessage id={'landing.faq.button.text'} />
            </Link>
        </div>
    );
}
