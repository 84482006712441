import {
    Button,
    Variant,
} from '@genomics-dev/denim-components';
import {useCallback} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {useLinearFlowContext} from '../../../../layouts';

export function ContinueButton(props) {
    const {loading = false, submit = false} = props;

    const intl = useIntl();
    const {nextStep} = useLinearFlowContext();

    const onClickHandler = useCallback(() => {
        nextStep();
    }, [nextStep]);

    return (
        <Button
            after={true}
            dataTestId={'continue-button'}
            icon={'arrow--right'}
            isLoading={loading}
            onClick={submit ? null : onClickHandler}
            submit={submit}
            title={intl.formatMessage({id: 'kit.activation.button.continue.title'})}
            variant={Variant.PRIMARY}
        >
            <FormattedMessage id={'kit.activation.button.continue.text'} />
        </Button>
    );
}
