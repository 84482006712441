import {
    FormattedMessage,
    useIntl,
} from 'react-intl';

export function BulletPointsContent() {
    const intl = useIntl();

    return (
        <>
            <div className={'content'}>
                <div className={'point first'}>
                    <img
                        alt={intl.formatMessage({id: 'landing.bullets.locked.alt'})}
                        role={'presentation'}
                        src={'/images/icon-locked_vector_v1_1KB.svg'}
                    />
                    <h4>
                        <FormattedMessage id={'landing.bullets.locked.text'} />
                    </h4>
                </div>
                <div className={'point second'}>
                    <img
                        alt={intl.formatMessage({id: 'landing.bullets.identify.alt'})}
                        role={'presentation'}
                        src={'/images/icon-identify_vector_v1_1KB.svg'}
                    />
                    <h4>
                        <FormattedMessage id={'landing.bullets.identify.text'} />
                    </h4>
                </div>
            </div>
        </>
    );
}
