import {Link} from '@genomics-dev/denim-components';
import classNames from 'classnames';
import {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {useHubSpot} from '../../hooks';

export function ContactSupport(props) {
    const {
        className,
        dataTestId,
        text,
    } = props;

    const {open: openHubSpot} = useHubSpot();
    const intl = useIntl();

    const onClickHandler = useCallback(() => {
        openHubSpot();
    }, [openHubSpot]);

    const linkClasses = classNames('contact-support', className);
    const linkText = text ?? intl.formatMessage({id: 'common.contactSupport'});

    return (
        <Link
            className={linkClasses}
            dataTestId={dataTestId}
            onClick={onClickHandler}
        >
            {linkText}
        </Link>
    );
}
