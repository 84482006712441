import {useMemo} from 'react';
import {
    useLocation,
    useParams,
} from 'react-router-dom';

export function useSanitisedPathname() {
    const {pathname} = useLocation();
    const params = useParams();

    return useMemo(() => {
        // Do we have any URL params…?
        const keys = Object.keys(params);

        // If yes, we want to strip off everything after the last /, otherwise just do what we always did…
        // NOTE: routes like "/sign-up/*" cause keys[0] to have "*" as its value, hence the need to check that value.
        let sanitised = keys.length && keys[0] !== '*'
            ? pathname.substring(1, pathname.lastIndexOf('/'))
            : pathname.substring(1);
        sanitised = sanitised.replaceAll('/', '-');

        if (sanitised.endsWith('-')) {
            sanitised = sanitised.slice(0, -1);
        }

        return sanitised;
    }, [pathname]);
}
