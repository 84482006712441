import {
    DateInput,
    ErrorSummary,
    FormGroup,
    RadioButton,
    RadioButtonFormGroup,
} from '@genomics-dev/denim-components';
import classNames from 'classnames';
import {DateTime} from 'luxon';
import {
    useCallback,
    useReducer,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {useNavigate} from 'react-router-dom';
import {
    BackLink,
    FetchError,
} from '../../../components';
import {useAmplitude} from '../../../context';
import {useMutation} from '../../../hooks';
import {useLinearFlowContext} from '../../../layouts';
import {Routes} from '../../../Routes';
import {
    action,
    Endpoint,
    extractErrorMessage,
} from '../../../util';
import {
    ContinueButton,
    useKitActivationContext,
} from '../components';

const ACTION_SET_DISPLAY_DATE_INPUT = 'SET_DISPLAY_DATE_INPUT';
const ACTION_SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
const ACTION_CLEAR_ERROR_MESSAGES = 'ACTION_CLEAR_ERROR_MESSAGES';

const OPTION_TODAY = 'today';
const OPTION_OTHER = 'other';

const TODAY = DateTime.now();

const initialiser = (sampleCollected) => ({
    displayDateInput: sampleCollected === OPTION_OTHER,
    errorMessage: null,
});

const reducer = (state, {type, payload}) => {
    switch (type) {
        case ACTION_SET_DISPLAY_DATE_INPUT:
            return {
                ...state,
                displayDateInput: payload,
            };

        case ACTION_CLEAR_ERROR_MESSAGES:
            return {
                ...state,
                errorMessage: null,
            };

        case ACTION_SET_ERROR_MESSAGE:
            return {
                ...state,
                errorMessage: [...(state.errorMessage ?? []), payload].reduce((memo, message) => {
                    if (!memo.includes(message)) {
                        memo.push(message);
                    }

                    return memo;
                }, []),
            };

        default:
            return state;
    }
};

export function SampleCollected() {
    const amplitude = useAmplitude();
    const intl = useIntl();
    const {
        kitCode,
        otherDate,
        setOtherDate,
        sampleCollected,
        setSampleCollected,
    } = useKitActivationContext();
    const {onBackLinkClickHandler} = useLinearFlowContext();
    const [registerKit, {error, loading}] = useMutation(Endpoint.KITS, {cacheKey: Endpoint.SUMMARY});
    const navigate = useNavigate();
    const [{displayDateInput, errorMessage}, dispatch] = useReducer(
        reducer,
        sampleCollected,
        initialiser,
    );

    const radioButtonErrorMessages = {valueMissing: intl.formatMessage({id: 'forms.common.error.requiredOption'})};
    const dateInputMonthErrorMessages = {
        patternMismatch: intl.formatMessage({id: 'kit.activation.stepSeven.month.error.patternMismatch'}),
        valueMissing: intl.formatMessage({id: 'kit.activation.stepSeven.month.error.valueMissing'}),
    };
    const dateInputDayErrorMessages = {
        patternMismatch: intl.formatMessage({id: 'kit.activation.stepSeven.day.error.patternMismatch'}),
        valueMissing: intl.formatMessage({id: 'kit.activation.stepSeven.day.error.valueMissing'}),
    };
    const dateInputYearErrorMessages = {
        patternMismatch: intl.formatMessage({id: 'kit.activation.stepSeven.year.error.patternMismatch'}),
        valueMissing: intl.formatMessage({id: 'kit.activation.stepSeven.year.error.valueMissing'}),
    };

    const onRadioButtonsChangeHandler = useCallback((value) => {
        // Store which radio button is selected
        setSampleCollected(value);

        // If the other date option is selected then display the date input
        dispatch(action(ACTION_SET_DISPLAY_DATE_INPUT, value === OPTION_OTHER));

        // If the other date option isn't selected, then ensure we're not holding onto a previously entered date
        if (value !== OPTION_OTHER) {
            setOtherDate(null);
        }

        // A byproduct on this handler, is that we now know they've selected an option, so we can clear any error
        // messages that may have been set...
        dispatch(action(ACTION_CLEAR_ERROR_MESSAGES));
    }, []);

    const onRadioButtonsInvalid = useCallback(() => {
        // Only one thing it can be, which is "valueMissing"...
        dispatch(action(ACTION_SET_ERROR_MESSAGE, radioButtonErrorMessages.valueMissing));
    }, []);

    const onDateInputChange = useCallback(
        (datePortionValue, datePortionName) => {
            setOtherDate({
                day: '',
                month: '',
                year: '',
                ...otherDate,
                [datePortionName]: datePortionValue,
            });
        },
        [otherDate],
    );

    const onDateInputInvalid = useCallback((failedConstraints, datePortionName, _datePortionValue) => {
        const errorMessagesToUse = datePortionName === 'month'
            ? dateInputMonthErrorMessages
            : datePortionName === 'day'
            ? dateInputDayErrorMessages
            : dateInputYearErrorMessages;

        dispatch(action(ACTION_SET_ERROR_MESSAGE, errorMessagesToUse[failedConstraints]));
    }, []);

    const onSubmitHandler = useCallback(
        async (event) => {
            event.preventDefault();

            // Clear any error messages...
            dispatch(action(ACTION_CLEAR_ERROR_MESSAGES));

            const formValid = event.target.checkValidity();
            if (formValid) {
                const dateSampleCollected = sampleCollected === OPTION_TODAY
                    ? TODAY.toISODate()
                    : `${otherDate.year}-${otherDate.month}-${otherDate.day}`;

                try {
                    await registerKit({
                        body: {
                            kitCode: kitCode,
                            sampleCollectedAt: dateSampleCollected,
                        },
                    });
                    amplitude.logActivationDateSubmitted();
                }
                catch (err) {
                    return;
                }

                // Once the API is sent, the users internal state will be updated, and they should get the send your kit
                // back view on their homepage...
                navigate(Routes.HOME);
            }
        },
        [amplitude, kitCode, otherDate, sampleCollected],
    );

    const radioButtonFormGroupClasses = classNames({'half-margin': displayDateInput});

    return (
        <>
            {errorMessage && (
                <ErrorSummary
                    dataTestId={'kit-activation-step-seven-error-summary'}
                    errors={errorMessage}
                    title={intl.formatMessage({id: 'forms.common.error.title'})}
                />
            )}
            <BackLink onClick={onBackLinkClickHandler} />
            <h2 data-test-id={'kit-activation-step-seven-title'}>
                <FormattedMessage id={'kit.activation.stepSeven.title'} />
            </h2>
            {error && (
                <FetchError
                    dataTestId={'sample-collected-fetch-error'}
                    reason={extractErrorMessage(error)}
                />
            )}
            <form
                noValidate={true}
                onSubmit={onSubmitHandler}
            >
                <RadioButtonFormGroup
                    className={radioButtonFormGroupClasses}
                    dataTestId={'sample-collected-form-group'}
                    errorMessages={radioButtonErrorMessages}
                    name={'sampleCollected'}
                    onChange={onRadioButtonsChangeHandler}
                    onInvalid={onRadioButtonsInvalid}
                    required={true}
                    value={sampleCollected}
                >
                    <RadioButton
                        dataTestId={'sample-collected-today'}
                        description={intl.formatDate(Date.now(), {
                            day: 'numeric',
                            month: 'long',
                        })}
                        id={'sample-collected-today'}
                        label={intl.formatMessage({id: 'kit.activation.stepSeven.optionOne'})}
                        value={OPTION_TODAY}
                    />
                    <RadioButton
                        dataTestId={'sample-collected-other'}
                        id={'sample-collected-other'}
                        label={intl.formatMessage({id: 'kit.activation.stepSeven.optionTwo'})}
                        value={OPTION_OTHER}
                    />
                </RadioButtonFormGroup>
                {displayDateInput && (
                    <FormGroup dataTestId={'date-sample-collected-form-group'}>
                        <DateInput
                            dataTestId={'date-sample-collected-input'}
                            day={{
                                errorMessages: dateInputDayErrorMessages,
                                label: intl.formatMessage({id: 'kit.activation.stepSeven.day.label'}),
                                name: 'day',
                            }}
                            id={'date-of-birth'}
                            month={{
                                errorMessages: dateInputMonthErrorMessages,
                                label: intl.formatMessage({id: 'kit.activation.stepSeven.month.label'}),
                                name: 'month',
                            }}
                            onChange={onDateInputChange}
                            onInvalid={onDateInputInvalid}
                            required={true}
                            value={otherDate}
                            year={{
                                errorMessages: dateInputYearErrorMessages,
                                label: intl.formatMessage({id: 'kit.activation.stepSeven.year.label'}),
                                name: 'year',
                            }}
                        />
                    </FormGroup>
                )}
                <ContinueButton
                    loading={loading}
                    submit={true}
                />
            </form>
        </>
    );
}
