import {
    Button,
    FormGroup,
    TextInput,
    Variant,
} from '@genomics-dev/denim-components';
import classNames from 'classnames';
import {
    useCallback,
    useReducer,
} from 'react';
import {useIntl} from 'react-intl';
import {
    useAmplitude,
    useConfig,
} from '../../context';
import {action} from '../../util';

const UPDATE_ACCESS_CODE = 'UPDATE_ACCESS_CODE';
const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
const SET_IS_VALIDATING_ACCESS_CODE = 'SET_IS_VALIDATING_ACCESS_CODE';

// This is used to try and tease apart whatever format they've chosen to enter their code with, we can try and
// reconstruct it into the format they were sent and the backend accepts...
const ACCESS_CODE_REGEX = /^(?<first>[\d\w]{4})-?(?<second>[\d\w]{4})$/im;

const reducer = (state, {type, payload}) => {
    switch (type) {
        case UPDATE_ACCESS_CODE:
            return {
                ...state,
                accessCode: payload,
            };

        case SET_ERROR_MESSAGE:
            return {
                ...state,
                errorMessage: payload,
            };

        case SET_IS_VALIDATING_ACCESS_CODE:
            return {
                ...state,
                isValidatingAccessCode: payload,
            };

        default:
            return state;
    }
};

const initialState = {
    errorMessage: null,
    isValidatingAccessCode: false,
};

export function AccessCodeInput(props) {
    const {
        buttonText,
        className,
        dataTestId,
        id,
        label,
        onInvalidAccessCode,
        onValidAccessCode,
        onError,
        placeholder,
        value = '',
        variant = Variant.DARK,
    } = props;

    const amplitude = useAmplitude();
    const {backend} = useConfig();
    const intl = useIntl();
    const [{errorMessage, isValidatingAccessCode}, dispatch] = useReducer(reducer, initialState);

    let sanitisedVariant = variant;
    if (![Variant.LIGHT, Variant.DARK].includes(variant)) {
        if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.error('AccessCodeInput only supports Variant.LIGHT and Variant.DARK.');
        }
        sanitisedVariant = Variant.DARK;
    }

    const onSubmitHandler = useCallback(async (event) => {
        event.preventDefault();

        const form = event.target;
        const formValid = form.checkValidity();

        if (formValid) {
            dispatch(action(SET_IS_VALIDATING_ACCESS_CODE, true));

            // Get teh value from the text input and strip and spaces...
            const accessCode = form.elements.accessCodeInput.value;
            const accessCodeWithNoSpaces = accessCode.replaceAll(' ', '');

            const result = accessCodeWithNoSpaces.match(ACCESS_CODE_REGEX);
            if (result) {
                const {groups: {first, second} = {}} = result ?? {};
                const correctlyFormattedAccessCode = `${first.toLocaleUpperCase()}-${second.toLocaleUpperCase()}`;

                try {
                    const response = await fetch(
                        `${backend}/invitations/available/${correctlyFormattedAccessCode}`,
                    );

                    // Do this here, as if we do it in a finally block, the component have been unmounted,m and we get a
                    // React moan in the console...
                    dispatch(action(SET_IS_VALIDATING_ACCESS_CODE, false));

                    if (response.ok) {
                        amplitude.logValidAccessCodeEntered();
                        if (onValidAccessCode) {
                            onValidAccessCode(correctlyFormattedAccessCode);
                        }
                    }
                    else {
                        amplitude.logInvalidAccessCodeEntered(accessCode);
                        if (onInvalidAccessCode) {
                            onInvalidAccessCode(correctlyFormattedAccessCode);
                        }
                    }
                }
                catch (error) {
                    amplitude.logInvalidAccessCodeEntered(accessCode);

                    if (process.env.NODE_ENV === 'development') {
                        // eslint-disable-next-line no-console
                        console.error(error);
                    }

                    dispatch(action(SET_IS_VALIDATING_ACCESS_CODE, false));

                    if (onError) {
                        onError(correctlyFormattedAccessCode, error);
                    }
                }
            }
            else {
                amplitude.logInvalidAccessCodeEntered(accessCode);

                // If the format of the access code they've entered is garbage, don't bother sending it to the
                // backend, just take them straight to the issue page...
                dispatch(action(SET_IS_VALIDATING_ACCESS_CODE, false));

                if (onInvalidAccessCode) {
                    onInvalidAccessCode(accessCode);
                }
            }
        }
    }, []);

    const containerClassNames = classNames('access-code-input', sanitisedVariant, className);

    return (
        <div className={containerClassNames}>
            <form
                noValidate={true}
                onSubmit={onSubmitHandler}
            >
                <FormGroup
                    dataTestId={`${dataTestId ?? id}-form-group`}
                    errorMessage={errorMessage}
                    label={label}
                    labelFor={id}
                    placeholder={placeholder}
                >
                    <TextInput
                        dataTestId={dataTestId ?? id}
                        errorMessages={{default: intl.formatMessage({id: 'landing.accessCodeIssue.noAccessCode'})}}
                        id={id}
                        name={'accessCodeInput'}
                        placeholder={placeholder}
                        required={true}
                        type={'text'}
                        value={value}
                        variant={Variant.PRIMARY}
                    />
                    <Button
                        dataTestId={`${dataTestId ?? id}-button`}
                        isLoading={isValidatingAccessCode}
                        submit={true}
                        variant={Variant.PRIMARY}
                    >
                        {buttonText}
                    </Button>
                </FormGroup>
            </form>
        </div>
    );
}
