import {Link} from '@genomics-dev/denim-components';
import {useMemo} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {Routes} from '../../Routes';

export function WhatHappensNext(props) {
    const {
        name,
        summary: {
            payment,
            physicianReviewStatus,
        } = {},
    } = props;

    const intl = useIntl();

    const titleKey = useMemo(() => {
        if (physicianReviewStatus === 'REORDER_PLACED') {
            return 'reorder';
        }

        if (payment === 'PAID') {
            return 'paid';
        }

        return 'default';
    }, [payment, physicianReviewStatus]);

    return (
        <>
            <div className={'left'}>
                <header>
                    <div
                        className={'is-caption name'}
                        data-test-id={'users-shortened-name'}
                    >
                        {name}
                    </div>
                    <h2
                        className={'page-title'}
                        data-test-id={'home-what-happens-next-title'}
                    >
                        <FormattedMessage id={`home.whatHappensNext.${titleKey}.title`} />
                    </h2>
                </header>
                {(payment === 'PAID') && (
                    <p>
                        <FormattedMessage id={'home.whatHappensNext.description.paid'} />
                    </p>
                )}
                <p>
                    <FormattedMessage id={'home.whatHappensNext.description.one'} />
                </p>
                <p>
                    <FormattedMessage id={'home.whatHappensNext.description.two'} />
                </p>
                <p className={'no-margin-bottom'}>
                    <FormattedMessage
                        id={'home.whatHappensNext.description.three'}
                        values={{
                            link: (chunk) => (
                                <Link
                                    className={'alternate'}
                                    href={Routes.HELP_CENTER}
                                    rel={'noreferrer'}
                                    target={'_blank'}
                                >
                                    {chunk}
                                </Link>
                            ),
                        }}
                    />
                </p>
            </div>
            <div className={'right'}>
                <img
                    alt={intl.formatMessage({id: 'common.testKitBox.alt'})}
                    src={'/images/health-insights-test-kit-box_520x600.jpg'}
                />
            </div>
        </>
    );
}
