import {
    Button,
    Variant,
} from '@genomics-dev/denim-components';
import {
    useCallback,
    useEffect,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {useOutletContext} from 'react-router-dom';
import {ProfileInformation} from '../../../components';
import {useAmplitude} from '../../../context';
import {useLinearFlowContext} from '../../../layouts';

export function CheckYourDetails() {
    const amplitude = useAmplitude();
    const intl = useIntl();
    const {nextStep} = useLinearFlowContext();
    const {currentUserProfile = {}} = useOutletContext();

    useEffect(() => {
        amplitude.logRequestNewTestCheckYourDetailsVisit();
    }, [amplitude]);

    const onContinueClickHandler = useCallback(() => {
        nextStep();
    }, [nextStep]);

    return (
        <>
            <h2 data-test-id={'test-reorder-check-details-title'}>
                <FormattedMessage id={'testReorder.checkDetails.title'} />
            </h2>
            <ProfileInformation
                allowEditing={true}
                editPrefix={'/test-reorder'}
                {...currentUserProfile}
            />
            <Button
                className={'no-margin icon arrow--right after'}
                dataTestId={'test-reorder-check-details-continue'}
                onClick={onContinueClickHandler}
                title={intl.formatMessage({id: 'common.button.continue.title'})}
                variant={Variant.PRIMARY}
            >
                <FormattedMessage id={'common.button.continue'} />
            </Button>
        </>
    );
}
