import {
    Breakpoint,
    useBreakpoints,
} from '@genomics-dev/denim-components';
import classNames from 'classnames';
import {useMemo} from 'react';
import {useLocation} from 'react-router-dom';
import {Logotype} from '../../../../components';
import {useUserContext} from '../../../../context';
import {Routes} from '../../../../Routes';
import {SignUpButton} from './SignUpButton';
import {SplitLogotype} from './SplitLogotype';

export function MobileNavbarContent(props) {
    const {
        className,
        dataTestId,
        displaySignUp,
        homeLink = false,
        marketingRoutes = [],
    } = props;

    const currentBreakpoint = useBreakpoints();
    const {isAuthenticated} = useUserContext();
    const {pathname} = useLocation();

    const displayPoweredByLogotype = useMemo(() => marketingRoutes.includes(pathname), [pathname]);

    const displaySignUpButton = useMemo(() => {
        if (displaySignUp) {
            return true;
        }

        if (isAuthenticated) {
            return false;
        }

        return pathname !== Routes.LANDING;
    }, [displaySignUp, isAuthenticated, pathname]);

    const containerClasses = classNames(className, {
        'with-sign-up': displaySignUpButton,
        'with-split-logotype': displaySignUpButton && currentBreakpoint === Breakpoint.PHONE,
    });

    return (
        <div
            className={containerClasses}
            data-test-id={dataTestId}
        >
            {displaySignUpButton && currentBreakpoint === Breakpoint.PHONE
                ? <SplitLogotype dataTestId={'health-insights-split-logotype-navigation'} />
                : (
                    <Logotype
                        dataTestId={'health-insights-logotype-navigation'}
                        homeLink={homeLink}
                        poweredByLogotype={displayPoweredByLogotype}
                        split={displaySignUp}
                    />
                )}
            {displaySignUpButton && <SignUpButton />}
        </div>
    );
}
