import 'core-js/stable';
import 'regenerator-runtime/runtime';
import {createRoot} from 'react-dom/client';
import {IntlProvider} from 'react-intl';
import Modal from 'react-modal';
import {BrowserRouter} from 'react-router-dom';
import {
    App,
    Auth0ProviderWithRedirectProcessor,
    CacheProvider,
    ConfigProvider,
    ErrorBoundary,
    HubSpotLoader,
} from './health-insights';
import messages from './i18n/messages.json';
import '../css/genomics-health-insights.pcss';

// Get the element where we will render the application into…
const container = document.getElementById('root');

// Tell the modal where the application is rendered into, so that it can apply aria-hidden when a modal is open…
Modal.setAppElement(container);

const root = createRoot(container);
root.render(
    <IntlProvider
        defaultLocale={'en'}
        locale={'en'}
        messages={messages}
    >
        <ConfigProvider>
            <BrowserRouter>
                <Auth0ProviderWithRedirectProcessor>
                    <CacheProvider>
                        <HubSpotLoader>
                            <ErrorBoundary>
                                <App />
                            </ErrorBoundary>
                        </HubSpotLoader>
                    </CacheProvider>
                </Auth0ProviderWithRedirectProcessor>
            </BrowserRouter>
        </ConfigProvider>
    </IntlProvider>,
);
