import {
    ErrorSummary,
    Link,
} from '@genomics-dev/denim-components';
import {useMemo} from 'react';
import {useIntl} from 'react-intl';
import {Routes} from '../../Routes';
import {ContactSupport} from '../contact-support';

// "errorResponse" prop is expected to bethe full error response returned by the mutation hook. This component will
// render only the first error returned in this response; there maybe more but it makes no visual sense to display
// multiple error blobs.
//
// Custom messages exist for most of the profile endpoint validation error responses, but we don't cover all of them,
// as for things like Reported Sex and Ethnicity, the user can't control the submitted value, so makes no sense to show
// a custom form error, and we show the regular fetchError instead.
//
// Some fetch validation checks are also expected to be covered by the browser based form checks (e.g. name length,
// required fields) but we add them here anyway, because if for some reason the API validates slightly differently to
// the browser check, it's better to show a custom message allowing the user to correct the error, rather than the
// generic error message.
export function FetchValidationError(props) {
    const {
        dataTestId,
        errorResponse,
        pathname,
    } = props;

    const intl = useIntl();

    const {error, errorMessages} = useMemo(() => {
        if (!errorResponse) {
            return {error: null};
        }

        // Extract the things that matter from the error response...
        const {errorData} = errorResponse ?? {};

        // ADDRESS_STATE_NOT_SUPPORTED takes precedence over ADDRESS_INVALID, otherwise take the first in the array,
        // or setup for a generic error if no content was given
        let errorCode = errorData?.errors?.includes('ADDRESS_STATE_NOT_SUPPORTED')
            ? 'ADDRESS_STATE_NOT_SUPPORTED'
            : errorData?.errors?.[0] ?? 'GENERIC';

        // We need different error messages for the different types of address, so fudge the errorCode
        if (errorCode === 'ADDRESS_INVALID') {
            const [addressPrefix] = pathname.substr(pathname.lastIndexOf('/') + 1).split('-');
            errorCode = `${addressPrefix.toLocaleUpperCase()}_${errorCode}`;
        }

        if (
            [
                'SHIPPING_ADDRESS_INVALID',
                'ADDRESS_STATE_NOT_SUPPORTED',
                'DOB_OUTSIDE_RANGE',
                'INVALID_ACCESS_CODE',
            ].includes(errorCode)
        ) {
            return {
                error: errorCode,
                errorMessages: [
                    intl.formatMessage({id: `fetchValidationError.paragraph.one.${errorCode}`}),
                    intl.formatMessage(
                        {id: `fetchValidationError.paragraph.two.${errorCode}`},
                        {
                            cs: (chunk) => <ContactSupport text={chunk} />,
                            link: (chunks) => (
                                <Link
                                    className={'alternate'}
                                    rel={'noreferrer'}
                                    target={'_blank'}
                                    url={Routes.HELP_CENTER}
                                >
                                    {chunks}
                                </Link>
                            ),
                        },
                    ),
                ],
            };
        }
        else if (
            [
                'HOME_ADDRESS_INVALID',
                'PHONE_NUMBER_INVALID',
                'GENERIC',
                'IDENTIFY_MOST_MUST_BE_IN_ETHNICITY_LIST',
                'ETHNICITIES_MUST_BE_MULTIPLE_IF_IDENTIFY_MOST_SET',
            ].includes(errorCode)
        ) {
            return {
                error: errorCode,
                errorMessages: [
                    intl.formatMessage(
                        {id: `fetchValidationError.paragraph.${errorCode}`},
                        {cs: (chunk) => <ContactSupport text={chunk} />},
                    ),
                ],
            };
        }

        return {
            error: errorCode,
            errorMessages: [],
        };
    }, [errorResponse]);

    // Check if we have a message we can show for this validation error (based on existence of message title).
    if (error) {
        return (
            <ErrorSummary
                dataTestId={dataTestId ?? `error-summary`}
                errors={errorMessages}
                title={intl.formatMessage({id: `fetchValidationError.title.${error}`})}
            />
        );
    }

    // If not, don't render anything
    return null;
}
