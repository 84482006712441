import {FormattedMessage} from 'react-intl';
import {ContactSupport} from '../contact-support';
import {Divider} from '../divider';
import {UsefulLinks} from '../useful-links';

export function ErrorNotice(props) {
    const {errorCode} = props;

    return (
        <div className={'grid fifty-fifty center-content error-notice'}>
            <div className={'left'}>
                <h1 data-test-id={'error-notice-title'}>
                    <FormattedMessage id={'encounteredError.title'} />
                </h1>
                {errorCode && (
                    <p
                        className={'is-eyebrow is-small error-code'}
                        data-test-id={'error-code'}
                    >
                        <FormattedMessage
                            id={'encounteredError.errorCode'}
                            values={{errorCode}}
                        />
                    </p>
                )}
                <p>
                    <FormattedMessage
                        id={'encounteredError.paragraph.one'}
                        values={{
                            cs: (chunk) => (
                                <ContactSupport
                                    className={'alternate'}
                                    dataTestId={'contact-support-link'}
                                    text={chunk}
                                />
                            ),
                        }}
                    />
                </p>

                <Divider />

                <UsefulLinks />
            </div>
            <img
                alt={''}
                className={'right'}
                role={'presentation'}
                src={'/images/slatted-circle-gradient.svg'}
            />
        </div>
    );
}
