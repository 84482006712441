import {Link} from '@genomics-dev/denim-components';
import classNames from 'classnames';
import {FormattedMessage} from 'react-intl';
import {Link as InternalLink} from 'react-router-dom';
import {Routes} from '../../Routes';

export function UsefulLinks(props) {
    const {className, dataTestId} = props;

    const linkContainerClasses = classNames('useful-links', className);

    return (
        <div
            className={linkContainerClasses}
            data-test-id={dataTestId}
        >
            <p className={'title'}>
                <FormattedMessage id={'common.usefulLinks.title'} />
            </p>

            <InternalLink
                className={'link is-heavy'}
                data-test-id={'useful-links-landing'}
                to={Routes.LANDING}
            >
                <span>
                    <FormattedMessage id={'common.usefulLink.home'} />
                </span>
            </InternalLink>

            <div className={'is-small useful-links-separator'}>
                <FormattedMessage id={'common.usefulLink.separator'} />
            </div>

            <InternalLink
                className={'link is-heavy'}
                data-test-id={'useful-links-account'}
                to={Routes.ACCOUNT_PROFILE}
            >
                <span>
                    <FormattedMessage id={'common.usefulLink.dashboard'} />
                </span>
            </InternalLink>

            <div className={'is-small useful-links-separator'}>
                <FormattedMessage id={'common.usefulLink.separator'} />
            </div>

            <Link
                className={'is-heavy icon after launch'}
                dataTestId={'useful-links-help'}
                rel={'noreferrer'}
                target={'_blank'}
                url={Routes.HELP_CENTER}
            >
                <FormattedMessage id={'common.usefulLink.helpCenter'} />
            </Link>
        </div>
    );
}
