import {
    Button,
    Variant,
} from '@genomics-dev/denim-components';
import {useCallback} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {useNavigate} from 'react-router-dom';
import {useAmplitude} from '../../context';
import {Routes} from '../../Routes';

export function SignUpButton(props) {
    const {dataTestId, location} = props;
    const amplitude = useAmplitude();
    const intl = useIntl();
    const navigate = useNavigate();

    const onClickHandler = useCallback(() => {
        amplitude.logSignUpClicked(location);
        navigate(Routes.SIGN_UP);
    }, [amplitude, location, navigate]);

    return (
        <Button
            after={true}
            className={'sign-up-button'}
            dataTestId={dataTestId}
            icon={'arrow--right'}
            onClick={onClickHandler}
            title={intl.formatMessage({id: `landing.hero.signUp.button.title`})}
            variant={Variant.PRIMARY}
        >
            <FormattedMessage id={'landing.hero.signUp.button.text'} />
        </Button>
    );
}
