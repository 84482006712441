import {
    Link,
    Variant,
} from '@genomics-dev/denim-components';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {
    BackLink,
    GridContainer,
} from '../../components';
import {
    useDefaultBackClickHandler,
    useTitle,
} from '../../hooks';

export function Overview() {
    const intl = useIntl();
    const defaultBackClickHandler = useDefaultBackClickHandler();

    useTitle(intl.formatMessage({id: 'overview.documentTitle'}));

    return (
        <section className={'static-content'}>
            <GridContainer containerClassName={'content-section'}>
                <div className={'page-title'}>
                    <BackLink onClick={defaultBackClickHandler} />
                    <h1 data-test-id={'terms-and-conditions-page-title'}>
                        <FormattedMessage id={'overview.pageTitle.text'} />
                    </h1>
                </div>
            </GridContainer>
            <GridContainer
                className={'content-section'}
                variant={Variant.LIGHT}
            >
                <div className={'content-item about-terms'}>
                    <p>
                        <FormattedMessage
                            id={'overview.paragraph.one'}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'overview.paragraph.two'}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'overview.paragraph.three'}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'overview.paragraph.four'}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'overview.paragraph.five'}
                        />
                    </p>
                    <ul className={'no-margin'}>
                        <li>
                            <FormattedMessage
                                id={'overview.trait.one'}
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                id={'overview.trait.two'}
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                id={'overview.trait.three'}
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                id={'overview.trait.four'}
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                id={'overview.trait.five'}
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                id={'overview.trait.six'}
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                id={'overview.trait.seven'}
                            />
                        </li>
                    </ul>
                    <p>
                        <FormattedMessage
                            id={'overview.paragraph.six'}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'overview.paragraph.seven'}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'overview.paragraph.eight'}
                            values={{
                                link: (chunks) => (
                                    <Link
                                        className={'alternate'}
                                        rel={'noreferrer'}
                                        target={'_blank'}
                                        url={'https://insightsgenomics.com/documents/genomicsplc-HI-validation-paper-2023.pdf'}
                                    >
                                        {chunks}
                                    </Link>
                                ),
                            }}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'overview.paragraph.nine'}
                            values={{
                                link: (chunks) => (
                                    <Link
                                        className={'alternate'}
                                        rel={'noreferrer'}
                                        target={'_blank'}
                                        url={'https://findageneticcounselor.nsgc.org/?reload=timezone'}
                                    >
                                        {chunks}
                                    </Link>
                                ),
                            }}
                        />
                    </p>
                </div>
            </GridContainer>
        </section>
    );
}
