import {
    ErrorSummary,
    FormGroup,
    TextInput,
} from '@genomics-dev/denim-components';
import {
    useCallback,
    useReducer,
    useRef,
    useState,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {BackLink} from '../../../components';
import {useAmplitude} from '../../../context';
import {useLazyQuery} from '../../../hooks';
import {useLinearFlowContext} from '../../../layouts';
import {
    action,
    Endpoint,
    NetworkPolicy,
} from '../../../util';
import {
    ContinueButton,
    useKitActivationContext,
} from '../components';
import {KitCodeValidationError} from './KitCodeValidationError';

const CODE_TEXT_INPUT_PATTERN = '(?:[Pp]-)?[0-9]{5}';
const CODE_NUMERIC_REGEX = /^(?:[Pp]-)?(?<code>[0-9]{5})$/;

const initialState = {
    error: null,
    loading: false,
};

const SET_ERROR = 'SET_ERROR';
const SET_LOADING = 'SET_LOADING';

const reducer = (state, {type, payload}) => {
    switch (type) {
        case SET_ERROR:
            return {
                ...state,
                error: payload,
            };

        case SET_LOADING:
            return {
                ...state,
                loading: payload,
            };

        default:
            return state;
    }
};

export function EnterTheCode() {
    const amplitude = useAmplitude();
    const intl = useIntl();
    const {kitCode, setKitCode} = useKitActivationContext();
    const {nextStep, onBackLinkClickHandler} = useLinearFlowContext();
    const [validateKitCode] = useLazyQuery(Endpoint.VALIDATE_KIT, {networkPolicy: NetworkPolicy.NETWORK_ONLY});
    const codeInputRef = useRef();
    const [codeInvalid, setCodeInvalid] = useState(null);
    const [{error, loading}, dispatch] = useReducer(reducer, initialState);

    const errorMessages = {
        patternMismatch: intl.formatMessage({id: 'kit.activation.stepTwo.error.patternMismatch'}),
        valueMissing: intl.formatMessage({id: 'kit.activation.stepTwo.error.required'}),
    };

    const codeInvalidHandler = useCallback((failedConstraints) => {
        setCodeInvalid(failedConstraints.length === 0 ? null : errorMessages[failedConstraints[0]]);
    }, []);

    const onNavigateHandler = useCallback(() => {
        // Clear the error, so we display the code input view.
        dispatch(action(SET_ERROR, null));
    }, []);

    const onSubmitHandler = useCallback(
        async (event) => {
            event.preventDefault();

            // Clear any large errors...
            dispatch(action(SET_ERROR, null));

            const formValid = event.target.checkValidity();
            if (formValid) {
                // Clear any error messages and set the loading indicator...
                setCodeInvalid(null);
                dispatch(action(SET_LOADING, true));

                // Get the value of the input field...
                const latestKitCode = codeInputRef.current?.value;

                // See if it matches what we expect
                const result = latestKitCode.match(CODE_NUMERIC_REGEX);
                if (result) {
                    const {groups: {code} = {}} = result ?? {};

                    try {
                        // Validate the kit code, no content returned...
                        await validateKitCode({endpoint: `${Endpoint.VALIDATE_KIT}/P-${code}`});

                        // Only store the kitCode for later use if it's valid, and store what was validated, rather than
                        // what they entered...
                        setKitCode(`P-${code}`);

                        // Navigate to the next step...
                        amplitude.logActivationCodeValidSubmitted();
                        nextStep();
                    }
                    catch (apiError) {
                        amplitude.logActivationCodeInvalidSubmitted(code);
                        dispatch(action(SET_ERROR, apiError));
                    }
                    finally {
                        dispatch(action(SET_LOADING, false));
                    }
                }
            }
        },
        [amplitude, validateKitCode],
    );

    if (error) {
        return <KitCodeValidationError onNavigate={onNavigateHandler} />;
    }

    return (
        <>
            {codeInvalid && (
                <ErrorSummary
                    errors={codeInvalid}
                    title={intl.formatMessage({id: 'forms.common.error.title'})}
                />
            )}
            <BackLink onClick={onBackLinkClickHandler} />
            <h2 data-test-id={'kit-activation-step-two-title'}>
                <FormattedMessage id={'kit.activation.stepTwo.title'} />
            </h2>
            <p>
                <FormattedMessage id={'kit.activation.stepTwo.description'} />
            </p>
            <div className={'instruction more-margin'}>
                <img
                    alt={''}
                    className={'image'}
                    role={'presentation'}
                    src={'/images/kit-activation-two-one.svg'}
                />
                <img
                    alt={''}
                    className={'image'}
                    role={'presentation'}
                    src={'/images/kit-activation-two-two.svg'}
                />
            </div>
            <form
                noValidate={true}
                onSubmit={onSubmitHandler}
            >
                <FormGroup
                    className={'code-input'}
                    dataTestId={'code-input-form-group'}
                    label={intl.formatMessage({id: 'kit.activation.stepTwo.input.title'})}
                    labelFor={'codeInput'}
                >
                    <TextInput
                        dataTestId={'code-input'}
                        errorMessages={errorMessages}
                        id={'codeInput'}
                        ignoreOnBlurInvalidity={true}
                        ignoreOnChangeInvalidity={true}
                        name={'codeInput'}
                        onInvalid={codeInvalidHandler}
                        pattern={CODE_TEXT_INPUT_PATTERN}
                        ref={codeInputRef}
                        required={true}
                        type={'text'}
                        value={kitCode ?? ''}
                    />
                </FormGroup>
                <ContinueButton
                    loading={loading}
                    submit={true}
                />
            </form>
        </>
    );
}
