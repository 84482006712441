import {ErrorCard} from '@genomics-dev/denim-components';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {ContactSupport} from '../contact-support';

export function FetchError(props) {
    const {dataTestId, reason} = props;

    const intl = useIntl();

    const displayReason = ['development', 'test'].includes(process.env.NODE_ENV) && reason;

    return (
        <ErrorCard
            dataTestId={dataTestId ?? 'fetch-error'}
            title={intl.formatMessage({id: 'fetchError.title'})}
        >
            {displayReason && (
                <p
                    className={'is-eyebrow is-small error-code'}
                    data-test-id={'fetch-error-reason'}
                >
                    <FormattedMessage
                        id={'fetchError.reason'}
                        values={{reason}}
                    />
                </p>
            )}
            <p>
                <FormattedMessage
                    id={'fetchError.information'}
                    values={{cs: (chunk) => <ContactSupport text={chunk} />}}
                />
            </p>
        </ErrorCard>
    );
}
