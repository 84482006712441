import {
    Button,
    Variant,
} from '@genomics-dev/denim-components';
import {
    useCallback,
    useEffect,
    useState,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {
    Navigate,
    useParams,
} from 'react-router-dom';
import {
    ContactSupport,
    GridContainer,
    Loading,
} from '../../components';
import {
    useLazyQuery,
    useTitle,
} from '../../hooks';
import {Routes} from '../../Routes';
import {Endpoint} from '../../util';
import {PaymentComplete} from './PaymentComplete';

const STRIPE_STATUS_OPEN = 'OPEN';
const STRIPE_STATUS_COMPLETE = 'COMPLETE';
const STRIPE_STATUS_EXPIRED = 'EXPIRED';

export function PaymentCallback() {
    const intl = useIntl();
    const [getCheckoutStatus, {error}] = useLazyQuery(Endpoint.CHECKOUT_STATUS);
    const {sessionId} = useParams();
    const [isFetchingStatus, setIsFetchingStatus] = useState(false);
    const [status, setStatus] = useState(null);
    useTitle(intl.formatMessage({id: 'payment.documentTitle'}));

    const fetchCheckoutStatus = useCallback(async () => {
        setIsFetchingStatus(true);

        try {
            // Get the status of the checkout session from our backend.
            const {status} = await getCheckoutStatus({
                endpoint: `${Endpoint.CHECKOUT_STATUS}?client_secret=${sessionId}`,
            });

            setStatus(status.toLocaleUpperCase());
        }
        finally {
            setIsFetchingStatus(false);
        }
    }, []);

    useEffect(() => {
        if (!isFetchingStatus) {
            fetchCheckoutStatus();
        }
    }, []);

    const onClickHandler = useCallback(async () => {
        // Rather than hard reloading teh page, just try contacting teh backend again to see if it works this time.
        await fetchCheckoutStatus();
    }, [fetchCheckoutStatus]);

    if (error) {
        return (
            <GridContainer gridClassName={'all-columns'}>
                <div>
                    <h2 data-test-id={'status-error-title'}>
                        <FormattedMessage id={'payment.title'} />
                    </h2>
                    <p>
                        <FormattedMessage id={'payment.description.one'} />
                    </p>
                    <p>
                        <FormattedMessage id={'payment.description.two'} />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'payment.description.three'}
                            values={{cs: (chunk) => <ContactSupport className={'alternate'} text={chunk} />}}
                        />
                    </p>
                    <Button
                        dataTestId={'status-refresh-button'}
                        isLoading={isFetchingStatus}
                        onClick={onClickHandler}
                        variant={Variant.PRIMARY}
                    >
                        <FormattedMessage id={'payment.button'} />
                    </Button>
                </div>
            </GridContainer>
        );
    }

    // If the payment is complete, then forward on to the home page
    if (status === STRIPE_STATUS_COMPLETE) {
        return <PaymentComplete />;
    }

    // According to https://docs.stripe.com/checkout/embedded/quickstart?client=react#handle-session
    // "open" means - "The payment failed or was cancelled. Remount Checkout so that your customer can try again."
    // I'm assuming "expired" means the same, although there's no mention of that in the example.
    if ([STRIPE_STATUS_OPEN, STRIPE_STATUS_EXPIRED].includes(status)) {
        return <Navigate to={Routes.CHECKOUT} />;
    }

    return (
        <div
            className={'loading-container'}
            data-test-id={'loading-indicator'}
            key={'loading'}
        >
            <Loading />
        </div>
    );
}
