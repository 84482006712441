import {
    Checkbox,
    CheckboxFormGroup,
    MoreInfo,
} from '@genomics-dev/denim-components';
import {
    useCallback,
    useEffect,
    useState,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {useOutletContext} from 'react-router-dom';
import {Ethnicities} from '../../../../util';
import {FetchValidationError} from '../../../fetch-validation-error';

export function Ethnicity() {
    const intl = useIntl();
    const {
        currentUserProfile: {ethnicities = []} = {},
        errorResponse,
        setPersistFields,
    } = useOutletContext();
    const [values, setValues] = useState(ethnicities ?? []);

    useEffect(() => {
        setPersistFields(() => () => ({ethnicities: values}));

        return () => {
            setPersistFields(null);
        };
    }, [values]);

    const onChangeHandler = useCallback((checkboxValues) => {
        setValues(checkboxValues);
    }, []);

    return (
        <>
            <h2
                className={'title'}
                data-test-id={'sign-up-ethnicity-title'}
            >
                <FormattedMessage id={'signUp.ethnicity.title'} />
            </h2>
            <MoreInfo
                content={
                    <>
                        <p className={'no-margin-top'}>
                            <FormattedMessage id={'signUp.ethnicity.moreInfo.one'} />
                        </p>
                        <p className={'no-margin-top'}>
                            <FormattedMessage id={'signUp.ethnicity.moreInfo.two'} />
                        </p>
                        <p className={'no-margin'}>
                            <FormattedMessage id={'signUp.ethnicity.moreInfo.three'} />
                        </p>
                    </>
                }
                title={intl.formatMessage({id: 'common.whyWeAskThisQuestion'})}
            />
            <FetchValidationError errorResponse={errorResponse} />
            <CheckboxFormGroup
                alternateOptions={[
                    <Checkbox
                        dataTestId={'ethnicity-prefer-not-to-say'}
                        id={'ethnicity-prefer-not-to-say'}
                        key={Ethnicities.US_PNTS}
                        label={intl.formatMessage({id: `common.profile.ethnicity.${Ethnicities.US_PNTS}.text`})}
                        value={Ethnicities.US_PNTS}
                    />,
                ]}
                dataTestId={'sign-up-ethnicity-form-group'}
                divider={intl.formatMessage({id: 'common.or'})}
                errorMessages={{valueMissing: intl.formatMessage({id: 'forms.common.error.requiredOption'})}}
                label={intl.formatMessage({id: 'signUp.ethnicity.label'})}
                name={'ethnicity'}
                onChange={onChangeHandler}
                options={[
                    <Checkbox
                        dataTestId={'ethnicity-black-or-african-american'}
                        description={intl.formatMessage({
                            id: `common.profile.ethnicity.${Ethnicities.US_BLACK}.description`,
                        })}
                        id={'ethnicity-black-or-african-american'}
                        key={Ethnicities.US_BLACK}
                        label={intl.formatMessage({id: `common.profile.ethnicity.${Ethnicities.US_BLACK}.text`})}
                        value={Ethnicities.US_BLACK}
                    />,
                    <Checkbox
                        dataTestId={'ethnicity-east-asian'}
                        description={intl.formatMessage({
                            id: `common.profile.ethnicity.${Ethnicities.US_EAS}.description`,
                        })}
                        id={'ethnicity-east-asian'}
                        key={Ethnicities.US_EAS}
                        label={intl.formatMessage({id: `common.profile.ethnicity.${Ethnicities.US_EAS}.text`})}
                        value={Ethnicities.US_EAS}
                    />,
                    <Checkbox
                        dataTestId={'ethnicity-native-american-or-alaskan-native'}
                        id={'ethnicity-native-american-or-alaskan-native'}
                        key={Ethnicities.US_AIAN}
                        label={intl.formatMessage({id: `common.profile.ethnicity.${Ethnicities.US_AIAN}.text`})}
                        value={Ethnicities.US_AIAN}
                    />,
                    <Checkbox
                        dataTestId={'ethnicity-native-hawaiian-or-pacific-islander'}
                        description={intl.formatMessage({
                            id: `common.profile.ethnicity.${Ethnicities.US_NHPI}.description`,
                        })}
                        id={'ethnicity-native-hawaiian-or-pacific-islander'}
                        key={Ethnicities.US_NHPI}
                        label={intl.formatMessage({id: `common.profile.ethnicity.${Ethnicities.US_NHPI}.text`})}
                        value={Ethnicities.US_NHPI}
                    />,
                    <Checkbox
                        dataTestId={'ethnicity-south-asian'}
                        description={intl.formatMessage({
                            id: `common.profile.ethnicity.${Ethnicities.US_SAS}.description`,
                        })}
                        id={'ethnicity-south-asian'}
                        key={Ethnicities.US_SAS}
                        label={intl.formatMessage({id: `common.profile.ethnicity.${Ethnicities.US_SAS}.text`})}
                        value={Ethnicities.US_SAS}
                    />,
                    <Checkbox
                        dataTestId={'ethnicity-south-east-asian'}
                        description={intl.formatMessage({
                            id: `common.profile.ethnicity.${Ethnicities.US_SEAS}.description`,
                        })}
                        id={'ethnicity-south-east-asian'}
                        key={Ethnicities.US_SEAS}
                        label={intl.formatMessage({id: `common.profile.ethnicity.${Ethnicities.US_SEAS}.text`})}
                        value={Ethnicities.US_SEAS}
                    />,
                    <Checkbox
                        dataTestId={'ethnicity-hispanic'}
                        description={intl.formatMessage({
                            id: `common.profile.ethnicity.${Ethnicities.US_HISP}.description`,
                        })}
                        id={'ethnicity-hispanic'}
                        key={Ethnicities.US_HISP}
                        label={intl.formatMessage({id: `common.profile.ethnicity.${Ethnicities.US_HISP}.text`})}
                        value={Ethnicities.US_HISP}
                    />,
                    <Checkbox
                        dataTestId={'ethnicity-white'}
                        description={intl.formatMessage({
                            id: `common.profile.ethnicity.${Ethnicities.US_WHITE}.description`,
                        })}
                        id={'ethnicity-white'}
                        key={Ethnicities.US_WHITE}
                        label={intl.formatMessage({id: `common.profile.ethnicity.${Ethnicities.US_WHITE}.text`})}
                        value={Ethnicities.US_WHITE}
                    />,
                    <Checkbox
                        dataTestId={'ethnicity-other'}
                        id={'ethnicity-other'}
                        key={Ethnicities.US_OTHER}
                        label={intl.formatMessage({id: `common.profile.ethnicity.${Ethnicities.US_OTHER}.text`})}
                        value={Ethnicities.US_OTHER}
                    />,
                ]}
                required={true}
                value={values}
            />
        </>
    );
}
