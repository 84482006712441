import {
    Link,
    Variant,
} from '@genomics-dev/denim-components';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {Link as InternalLink} from 'react-router-dom';
import {
    BackLink,
    GridContainer,
} from '../../components';
import {
    useDefaultBackClickHandler,
    useTitle,
} from '../../hooks';
import {Routes} from '../../Routes';

export function TermsConditions() {
    const intl = useIntl();
    const defaultBackClickHandler = useDefaultBackClickHandler();

    const rtfFormat = () => ({
        bold: (chunks) => <strong>{chunks}</strong>,
        em: (chunks) => <em>{chunks}</em>,
        email: (chunks) => (
            <Link
                rel={'noreferrer'}
                target={'_blank'}
                url={intl.formatMessage({id: 'common.support.email'})}
            >
                {chunks}
            </Link>
        ),
        hipn: (chunks) => (
            <em>
                <InternalLink
                    className={'link'}
                    to={Routes.PRIVACY_POLICY}
                >
                    <span>{chunks}</span>
                </InternalLink>
            </em>
        ),
        wplink: (chunks) => <Link url={`#${Routes.TARGET_WEBSITE_PRIVACY}`}>{chunks}</Link>,
        cs: (chunks) => (
            <Link
                rel={'noreferrer'}
                target={'_blank'}
                url={intl.formatMessage({id: 'common.support.email'})}
            >
                {chunks}
            </Link>
        ),
        legal: (chunks) => (
            <Link
                rel={'noreferrer'}
                target={'_blank'}
                url={intl.formatMessage({id: 'common.legal.email'})}
            >
                {chunks}
            </Link>
        ),
    });

    useTitle(intl.formatMessage({id: 'websiteTermsAndConditions.documentTitle'}));

    return (
        <section className={'static-content'}>
            <GridContainer containerClassName={'content-section'}>
                <div className={'page-title'}>
                    <BackLink onClick={defaultBackClickHandler} />
                    <h1 data-test-id={'terms-and-conditions-page-title'}>
                        <FormattedMessage id={'websiteTermsAndConditions.pageTitle.text'} />
                    </h1>
                </div>
            </GridContainer>
            <GridContainer
                className={'content-section'}
                variant={Variant.LIGHT}
            >
                <div className={'content-item about-terms'}>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.lastUpdated.paragraph.one.text'}
                            values={rtfFormat()}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.aboutOurWebsiteTerms.paragraph.one'}
                            values={rtfFormat()}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.aboutOurWebsiteTerms.paragraph.two'}
                            values={rtfFormat()}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.aboutOurWebsiteTerms.paragraph.three'}
                            values={rtfFormat()}
                        />
                    </p>
                </div>
                <div className={'content-item eligibility'}>
                    <h2>
                        <FormattedMessage id={'websiteTermsAndConditions.eligibility.title'} />
                    </h2>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.eligibility.paragraph.one'}
                            values={rtfFormat()}
                        />
                    </p>
                    <ul>
                        <li>
                            <FormattedMessage
                                id={'websiteTermsAndConditions.eligibility.item.one'}
                                values={rtfFormat()}
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                id={'websiteTermsAndConditions.eligibility.item.two'}
                                values={rtfFormat()}
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                id={'websiteTermsAndConditions.eligibility.item.three'}
                                values={rtfFormat()}
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                id={'websiteTermsAndConditions.eligibility.item.four'}
                                values={rtfFormat()}
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                id={'websiteTermsAndConditions.eligibility.item.five'}
                                values={rtfFormat()}
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                id={'websiteTermsAndConditions.eligibility.item.six'}
                                values={rtfFormat()}
                            />
                        </li>
                    </ul>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.eligibility.paragraph.two'}
                            values={rtfFormat()}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.eligibility.paragraph.three'}
                            values={rtfFormat()}
                        />
                    </p>
                </div>
                <div className={'content-item changes-to-terms'}>
                    <h2>
                        <FormattedMessage id={'websiteTermsAndConditions.changesToTerms.title'} />
                    </h2>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.changesToTerms.paragraph.one'}
                            values={rtfFormat()}
                        />
                    </p>
                </div>
                <div className={'content-item risk-and-considerations'}>
                    <h2>
                        <FormattedMessage id={'websiteTermsAndConditions.riskAndConsiderations.title'} />
                    </h2>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.riskAndConsiderations.paragraph.one'}
                            values={rtfFormat()}
                        />
                    </p>
                    <ul>
                        <li>
                            <FormattedMessage
                                id={'websiteTermsAndConditions.riskAndConsiderations.item.one'}
                                values={rtfFormat()}
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                id={'websiteTermsAndConditions.riskAndConsiderations.item.two'}
                                values={rtfFormat()}
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                id={'websiteTermsAndConditions.riskAndConsiderations.item.three'}
                                values={rtfFormat()}
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                id={'websiteTermsAndConditions.riskAndConsiderations.item.four'}
                                values={rtfFormat()}
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                id={'websiteTermsAndConditions.riskAndConsiderations.item.five'}
                                values={rtfFormat()}
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                id={'websiteTermsAndConditions.riskAndConsiderations.item.six'}
                                values={rtfFormat()}
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                id={'websiteTermsAndConditions.riskAndConsiderations.item.seven'}
                                values={rtfFormat()}
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                id={'websiteTermsAndConditions.riskAndConsiderations.item.eight'}
                                values={rtfFormat()}
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                id={'websiteTermsAndConditions.riskAndConsiderations.item.nine'}
                                values={rtfFormat()}
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                id={'websiteTermsAndConditions.riskAndConsiderations.item.ten'}
                                values={rtfFormat()}
                            />
                        </li>
                    </ul>
                </div>
                <div className={'content-item limitation-of-genetic-data'}>
                    <h2>
                        <FormattedMessage id={'websiteTermsAndConditions.limitationOfGeneticData.title'} />
                    </h2>
                    <ul>
                        <li>
                            <FormattedMessage
                                id={'websiteTermsAndConditions.limitationOfGeneticData.item.one'}
                                values={rtfFormat()}
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                id={'websiteTermsAndConditions.limitationOfGeneticData.item.two'}
                                values={rtfFormat()}
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                id={'websiteTermsAndConditions.limitationOfGeneticData.item.three'}
                                values={rtfFormat()}
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                id={'websiteTermsAndConditions.limitationOfGeneticData.item.four'}
                                values={rtfFormat()}
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                id={'websiteTermsAndConditions.limitationOfGeneticData.item.five'}
                                values={rtfFormat()}
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                id={'websiteTermsAndConditions.limitationOfGeneticData.item.six'}
                                values={rtfFormat()}
                            />
                        </li>
                        <li>
                            <FormattedMessage
                                id={'websiteTermsAndConditions.limitationOfGeneticData.item.seven'}
                                values={rtfFormat()}
                            />
                        </li>
                    </ul>
                </div>
                <div className={'content-item no-advice'}>
                    <h2>
                        <FormattedMessage id={'websiteTermsAndConditions.noAdvice.title'} />
                    </h2>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.noAdvice.paragraph.one'}
                            values={rtfFormat()}
                        />
                    </p>
                </div>
                <div className={'content-item disclaimer'}>
                    <h2>
                        <FormattedMessage id={'websiteTermsAndConditions.disclaimer.title'} />
                    </h2>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.disclaimer.paragraph.one'}
                            values={rtfFormat()}
                        />
                    </p>
                </div>
                <div className={'content-item limitations-of-liability'}>
                    <h2>
                        <FormattedMessage id={'websiteTermsAndConditions.limitationsOfLiability.title'} />
                    </h2>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.limitationsOfLiability.paragraph.one'}
                            values={rtfFormat()}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.limitationsOfLiability.paragraph.two'}
                            values={rtfFormat()}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.limitationsOfLiability.paragraph.three'}
                            values={rtfFormat()}
                        />
                    </p>
                </div>
                <div className={'content-item disputes'}>
                    <h2>
                        <FormattedMessage id={'websiteTermsAndConditions.disputes.title'} />
                    </h2>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.disputes.paragraph.one'}
                            values={rtfFormat()}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.disputes.paragraph.two'}
                            values={rtfFormat()}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.disputes.paragraph.three'}
                            values={rtfFormat()}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.disputes.paragraph.four'}
                            values={rtfFormat()}
                        />
                    </p>
                </div>
                <div className={'content-item refunds'}>
                    <h2>
                        <FormattedMessage id={'websiteTermsAndConditions.refunds.title'} />
                    </h2>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.refunds.paragraph.one'}
                            values={rtfFormat()}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.refunds.paragraph.two'}
                            values={rtfFormat()}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.refunds.paragraph.three'}
                            values={rtfFormat()}
                        />
                    </p>
                </div>
                <div className={'content-item kit-replacements'}>
                    <h2>
                        <FormattedMessage id={'websiteTermsAndConditions.kitReplacements.title'} />
                    </h2>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.kitReplacements.paragraph.one'}
                            values={rtfFormat()}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.kitReplacements.paragraph.two'}
                            values={rtfFormat()}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.kitReplacements.paragraph.three'}
                            values={rtfFormat()}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.kitReplacements.paragraph.four'}
                            values={rtfFormat()}
                        />
                    </p>
                </div>
                <div className={'content-item using-the-site'}>
                    <h2>
                        <FormattedMessage id={'websiteTermsAndConditions.usingTheSite.title'} />
                    </h2>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.usingTheSite.paragraph.one'}
                            values={rtfFormat()}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.usingTheSite.paragraph.two'}
                            values={rtfFormat()}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.usingTheSite.paragraph.three'}
                            values={rtfFormat()}
                        />
                    </p>
                </div>
                <div className={'content-item unacceptable-use'}>
                    <h2>
                        <FormattedMessage id={'websiteTermsAndConditions.unacceptableUse.title'} />
                    </h2>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.unacceptableUse.paragraph.one'}
                            values={rtfFormat()}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.unacceptableUse.paragraph.two'}
                            values={rtfFormat()}
                        />
                    </p>
                </div>
                <div className={'content-item creating-an-account'}>
                    <h2>
                        <FormattedMessage id={'websiteTermsAndConditions.creatingAnAccount.title'} />
                    </h2>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.creatingAnAccount.paragraph.one'}
                            values={rtfFormat()}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.creatingAnAccount.paragraph.two'}
                            values={rtfFormat()}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.creatingAnAccount.paragraph.three'}
                            values={rtfFormat()}
                        />
                    </p>
                </div>
                <div className={'content-item third-party'}>
                    <h2>
                        <FormattedMessage id={'websiteTermsAndConditions.thirdParty.title'} />
                    </h2>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.thirdParty.paragraph.one'}
                            values={rtfFormat()}
                        />
                    </p>
                </div>
                <div className={'content-item copyright'}>
                    <h2>
                        <FormattedMessage id={'websiteTermsAndConditions.copyright.title'} />
                    </h2>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.copyright.paragraph.one'}
                            values={rtfFormat()}
                        />
                    </p>
                </div>
                <div className={'content-item intellectual-property'}>
                    <h2>
                        <FormattedMessage id={'websiteTermsAndConditions.intellectualProperty.title'} />
                    </h2>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.intellectualProperty.paragraph.one'}
                            values={rtfFormat()}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.intellectualProperty.paragraph.two'}
                            values={rtfFormat()}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.intellectualProperty.paragraph.three'}
                            values={rtfFormat()}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.intellectualProperty.paragraph.four'}
                            values={rtfFormat()}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.intellectualProperty.paragraph.five'}
                            values={rtfFormat()}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.intellectualProperty.paragraph.six'}
                            values={rtfFormat()}
                        />
                    </p>
                </div>
                <div className={'content-item contact-us'}>
                    <h2>
                        <FormattedMessage id={'websiteTermsAndConditions.contactUs.title'} />
                    </h2>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.contactUs.paragraph.one'}
                            values={rtfFormat()}
                        />
                    </p>
                </div>
                <div className={'content-item waiver'}>
                    <h2>
                        <FormattedMessage id={'websiteTermsAndConditions.waiver.title'} />
                    </h2>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.waiver.paragraph.one'}
                            values={rtfFormat()}
                        />
                    </p>
                </div>
                <div className={'content-item assignment'}>
                    <h2>
                        <FormattedMessage id={'websiteTermsAndConditions.assignment.title'} />
                    </h2>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.assignment.paragraph.one'}
                            values={rtfFormat()}
                        />
                    </p>
                </div>
                <div className={'content-item notice'}>
                    <h2>
                        <FormattedMessage id={'websiteTermsAndConditions.notice.title'} />
                    </h2>
                    <p>
                        <FormattedMessage
                            id={'websiteTermsAndConditions.notice.paragraph.one'}
                            values={rtfFormat()}
                        />
                    </p>
                </div>
            </GridContainer>
        </section>
    );
}
