import {Variant} from '@genomics-dev/denim-components';
import classNames from 'classnames';

export function ExplainerStat(props) {
    const {
        dataTestId,
        riskLevel,
        stat,
        text,
        variant = Variant.DARK,
    } = props;

    let sanitisedVariant = variant;
    if (![Variant.LIGHT, Variant.DARK].includes(variant)) {
        if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.error('ExplainerStat only supports Variant.LIGHT and Variant.DARK.');
        }
        sanitisedVariant = Variant.DARK;
    }

    const explainerStatClasses = classNames('explainer-stat', sanitisedVariant);
    const statClasses = classNames('stat', 'is-medium', {
        'risk-gradient': sanitisedVariant === Variant.DARK,
        [riskLevel.toLocaleLowerCase()]: riskLevel,
    });
    const explainerClasses = classNames('explainer', 'text', 'is-heavy', {
        'risk-gradient': sanitisedVariant === Variant.DARK,
        [riskLevel.toLocaleLowerCase()]: riskLevel,
    });

    return (
        <div
            className={explainerStatClasses}
            data-test-id={dataTestId}
        >
            <div className={statClasses}>{stat}</div>
            <div className={explainerClasses}>{text}</div>
        </div>
    );
}
